<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Lista de negritas</h3>
  </div>
  <hr />

  <form>
    <div class="mb-3 row">
      <div class="col-md-6">
        <div class="input-group">
          <input
            type="text"
            id="table-complete-search"
            name="searchText"
            class="form-control"
            placeholder="Buscar negritas"
            [(ngModel)]="filterText"
            (keyup)="filterRecords()"
          />

          <select
            class="form-select ms-1"
            name="currentOfficeId"
            [(ngModel)]="currentOfficeId"
            id="currentOfficeId"
            (change)="setCurrentOffice($event)"
            *ngIf="rolekey == 'admin'"
          >
            <option value="0" selected="selected">Selecciona sucursal</option>
            <option
              *ngFor="let office of offices"
              value="{{ office.id }}"
              selected="{ office.id == roleofficeid ? 'selected' : '' }"
            >
              {{ office.name }}
            </option>
          </select>

          <button
            class="btn btn-outline-danger ms-1"
            type="button"
            (click)="clearSearch()"
          >
            Limpiar busqueda
          </button>
        </div>
      </div>
    </div>
    <hr />

    <div class="alert alert-success" role="alert" *ngIf="flagDeleted">
      {{ message }}
    </div>

    <table class="table table-striped">
      <thead>
        <tr>
          <th class="col">ID</th>
          <th class="col">Nombre del cliente</th>
          <th class="col">Fecha de compra del paquete</th>
          <th class="col">Fecha de ultima sesión</th>
          <th class="col">Areas del paquete</th>
          <th class="col">Sucursal</th>
          <th class="col">Causa</th>
          <th class="col">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let blacks of filterBlackDebts
              | paginate : { itemsPerPage: 15, currentPage: page }
          "
        >
          <td scope="row">
            {{ blacks.id }}
          </td>
          <td>
            {{ blacks.client }}
          </td>
          <td>{{ blacks.information_package.compra }}</td>
          <td>{{ blacks.information_package.session }}</td>
          <td>
            {{ blacks.areas }}
          </td>
          <td>
            {{ blacks.office_name }}
          </td>
          <td>
            {{ blacks.reason }}
          </td>
          <td>
            <div class="d-flex d-flex-row">
              <div>
                <a
                  [routerLink]="[
                    '/black-debts/info',
                    blacks.clientId,
                    blacks.apptId
                  ]"
                  placement="top"
                  ngbTooltip="Informacion de blacks.client"
                  role="button"
                  class="btn btn-primary"
                >
                  <i class="bi bi-eye-fill"></i>
                </a>
              </div>
              <div class="ms-1">
                <a
                  (click)="
                    showTemplateDisabled(
                      blacks.clientId,
                      blacks.client,
                      blacks.apptId
                    )
                  "
                  placement="top"
                  ngbTooltip="Deshabilitar cliente de negritas"
                  role="button"
                  class="btn btn-primary"
                >
                  <i class="bi bi-trash3"></i>
                </a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="col-md-12 d-flex align-items-center justify-content-center">
      <pagination-controls
        (pageChange)="page = $event"
        nextLabel="Siguiente"
        previousLabel="Anterior"
        class="mt-3"
      ></pagination-controls>
    </div>
  </form>
</div>

<ng-template #disabledClient let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">Desactivar al client de negritas</h4>
    <button class="close" type="button" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Deseas desactivar el paquete del cliente<strong>{{ clientName }}</strong>
      ?
    </p>
    <form [formGroup]="deletedForms" (ngSubmit)="deleteClients()" novalidate>
      <div class="row">
        <div class="form-floating">
          <input type="hidden" name="apptId" />
          <textarea
            class="form-control"
            placeholder="Razon de eliminacion del paquete"
            id="comments"
            formControlName="comments"
            style="height: 100px"
          ></textarea>
          <label class="ms-1" for="comments">
            Razon de desactivar el paquete
          </label>
        </div>
      </div>
      <div class="row mt-2">
        <div class="d-grip gap-2 d-md-flex justify-content-md-end">
          <button
            type="button"
            (click)="deleteClients()"
            class="btn btn-outline-success"
          >
            Confirmar
          </button>
          <button
            class="btn btn-outline-danger"
            type="button"
            (click)="close()"
          >
            Cancelar
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
