import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class CreateComponent implements OnInit {
  officeId: number | null = 0;
  date: string | null = '';
  breadcrumbs: any = [];
  innerForm: any = FormGroup;
  outerForm: any = FormGroup;
  submittedInner: boolean = false;
  submittedOuter: boolean = false;
  flagOuter: boolean = false;
  flagInner: boolean = false;
  messageOuter: string = '';
  messageInner: string = '';
  saldoTotalExtra: number = 0;
  lastProduct: number = 0;
  paymentsFilter: any = [];
  payments: any = [];
  payment_methods: any = [];
  clientsFilter: any = [];
  outerData: any = [];
  innerData: any = [];
  sumInner: number = 0;
  sumOuter: number = 0;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private ingresos: FormBuilder,
    private egresos: FormBuilder
  ) {}

  ngOnInit(): void {
    this.officeId = Number(this.route.snapshot.paramMap.get('officeId'));
    this.date = this.route.snapshot.paramMap.get('filterDate');

    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Caja chica',
        path: '/small-box/list',
        link: true,
      },
      {
        name: 'Dar de alta ingreso o egreso',
        link: false,
      },
    ];

    this.validators();
    this.getPaymentMethods();
    this.getPayments();
    this.getInformationData();
    this.paymentsSessionsPerDate();
  }

  getPayments() {
    this.api.get('payments').subscribe({
      next: (payments) => {
        this.payments = payments.payments;
      },
      error: (err) => {},
    });
  }

  getPaymentMethods() {
    this.api.get('payment_methods').subscribe({
      next: (paymentMethods) => {
        this.payment_methods = paymentMethods.payment_methods;
      },
      error: (err) => {},
    });
  }

  getInformationData() {
    const params = `${Number(this.officeId)}/${this.date}`;
    this.api.getById('sessions', params).subscribe({
      next: (info) => {
        this.outerForm.patchValue({
          officeId: this.officeId,
        });

        const clients = info.sessions;
        const formatValue = clients.map((client: any) => {
          return {
            value: client.id,
            label: `${client.client.record} - ${client.client.name} (Sesion ${client.numberSession}) - Cabina: ${client.cabin.name}`,
          };
        });

        this.clientsFilter = [{ options: formatValue }];
      },
      error: (err) => {},
    });
  }

  paymentsSessionsPerDate(): void {
    const params = `${Number(this.officeId)}/${this.date}`;
    this.api.getById('small_box_payments', params).subscribe({
      next: (results) => {
        this.innerData = results.payments.inner;
        this.outerData = results.payments.outer;
        this.sumInner = results.payments.sumInner;
        this.sumOuter = results.payments.sumOuter;
      },
      error: (err) => {},
    });
  }

  validators(): void {
    this.outerForm = this.egresos.group({
      concept: ['', [Validators.required]],
      amount: ['', [Validators.required]],
      officeId: ['', [Validators.required]],
    });

    this.innerForm = this.ingresos.group({
      amount: ['', [Validators.required]],
      paymentMethodId: ['', [Validators.required]],
      paymentId: [''],
      terminal: [''],
      extra: [''],
      sessionTemp: [''],
      sessionId: ['', [Validators.required]],
    });
  }

  get f() {
    return this.outerForm.controls;
  }

  get fe() {
    return this.innerForm.controls;
  }

  checkPaymentMethods(evt: any): void {
    this.paymentsFilter = this.payments.filter(
      (data: any) => data.payment_method_id === Number(evt.target.value)
    );
  }

  changeElement(evt: any): void {
    const numberData = evt.target.value;

    if (numberData == 1 || numberData == 2) {
      this.saldoTotalExtra =
        Number(this.saldoTotalExtra) + 50 - Number(this.lastProduct);

      if (this.lastProduct == 0 || this.lastProduct == 100) {
        this.lastProduct = 50;
      }

      this.innerForm.patchValue({
        amount: 50,
      });
    } else {
      this.saldoTotalExtra =
        Number(this.saldoTotalExtra) + 100 - Number(this.lastProduct);
      if (this.lastProduct == 0 || this.lastProduct == 50) {
        this.lastProduct = 100;
      }
      this.innerForm.patchValue({
        amount: 100,
      });
    }
  }

  updateClientSession(evt: any): void {
    this.innerForm.patchValue({
      sessionId: evt.value,
    });
  }

  createInnerPayment(): void {
    this.submittedInner = true;

    if (this.innerForm.invalid) {
      this.submittedInner = false;
      return;
    }

    const obj = {
      paymentMethodId: this.innerForm.value.paymentMethodId,
      amount: this.innerForm.value.amount,
      paymentId: this.innerForm.value.paymentId,
      terminal: this.innerForm.value.terminal,
      sessionId: this.innerForm.value.sessionId,
      extra: this.innerForm.value.extra,
      remainingPayment: '',
    };

    this.api.create('extra_payment_session', obj).subscribe({
      next: (result) => {
        this.flagInner = true;
        this.messageInner = result.message;
        this.resetForm();

        setTimeout(() => {
          this.submittedInner = false;
          this.flagInner = false;
          this.messageInner = '';
        }, 3000);
      },
      error: (err) => {},
    });
  }

  createOuterPayment(): void {
    this.submittedOuter = true;

    if (this.outerForm.invalid) {
      console.log(this.outerForm);
      this.submittedOuter = false;
      return;
    }

    const currentDate = new Date();
    const day = String(currentDate.getDate());
    const month = String(currentDate.getMonth() + 1);
    const year = currentDate.getFullYear();
    const obj = {
      concept: this.outerForm.value.concept,
      amount: this.outerForm.value.amount,
      office: this.outerForm.value.officeId,
      date: `${year}-${month}-${day}`,
    };

    this.api.create('small_box', obj).subscribe({
      next: (result) => {
        this.messageOuter = result.message;
        this.flagOuter = true;
        this.resetForm();

        setTimeout(() => {
          this.flagOuter = false;
          this.submittedOuter = false;
          this.messageOuter = '';
        }, 3000);
      },
      error: (err) => {},
    });
  }

  resetForm() {
    this.outerForm.reset();
    this.innerForm.reset();
    this.innerForm.get('paymentMethodId').setValue('');
    this.innerForm.get('paymentId').setValue('');
    this.innerForm.get('terminal').setValue('');
    this.innerForm.get('sessionTemp').reset();
    this.paymentsFilter = [];
  }
}
