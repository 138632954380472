<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Editar pago</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-5">
    <a [routerLink]="['/payments/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de pagos
    </a>
  </div>
  <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
    <div *ngIf="isDisplayed" class="alert alert-danger" role="alert">
      {{ generalMessage }}
    </div>
    <div *ngIf="isSaved" class="alert alert-success" role="alert">
      {{ generalMessage }}
    </div>

    <form [formGroup]="paymentsForm" (ngSubmit)="updatePayments()" novalidate>
      <div class="accordion" id="cities_accordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="cities-information">
            <button
              class="accordion-button color-header-collapse"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              disabled
            >
              <span class="color-text"> Actualizaci&iacute;n de Pagos </span>
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div class="row">
                <div class="mb-3 form-floating">
                  <input
                    type="text"
                    name="payment_name"
                    id="payment_name"
                    formControlName="payment_name"
                    class="form-control"
                    placeholder="Nombre de pago"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.payment_name.errors,
                      'is-valid': submitted && !f.payment_name.errors
                    }"
                  />
                  <label for="cycle" class="ms-2">Nombre de pago</label>
                </div>
                <div class="form-floating mb-3">
                  <select
                    name="payment_method_id"
                    id="payment_method_id"
                    class="form-control"
                    formControlName="payment_method_id"
                    placeholcer="Metodo de pago"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.payment_method_id.errors,
                      'is-valid': submitted && !f.payment_method_id.errors
                    }"
                  >
                    <option value="" selected="{ payment_method == 0 }">
                      Seleccione un m&eacute;todo de pago
                    </option>
                    <option
                      value="{{ paymentMethod.id }}"
                      *ngFor="let paymentMethod of paymentMethods"
                      selected="{ paymentMethod.id === payment_method ? 'selected' : '' }"
                    >
                      {{ paymentMethod.payment_name }}
                    </option>
                  </select>
                  <label for="payment_method_id" class="ms-2"
                    >M&eacute;todo de pago</label
                  >
                </div>
                <div class="row">
                  <div class="col-md-6 mb-3 form-floating">
                    <input
                      type="number"
                      name="order"
                      formControlName="order"
                      placeholder="Posicion"
                      required
                      [ngClass]="{
                        'is-invalid': submitted && f.order.errors,
                        'is-valid': submitted && !f.order.errors
                      }"
                      class="form-control"
                      id="order"
                    />
                    <label for="order" class="ms-2">
                      Posicion del registro
                    </label>
                  </div>
                  <div class="col-md-6">
                    <div class="form-check form-switch ms-3">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        formControlName="active"
                      />
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckDefault"
                      >
                        Activado / Desactivado ?
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3 mt-2 d-grip gap-2 d-md-flex justify-content-md-end">
        <button type="submit" class="btn btn-success">
          <i class="bi bi-plus-circle"></i>
          Actualizar
        </button>
      </div>
    </form>
  </div>
</div>
