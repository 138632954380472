<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Crear pago</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-5">
    <a [routerLink]="['/payments/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de pagos
    </a>
  </div>
  <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
    <div *ngIf="isDisplayed" class="alert alert-danger" role="alert">
      {{ generalMessage }}
    </div>
    <div *ngIf="isSaved" class="alert alert-success" role="alert">
      {{ generalMessage }}
    </div>

    <form [formGroup]="paymentsForm" (ngSubmit)="createPayments()" novalidate>
      <div class="accordion" id="cities_accordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="cities-information">
            <button
              class="accordion-button color-header-collapse"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              disabled
            >
              <span class="color-text">
                Creaci&oacute;n de Opciones de Pagos
              </span>
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div class="row">
                <div class="mb-3 form-floating">
                  <input
                    type="text"
                    name="payment_name"
                    id="payment_name"
                    formControlName="payment_name"
                    class="form-control"
                    placeholder="Opcion de pago"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.payment_name.errors,
                      'is-valid': submitted && !f.payment_name.errors
                    }"
                  />
                  <label for="payment_name" class="ms-2">
                    Opci&oacute;n de pago
                  </label>
                  <div>
                    <div
                      *ngIf="
                        f.payment_name?.invalid &&
                        flagErrors &&
                        (f.payment_name?.errors || f.payment_name?.touched)
                      "
                    >
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.payment_name?.errors.required"
                      >
                        La opci&oacute;n de pago es obligatorio.
                      </div>
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.payment_name?.errors.minlength"
                      >
                        La opci&oacute;n de pago debe ser al menos de 5
                        caracteres.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-floating mb-3">
                  <select
                    name="payment_method_id"
                    id="payment_method_id"
                    class="form-control"
                    formControlName="payment_method_id"
                    placeholcer="Metodo de pago"
                    required
                    [ngClass]="{
                      'is-invalid':
                        submitted && flagErrors && f.payment_method_id.errors,
                      'is-valid':
                        submitted && !flagErrors && !f.payment_method_id.errors
                    }"
                  >
                    <option value="" selected>
                      Seleccione un m&eacute;todo de pago
                    </option>
                    <option
                      value="{{ paymentMethod.id }}"
                      *ngFor="let paymentMethod of paymentsMethods"
                    >
                      {{ paymentMethod.payment_name }}
                    </option>
                  </select>
                  <label for="payment_method_id" class="ms-2"
                    >M&eacute;todo de pago</label
                  >
                  <div>
                    <div
                      *ngIf="
                        flagErrors &&
                        f.payment_method_id?.invalid &&
                        (f.payment_method_id?.errors ||
                          f.payment_method_id?.touched)
                      "
                    >
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.payment_method_id?.errors.required"
                      >
                        El m&eacute;todo de pago es requerido.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <div class="form-floating">
                      <input
                        type="number"
                        name="order"
                        id="order"
                        formControlName="order"
                        class="form-control"
                        placeholder="Posicion del registro"
                        required
                        [ngClass]="{
                          'is-invalid': submitted && f.payment_name.errors,
                          'is-valid': submitted && !f.payment_name.errors
                        }"
                      />
                      <label for="order" class="ms-2"
                        >Posicion del registro</label
                      >
                      <div>
                        <div
                          *ngIf="
                            f.order?.invalid &&
                            flagErrors &&
                            (f.order?.errors || f.order?.touched)
                          "
                        >
                          <div
                            class="ms-2 mt-1 text-danger"
                            *ngIf="f.order?.errors.required"
                          >
                            La posici&oacute;n del registro es obligatoria.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <div class="form-check form-switch ms-3">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        formControlName="is_active"
                      />
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckDefault"
                      >
                        Activado / Desactivado ?
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 mt-2 d-grip gap-2 d-md-flex justify-content-md-end">
        <button
          type="button"
          class="btn btn-danger"
          (click)="resetPaymentsForm()"
        >
          <i class="bi bi-eraser"></i>
          Limpiar Informaci&oacute;n
        </button>
        <button type="submit" class="btn btn-success">
          <i class="bi bi-plus-circle"></i>
          Crear
        </button>
      </div>
    </form>
  </div>
</div>
