import { Component, KeyValueDiffers, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.scss'],
})
export class CreateProductComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  productForm: any = FormGroup;
  submitted: boolean = false;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  generalMessage: string = '';
  responseMessage: string = '';
  breadcrumbs: any = [];
  private _message = new Subject<string>();

  constructor(private api: ApiService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this._message.subscribe(
      (messageData: string) => (this.generalMessage = messageData)
    );
    this._message.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
        this.isSaved = false;
        this.isDisplayed = false;
        this.generalMessage = '';
        this.submitted = false;
        this.responseMessage = '';
      }
    });

    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de insumos en venta',
        path: '/products/list',
        link: true,
      },
      {
        name: 'Crear insumo',
        link: false,
      },
    ];

    this.validations();
  }

  validations() {
    this.productForm = this.fb.group({
      name: ['', [Validators.required]],
      price: ['', [Validators.required]],
      pieces: ['', [Validators.required]],
    });
  }

  get f() {
    return this.productForm.controls;
  }

  createProduct(): void {
    this.submitted = true;

    if (this.productForm.invalid) {
      return;
    }

    const obj = {
      product_name: this.productForm.value.name,
      product_price: this.productForm.value.price,
      product_pieces: this.productForm.value.pieces,
    };

    this.api.create('products', obj).subscribe({
      next: (product) => {
        this.submitted = false;
        this.isDisplayed = false;
        this.isSaved = true;
        this.responseMessage = product.message;
        this._message.next(this.responseMessage);
        this.resetProduct();
      },
      error: (err) => {},
    });
  }

  resetProduct(): void {
    this.productForm.reset();
  }
}
