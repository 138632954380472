import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-update-payments',
  templateUrl: './update-payments.component.html',
  styleUrls: ['./update-payments.component.scss'],
})
export class UpdatePaymentsComponent implements OnInit {
  paymentsForm: any = FormGroup;
  submitted: boolean = false;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  generalMessage: string = '';
  paramId: string | null = '';
  breadcrumbs: any;
  paymentMethods: any = [];
  payment_method: number = 0;

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.paramId = this.route.snapshot.paramMap.get('id');
    this.breadcrumbs = [
      { name: 'Agenda', path: '/dashboard/calendar', link: true },
      { name: 'Lista de pagos', path: '/payments/list', link: true },
      { name: 'Editar pago', link: false },
    ];

    this.validators();
    this.loadPaymentMethods();
    this.loadPayment();
  }

  loadPayment() {
    this.api.getById('payments', Number(this.paramId)).subscribe({
      next: (payment) => {
        this.payment_method = payment.payments[0].payments_methods
          ? payment.payments[0].payments_methods.id
          : 0;

        this.paymentsForm.setValue({
          payment_name: payment.payments.name,
          order: payment.payments.order,
          active: payment.payments.is_active,
          payment_method_id: payment.payments[0].payments_methods
            ? payment.payments[0].payments_methods.id
            : 0,
        });
      },
      error: (err) => {},
    });
  }

  loadPaymentMethods() {
    this.api.get('payment_methods').subscribe({
      next: (paymentMethod) => {
        this.paymentMethods = paymentMethod.payment_methods;
      },
      error: (err) => {},
    });
  }

  validators() {
    this.paymentsForm = this.fb.group({
      payment_name: ['', [Validators.required, Validators.minLength(5)]],
      active: [''],
      payment_method_id: [''],
      order: ['', [Validators.required]],
    });
  }

  get f() {
    return this.paymentsForm.controls;
  }

  updatePayments() {
    this.submitted = true;

    if (this.paymentsForm.invalid) {
      return;
    }

    const obj = {
      payment_name: this.paymentsForm.value.payment_name,
      is_active: this.paymentsForm.value.active,
      payment_method_id: this.paymentsForm.value.payment_method_id,
      order: this.paymentsForm.value.order,
    };

    this.api.update('payments', Number(this.paramId), obj).subscribe({
      next: (payments) => {
        this.submitted = false;
        this.isSaved = true;
        this.generalMessage = payments.message;
      },
      error: (err) => {},
    });

    setTimeout(() => {
      this.submitted = false;
      this.isDisplayed = false;
      this.isSaved = false;
      this.generalMessage = '';
    }, 4000);
  }
}
