import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-cities',
  templateUrl: './edit-cities.component.html',
  styleUrls: ['./edit-cities.component.scss'],
})
export class EditCitiesComponent implements OnInit {
  cities: any = FormGroup;
  submitted: boolean = false;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  generalMessage: string = '';
  paramId: string | null = '';
  states: any = [];
  breadcrumbs: any;
  stateId: number = 0;

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private router: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.paramId = this.router.snapshot.paramMap.get('id');

    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de ciudades',
        path: '/cities/list',
        link: true,
      },
      {
        name: 'Editar ciudad',
        link: false,
      },
    ];

    this.validators();
    this.getCities();
    this.getStates();
  }

  validators() {
    this.cities = this.fb.group({
      state_id: ['', [Validators.required]],
      city: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  getCities() {
    this.api.getById('cities', Number(this.paramId)).subscribe({
      next: (city) => {
        this.stateId = city.city.states.id;

        this.cities.setValue({
          state_id: city.city.states.id,
          city: city.city.city_name,
        });
      },
      error: (err) => {},
    });
  }

  getStates() {
    this.api.get('states').subscribe({
      next: (states) => {
        this.states = states.estados;
      },
      error: (err) => {},
    });
  }

  get f() {
    return this.cities.controls;
  }

  updateCities() {
    this.submitted = true;

    if (this.cities.invalid) {
      return;
    }

    const obj = {
      stateId: this.cities.value.state_id,
      city: this.cities.value.city,
    };

    this.api.update('cities', Number(this.paramId), obj).subscribe({
      next: (cities) => {
        this.submitted = false;
        this.isSaved = true;
        this.generalMessage = cities.message;
      },
      error: (err) => {},
    });

    setTimeout(() => {
      this.submitted = false;
      this.isSaved = false;
      this.generalMessage = '';
      this.isDisplayed = false;
    }, 4000);
  }
}
