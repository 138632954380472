import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-list-packages',
  templateUrl: './list-packages.component.html',
  styleUrls: ['./list-packages.component.scss'],
})
export class ListPackagesComponent implements OnInit {
  @ViewChild('modalPackage', { static: false }) modalPackage!: TemplateRef<any>;
  modalPackageClose: any;
  filterText: string = '';
  packages: any = [];
  filterPackages: any = [];
  breadcrumbs: any;
  rolename: any = '';
  rolekey: any = '';
  idDeleted: number = 0;
  packageNameDeleted: string = '';
  flagDeleted: boolean = false;
  message: string = '';
  page: number = 1;
  offices: any = [];
  roleofficeid: number = 0;
  currentOfficeId: number = 0;
  confirmDelete: boolean = false;

  constructor(private api: ApiService, private modal: NgbModal) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de paquetes',
        link: false,
      },
    ];

    this.loadPackages();
    this.roleInfo();
    this.getOffices();
  }

  roleInfo() {
    this.api.get('employees/roles').subscribe({
      next: (roles) => {
        this.rolename = roles.user.role;
        this.rolekey = roles.user.key.toLowerCase();
        this.roleofficeid = roles.user.office.id;
        this.currentOfficeId = this.roleofficeid;
      },
      error: (err) => {},
    });
  }

  loadPackages() {
    this.api.get('packages').subscribe({
      next: (packages) => {
        this.packages = packages.packages;
        this.filterPackages = this.packages;
      },
      error: (err) => {},
    });
  }

  deletePackages(id: number): void {
    this.api.delete('packages/delete', Number(id)).subscribe({
      next: (packages) => {
        this.message = packages.message;
        const id = packages.packages.id;
        this.flagDeleted = true;

        this.filterPackages = this.filterPackages.filter(
          (packageData: any) => Number(packageData.id) !== Number(id)
        );
        this.modalPackageClose.close();
      },
      error: (err) => {},
    });

    setTimeout(() => {
      this.flagDeleted = false;
      this.message = '';
    }, 4000);
  }

  confirmDeletePackages(flag: boolean) {
    if (flag === false) {
      this.close();
    }

    this.confirmDelete = flag;
  }

  getOffices() {
    this.api.get('offices').subscribe({
      next: (offices) => {
        this.offices = offices.offices;
      },
      error: (err) => {},
    });
  }

  setCurrentOffice(evt: any) {
    this.api.get(`packages/${evt.target.value}`).subscribe({
      next: (packages) => {
        this.packages = packages.packages;
        this.filterPackages = this.packages;
      },
      error: (err) => {},
    });
  }

  clearSearch() {
    this.filterText = '';
    this.currentOfficeId = this.roleofficeid;
    this.loadPackages();
  }

  openDeleteMessage(id: number, packagename: string): void {
    this.idDeleted = id;
    this.packageNameDeleted = packagename;

    this.modalPackageClose = this.modal.open(this.modalPackage, { size: 'md' });
  }

  close(): void {
    this.modalPackageClose.close();
  }

  matches(listData: any, term: string) {
    return (
      listData.client.name.toLowerCase().includes(term.toLowerCase()) ||
      listData.client.office.toLowerCase().includes(term.toLowerCase()) ||
      listData.price.includes(term) ||
      listData.duration.includes(term) ||
      listData.date_purchased.includes(term)
    );
  }

  filterRecords() {
    const filterData = this.packages.filter((data: any) => {
      return this.matches(data, this.filterText);
    });

    this.filterPackages = filterData;
  }
}
