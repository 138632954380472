import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss'],
})
export class HomeLayoutComponent implements OnInit {
  role: string = '';
  roleKey: string = '';
  nameUser: string = '';
  onlyName: string = '';
  officeName: string = '';
  officeId: Number = 0;
  hasSecondary: boolean = false;
  @ViewChild('changeOffice', { static: false }) changeOffice!: TemplateRef<any>;
  modalClose: any;
  changeForm: any = FormGroup;
  loggedEmployee: any = [];
  offices: any = [];
  secOffices: any = [];
  submit: boolean = false;

  constructor(
    private api: ApiService,
    private modal: NgbModal,
    private router: Router,
    private fb5: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getRoleLoggedIn();
    this.validations();
    this.loggedInEmployee();
    this.getOffices();
  }

  loggedInEmployee() {
    this.api.get('employee').subscribe({
      next: (employee) => {
        if (typeof employee !== undefined) {
          this.loggedEmployee = employee.employee;
        }
      },
      error: (err) => {},
    });
  }

  getOffices() {
    this.api.get('offices').subscribe({
      next: (offices) => {
        this.offices = offices.offices;
      },
      error: (err) => {},
    });
  }

  getRoleLoggedIn(): void {
    this.api.get('employees/roles').subscribe({
      next: (roles) => {
        this.role = roles.user.role;
        this.roleKey = roles.user.key.toLowerCase();
        this.nameUser = roles.user.name;
        this.onlyName = roles.user.first_name;
        this.officeName = roles.user.office.name;
        this.officeId = Number(roles.user.office.id);
        this.hasSecondary = roles.user.multi_offices;
        this.secOffices = roles.user.offices;
      },
      error: (err) => {},
    });
  }

  validations(): void {
    this.changeForm = this.fb5.group({
      office_id_change: ['', [Validators.required]],
    });
  }

  get cf() {
    return this.changeForm.controls;
  }

  reloadNewOffice(): void {
    this.submit = true;

    if (this.changeForm.invalid) {
      return;
    }

    const obj = {
      office_id: this.changeForm.value.office_id_change,
    };

    this.api
      .update('offices/update', Number(this.loggedEmployee.id), obj)
      .subscribe({
        next: (data) => {
          this.api.setTotalCabins(data.total);
          //this.columnSizesCabins(Number(data.total));
          this.modalClose.close();
          this.submit = false;

          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['redirect-calendar']);
        },
        error: (err) => {},
      });
  }

  openChangeOffice(): void {
    this.offices = this.hasSecondary ? this.secOffices : this.offices;
    this.modalClose = this.modal.open(this.changeOffice, { size: 'md' });
  }
}
