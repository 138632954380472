<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Editar m&eacute;todo de pago</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-5">
    <a
      [routerLink]="['/payments-methods/list']"
      class="btn btn-outline-success"
    >
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de m&eacute;todos de pago
    </a>
  </div>

  <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
    <div *ngIf="isDisplayed" class="alert alert-danger" role="alert">
      {{ generalMessage }}
    </div>
    <div *ngIf="isSaved" class="alert alert-success" role="alert">
      {{ generalMessage }}
    </div>
    <form
      [formGroup]="paymentMethod"
      (ngSubmit)="updatePaymentMethod()"
      novalidate
    >
      <div class="accordion" id="cities_accordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="cities-information">
            <button
              class="accordion-button color-header-collapse"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              disabled
            >
              <span class="color-text">
                Actualizaci&oacute;n de M&eacute;todos de Pago
              </span>
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div class="row">
                <div class="mb-3 form-floating">
                  <input
                    type="text"
                    name="payment_method"
                    id="payment_method"
                    formControlName="payment_method"
                    class="form-control"
                    placeholder="Nombre del m&eacute;todo de pago"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.payment_method.errors,
                      'is-valid': submitted && !f.payment_method.errors
                    }"
                  />
                  <label for="cycle" class="ms-2">
                    Nombre del m&eacute;todos de pago
                  </label>
                </div>
                <div class="form-check form-switch ms-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    formControlName="is_active"
                  />
                  <label class="form-check-label" for="flexSwitchCheckDefault">
                    Activado / Desactivado ?
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3 mt-2 d-grid gap-2 d-md-flex justify-content-md-end">
        <button type="submit" class="btn btn-success">
          <i class="bi bi-arrow-clockwise"></i>
          Actualizar
        </button>
      </div>
    </form>
  </div>
</div>
