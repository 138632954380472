import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-create-office',
  templateUrl: './create-office.component.html',
  styleUrls: ['./create-office.component.scss'],
  providers: [NgbTimepickerConfig],
})
export class CreateOfficeComponent implements OnInit {
  officesForm: any = FormGroup;
  submitted: boolean = false;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  generalMessage: string = '';
  breadcrumbs: any;
  cities: any = [];
  startOffice: { hour: any; minute: any; second: any } = {
    hour: '',
    minute: '',
    second: '',
  };
  endOffice: { hour: any; minute: any; second: any } = {
    hour: '',
    minute: '',
    second: '',
  };

  constructor(private api: ApiService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de sucursales',
        path: '/offices/list',
        link: true,
      },
      {
        name: 'Crear sucursal',
        link: false,
      },
    ];

    this.validators();
    this.getCities();
  }

  getCities() {
    this.api.get('cities').subscribe({
      next: (cities) => {
        this.startOffice = {
          hour: 8,
          minute: 0,
          second: 0,
        };
        this.endOffice = {
          hour: 21,
          minute: 0,
          second: 0,
        };

        this.cities = cities.cities;
      },
      error: (err) => {},
    });
  }

  get f() {
    return this.officesForm.controls;
  }

  validators() {
    this.officesForm = this.fb.group({
      city_id: ['', [Validators.required]],
      name: ['', [Validators.required, Validators.minLength(5)]],
      address: ['', [Validators.required, Validators.minLength(5)]],
    });
  }

  createOffice() {
    this.submitted = true;

    if (this.officesForm.invalid) {
      return;
    }

    const obj = {
      cityId: this.officesForm.value.city_id,
      office_name: this.officesForm.value.name,
      office_address: this.officesForm.value.address,
      start_office: `${this.startOffice.hour}:${this.startOffice.minute}:${this.startOffice.second}`,
      end_office: `${this.endOffice.hour}:${this.endOffice.minute}:${this.endOffice.second}`,
    };

    this.api.create('offices', obj).subscribe({
      next: (office) => {
        this.submitted = false;
        this.isSaved = true;
        this.generalMessage = office.message;

        this.resetOfficeForm();
      },
      error: (err) => {},
    });
  }

  resetOfficeForm() {
    this.officesForm.reset();

    setTimeout(() => {
      this.submitted = false;
      this.isDisplayed = false;
      this.isSaved = false;
      this.generalMessage = '';
    }, 4000);
  }
}
