<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Dar de alta prospecto</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-5">
    <a [routerLink]="['/prospects/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de prospectos
    </a>
  </div>

  <div class="col-md-12 col-sm-12 col-lg-12 mx-auto">
    <div *ngIf="isDisplayed" class="alert alert-danger" role="alert">
      {{ generalMessage }}
    </div>
    <div *ngIf="isSaved" class="alert alert-success" role="alert">
      {{ generalMessage }}
    </div>
  </div>

  <form [formGroup]="prospectsForm" (submit)="createProspect()" novalidate>
    <div class="accordion" id="prospects">
      <div class="accordion-item">
        <h2 class="accordion-header" id="prospects-information">
          <button
            class="accordion-button color-header-collapse"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
            disabled
          >
            <span class="color-text"> Alta de prospectos </span>
          </button>
        </h2>
        <div
          class="accordio-collapse collapse show"
          id="collapseOne"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <div class="row">
              <div class="col-md-6 form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="nombre"
                  name="nombre"
                  formControlName="nombre"
                  placeholder="Nombre del prospecto"
                  required
                  [ngClass]="{
                    'is-invalid': submittedProspect && f.nombre.errors,
                    'is-valid': submittedProspect && !f.nombre.errors
                  }"
                />

                <label for="nombre" class="ms-2">Nombre del prospecto</label>
              </div>
              <div class="col-md-6 form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="telefono"
                  name="telefono"
                  formControlName="telefono"
                  placeholder="Telefono (ex: 3322445566)"
                  required
                  [ngClass]="{
                    'is-invalid': submittedProspect && f.telefono.errors,
                    'is-valid': submittedProspect && !f.telefono.errors
                  }"
                />
                <label for="telefono" class="ms-2"> Telefono </label>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-6 form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="atendio"
                  name="atendio"
                  formControlName="atendio"
                  placeholder="Encargada"
                  required
                  [ngClass]="{
                    'is-invalid': submittedProspect && f.atendio.errors,
                    'is-valid': submittedProspect && !f.atendio.errors
                  }"
                />
                <label for="atendio" class="ms-2">Encargada</label>
              </div>
              <div class="col-md-6 mb-3 form-floating">
                <select
                  class="form-select"
                  aria-label="Floating label select"
                  id="office"
                  name="office"
                  formControlName="office"
                >
                  <option value="" selected>Selecciona una sucursal</option>
                  <option
                    *ngFor="let office of offices"
                    value="{{ office.id }}"
                  >
                    {{ office.name }}
                  </option>
                </select>
                <label for="office" class="ms-2">
                  Selecciona una sucursal
                </label>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-6">
                <select2
                  [data]="packagesFilter"
                  style="width: 100%"
                  displaySearchStatus="always"
                  placeholder="Areas de interes"
                  formControlName="areas"
                  multiple
                  listPosition="below"
                  styleMode="material"
                  noResultMessage="Area no encontrada"
                  hideSelectedItems
                  (update)="updateAreas($event)"
                ></select2>
              </div>
              <div class="col-md-6 mb-3 form-floating">
                <select
                  name="medio"
                  aria-label="Floating label select"
                  formControlName="medio"
                  id="medio"
                  class="form-select"
                >
                  <option value="" selected>
                    Selecciona un medio de captacion
                  </option>
                  <option value="Facebook">Facebook</option>
                  <option value="Instagram">Instagram</option>
                  <option value="Sucursal">Sucursal</option>
                  <option value="Referido">Referido</option>
                </select>
                <label for="medio" class="ms-2">Medio de contacto</label>
              </div>
            </div>

            <div class="input-group mb-3">
              <span class="input-group-text"> Comentarios </span>
              <textarea
                name="comments"
                id="comments"
                class="form-control"
                formControlName="comments"
                aria-label="Comentarios"
                [ngClass]="{
                  'is-invalid': submittedProspect && f.comments.errors,
                  'is-valid': submittedProspect && !f.comments.errors
                }"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-3 mt-2 d-grip gap-2 d-md-flex justify-content-md-end">
      <button
        type="button"
        class="btn btn-danger"
        (click)="resetProspectForm()"
      >
        <i class="bi bi-eraser"></i>
        Limpiar
      </button>
      <button type="submit" class="btn btn-success">
        <i class="bi bi-plus-circle"></i>
        Dar de alta
      </button>
    </div>
  </form>
</div>
