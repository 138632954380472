<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Ingresos / Egresos</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-3">
    <a [routerLink]="['/small-box/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de caja chica
    </a>
  </div>
  <hr />

  <ul class="nav nav-tabs nav-fill mb-3" id="ex1" role="tablist">
    <li class="nav-item" role="presentation">
      <a
        class="nav-link active"
        id="ex2-tab-1"
        data-bs-toggle="tab"
        href="#ex2-tabs-1"
        role="tab"
        aria-controls="ex2-tabs-1"
        aria-selected="true"
      >
        Ingresos
      </a>
    </li>
    <li class="nav-item" role="presentation">
      <a
        class="nav-link"
        id="ex2-tab-2"
        data-bs-toggle="tab"
        href="#ex2-tabs-2"
        role="tab"
        aria-controls="ex2-tabs-2"
        aria-selected="false"
      >
        Egresos
      </a>
    </li>
  </ul>
  <div class="tab-content" id="ex2-content">
    <div
      class="tab-pane fade show active"
      id="ex2-tabs-1"
      role="tabpanel"
      aria-labelledby="ex2-tab-1"
    >
      <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
        <div
          class="alert alert-success"
          role="alert"
          *ngIf="flagInner && submittedInner"
        >
          {{ messageInner }}
        </div>

        <form
          [formGroup]="innerForm"
          (ngSubmit)="createInnerPayment()"
          novalidate
        >
          <div class="accordion" id="cities_accordion">
            <div class="accordion-item">
              <h2 class="accordion-header" id="cities-information">
                <button
                  class="accordion-button color-header-collapse"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  disabled
                >
                  <span class="color-text"> Agregar un ingreso </span>
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div class="row">
                    <!-- start-->
                    <!-- ponner los remaining en null -->
                    <div class="mb-3">
                      <select2
                        [data]="clientsFilter"
                        displaySearchStatus="always"
                        style="width: 100%"
                        placeholder="Clientes"
                        resettable
                        listPosition="below"
                        styleMode="material"
                        formControlName="sessionTemp"
                        noResultMessage="Cliente no encontrado"
                        (update)="updateClientSession($event)"
                        [ngClass]="{
                          'is-invalid': submittedInner && fe.sessionId.errors,
                          'is-valid': submittedInner && !fe.sessionId.errors
                        }"
                      ></select2>
                    </div>
                    <!-- -->
                    <input
                      type="hidden"
                      name="sessionId"
                      formControlName="sessionId"
                    />
                    <div class="mb-3">
                      <input
                        type="hidden"
                        formControlName="amount"
                        id="amount"
                      />
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          formControlName="extra"
                          name="extra"
                          value="1"
                          (change)="changeElement($event)"
                          id="extra1"
                        />
                        <label class="form-check-label ms-2" for="extra1">
                          Rastrillo
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          formControlName="extra"
                          name="extra"
                          value="2"
                          (change)="changeElement($event)"
                          id="extra2"
                        />
                        <label class="form-check-label ms-2" for="extra2">
                          Perfilador
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          formControlName="extra"
                          name="extra"
                          value="3"
                          (change)="changeElement($event)"
                          id="extra3"
                        />
                        <label class="form-check-label ms-2" for="extra3">
                          Ambos
                        </label>
                      </div>
                    </div>

                    <div class="form-floating mb-3">
                      <select
                        name="paymentMethodId"
                        id="paymentMethodId"
                        class="form-control"
                        formControlName="paymentMethodId"
                        placeholcer="Metodo de pago"
                        (change)="checkPaymentMethods($event)"
                        required
                        [ngClass]="{
                          'is-invalid':
                            submittedInner && fe.paymentMethodId.errors,
                          'is-valid':
                            submittedInner && !fe.paymentMethodId.errors
                        }"
                      >
                        <option value="" selected>
                          Seleccione un metodo de pago
                        </option>
                        <option
                          value="{{ paymentMethod.id }}"
                          *ngFor="let paymentMethod of payment_methods"
                        >
                          {{ paymentMethod.payment_name }}
                        </option>
                      </select>
                      <label for="paymentMethodId" class="ms-3">
                        Seleccione un metodo de pago
                      </label>
                    </div>

                    <div
                      class="form-floating mb-3"
                      *ngIf="paymentsFilter.length > 0"
                    >
                      <select
                        name="paymentId"
                        id="paymentId"
                        class="form-control"
                        formControlName="paymentId"
                        placeholcer="Tipo de pago"
                        required
                        [ngClass]="{
                          'is-invalid': submittedInner && fe.paymentId.errors,
                          'is-valid': submittedInner && !fe.paymentId.errors
                        }"
                      >
                        <option value="" selected>
                          Seleccione un tipo de pago
                        </option>
                        <option
                          value="{{ payment.id }}"
                          *ngFor="let payment of paymentsFilter"
                        >
                          {{ payment.name }}
                        </option>
                      </select>
                      <label for="paymentMethodId" class="ms-3">
                        Tipo de pago
                      </label>
                    </div>
                    <div
                      class="form-floating mb-3"
                      *ngIf="paymentsFilter.length > 0"
                    >
                      <select
                        name="terminal"
                        id="terminal"
                        class="form-control"
                        formControlName="terminal"
                        placeholcer="Selecciona una terminal"
                        required
                        [ngClass]="{
                          'is-invalid': submittedInner && fe.terminal.errors,
                          'is-valid': submittedInner && !fe.terminal.errors
                        }"
                      >
                        <option value="" selected>
                          Seleccione una terminal
                        </option>
                        <option value="Banorte">Banorte</option>
                        <option value="BBVA">BBVA</option>
                        <option value="Firstdata">Firstdata</option>
                        <option value="Netpay">Netpay</option>
                        <option value="Getnet">Getnet</option>
                      </select>
                      <label for="paymentMethodId" class="ms-3">
                        Selecciona una terminal
                      </label>
                    </div>
                    <!--last-->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-3 mt-2 d-grid gap-2 d-md-flex justify-content-md-end">
            <button type="button" class="btn btn-danger" (click)="resetForm()">
              <i class="bi bi-eraser"></i>
              Limpiar Informaci&oacute;n
            </button>
            <button type="submit" class="btn btn-success">
              <i class="bi bi-plus-circle"></i>
              Crear Ingreso
            </button>
          </div>
        </form>
      </div>
    </div>
    <div
      class="tab-pane fade"
      id="ex2-tabs-2"
      role="tabpanel"
      aria-labelledby="ex2-tab-2"
    >
      <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
        <div
          class="alert alert-success"
          role="alert"
          *ngIf="flagOuter && submittedOuter"
        >
          {{ messageOuter }}
        </div>

        <form
          [formGroup]="outerForm"
          (ngSubmit)="createOuterPayment()"
          novalidate
        >
          <div class="accordion" id="cities_accordion">
            <div class="accordion-item">
              <h2 class="accordion-header" id="cities-information">
                <button
                  class="accordion-button color-header-collapse"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  disabled
                >
                  <span class="color-text"> Agregar un Egreso </span>
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div class="row">
                    <div class="mb-3 form-floating col-md-6">
                      <input
                        type="hidden"
                        name="officeId"
                        formControlName="officeId"
                      />
                      <input
                        type="text"
                        formControlName="concept"
                        name="concept"
                        id="concept"
                        class="form-control"
                        placeholder="Concepto del pago"
                        required
                        [ngClass]="{
                          'is-invalid': submittedOuter && f.concept.errors,
                          'is-valid': submittedOuter && !f.concept.errors
                        }"
                      />
                      <label for="concept" class="ms-2"
                        >Concepto del pago</label
                      >
                    </div>

                    <div class="mb-3 form-floating col-md-6">
                      <input
                        type="text"
                        name="amount"
                        id="amount"
                        formControlName="amount"
                        class="form-control"
                        placeholder="Cantidad a pagar"
                        required
                        [ngClass]="{
                          'is-invalid': submittedOuter && f.amount.errors,
                          'is-valid': submittedOuter && !f.amount.errors
                        }"
                      />
                      <label for="role_key" class="ms-2"
                        >Cantidad a pagar</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-3 mt-2 d-grid gap-2 d-md-flex justify-content-md-end">
            <button type="button" class="btn btn-danger" (click)="resetForm()">
              <i class="bi bi-eraser"></i>
              Limpiar Informaci&oacute;n
            </button>
            <button type="submit" class="btn btn-success">
              <i class="bi bi-plus-circle"></i>
              Crear Egreso
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-md-4 mb-3 fs-4 fw-bold">
      Ingreso total:
      <span class="text-success">{{ sumInner | currency }}</span>
    </div>
    <div class="col-md-4 mb-3 fs-4 fw-bold">
      Egreso total:
      <span class="text-success">{{ sumOuter | currency }}</span>
    </div>
    <div class="col-md-4 mb-3 fs-4 fw-bold">
      Total:
      <span class="text-success">{{ sumInner - sumOuter | currency }}</span>
    </div>
  </div>
  <hr />
  <div class="row">
    <h3>Ingresos</h3>
    <div class="alert alert-danger" *ngIf="innerData.length == 0">
      No hay ingresos
    </div>
    <table class="table table-striped" *ngIf="innerData.length != 0">
      <thead>
        <tr>
          <th class="col">Nombre</th>
          <th class="col">Fecha</th>
          <th class="col">Cantidad</th>
          <th class="col"># de sesi&oacute;n</th>
        </tr>
      </thead>
      <tbody *ngFor="let innerRecord of innerData">
        <tr>
          <th class="col">
            {{ innerRecord.client.name }}
          </th>
          <th class="col">
            {{ innerRecord.date }}
          </th>
          <th class="col">
            {{ innerRecord.amount | currency }}
          </th>
          <th class="col">
            Sesi&oacute;n {{ innerRecord.session.numberSession }}
          </th>
        </tr>
      </tbody>
    </table>
  </div>
  <hr />
  <div class="row">
    <h3>Egresos</h3>
    <div class="alert alert-danger" *ngIf="outerData.length == 0">
      No hay egresos
    </div>
    <table class="table table-striped" *ngIf="outerData.length != 0">
      <thead>
        <tr>
          <th class="col">Concepto</th>
          <th class="col">Fecha</th>
          <th class="col">Cantidad</th>
        </tr>
      </thead>
      <tbody *ngFor="let outerRecord of outerData">
        <tr>
          <th class="col">
            {{ outerRecord.concept }}
          </th>
          <th class="col">
            {{ outerRecord.date }}
          </th>
          <th class="col">
            {{ outerRecord.amount | currency }}
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</div>
