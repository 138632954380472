<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Lista de ventas por dia</h3>
  </div>
  <hr />

  <form>
    <div class="mb-3 row">
      <div class="col-md-6">
        <div class="input-group">
          <input
            type="text"
            id="table-complete-search"
            name="searchText"
            class="form-control"
            placeholder="Buscar ventas por dia"
            [(ngModel)]="filterText"
            (keyup)="filterRecords()"
          />

          <select
            class="form-select ms-1"
            name="currentOfficeId"
            [(ngModel)]="currentOfficeId"
            id="currentOfficeId"
            (change)="setCurrentOffice($event)"
            *ngIf="rolekey == 'admin'"
          >
            <option value="0" selected="selected">Selecciona sucursal</option>
            <option
              *ngFor="let office of offices"
              value="{{ office.id }}"
              selected="{ office.id == roleofficeid ? 'selected' : '' }"
            >
              {{ office.name }}
            </option>
          </select>

          <button
            class="btn btn-outline-danger ms-1"
            type="button"
            (click)="clearSearch()"
          >
            Limpiar busqueda
          </button>
        </div>
      </div>
    </div>
    <hr />
    <!-- Search field section -->

    <table class="table table-striped">
      <thead>
        <tr>
          <th class="col">Sucursal</th>
          <th class="col">Fecha</th>
          <th class="col">Total del dia</th>
          <th class="col">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let sell of filterSells
              | paginate : { itemsPerPage: 15, currentPage: page }
          "
        >
          <td>{{ sell.office_name }}</td>
          <td>
            {{ sell.date }}
          </td>
          <td>
            {{ sell.amount | currency }}
          </td>
          <td>
            <div class="d-flex d-flex-row">
              <div>
                <a
                  [routerLink]="[
                    '/daily-sells/display',
                    sell.date,
                    sell.office_id
                  ]"
                  placement="top"
                  ngbTooltip="Reporte de ventas del dia"
                  role="button"
                  class="btn btn-primary"
                >
                  <i class="bi bi-file-spreadsheet-fill"></i>
                </a>
              </div>
              <div class="ms-1">
                <a
                  [routerLink]="[
                    '/daily-sells/display-next',
                    sell.date,
                    sell.office_id
                  ]"
                  placement="top"
                  ngbTooltip="Cuentas por cobrar"
                  role="button"
                  class="btn btn-primary"
                >
                  <i class="bi bi-calendar3"></i>
                </a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="col-md-12 d-flex align-items-center justify-content-center">
      <pagination-controls
        (pageChange)="page = $event"
        nextLabel="Siguiente"
        previousLabel="Anterior"
        class="mt-3"
      ></pagination-controls>
    </div>
  </form>
</div>
