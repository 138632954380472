<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Lista de Areas</h3>
  </div>
  <hr />
  <div class="row align-items-center mb-3">
    <div class="col-md-4" style="margin-left: -2rem">
      <input
        type="text"
        id="table-complete-search"
        name="searchText"
        class="form-control"
        placeholder="Buscar area"
        [(ngModel)]="filterText"
        (keyup)="filterRecords()"
      />
    </div>
    <div class="col-md-6">
      <div class="d-grip gap-1 d-md-flex justify-content-md-end">
        <a [routerLink]="['/areas/create']" class="btn btn-outline-success">
          <i class="bi bi-plus-circle"></i>
          Crear Area Nueva
        </a>
      </div>
    </div>
  </div>
  <hr />

  <form>
    <ngb-alert
      #selfClosingAlert
      type="{{ typeAlert }}"
      *ngIf="generalMessage != ''"
    >
      {{ generalMessage }}
    </ngb-alert>

    <table class="table table-striped">
      <thead>
        <tr>
          <th class="col">ID</th>
          <th class="col">Area</th>
          <th class="col">Precio</th>
          <th class="col">Duraci&oacute;n</th>
          <th class="col">Tipo</th>
          <th class="col">Area Full Body</th>
          <th class="col">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let area of filterAreas
              | paginate : { itemsPerPage: 16, currentPage: page }
          "
        >
          <td scope="row">
            {{ area.id }}
          </td>
          <td>{{ area.area }}</td>
          <td>{{ area.price | currency }}</td>
          <td>{{ area.duration }}</td>
          <td>{{ area.area_type_name }}</td>
          <td>{{ area.isfullbody }}</td>
          <td>
            <div class="d-flex d-flex-row">
              <div>
                <a
                  [routerLink]="['/areas/update', area.id, this.roleofficeid]"
                  placement="top"
                  ngbTooltip="Editar"
                  role="button"
                  class="btn btn-primary"
                >
                  <i class="bi bi-pencil-square"></i>
                </a>
              </div>
              <div class="ms-2">
                <a
                  [routerLink]="['/areas/show', area.id, this.roleofficeid]"
                  placement="top"
                  ngbTooltip="Informacion"
                  role="button"
                  class="btn btn-primary"
                >
                  <i class="bi bi-person-bounding-box"></i>
                </a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="col-md-12 d-flex align-items-center justify-content-center">
      <pagination-controls
        (pageChange)="page = $event"
        nextLabel="Siguiente"
        previousLabel="Anterior"
        class="mt-3"
      ></pagination-controls>
    </div>
  </form>
</div>
