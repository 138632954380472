import { Component } from '@angular/core';

@Component({
  selector: 'app-payrolls',
  templateUrl: './payrolls.component.html',
  styleUrls: ['./payrolls.component.scss']
})
export class PayrollsComponent {

}
