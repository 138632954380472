import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgbModal, NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-list-weekly-sells',
  templateUrl: './list-weekly-sells.component.html',
  styleUrls: ['./list-weekly-sells.component.scss'],
})
export class ListWeeklySellsComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  @ViewChild('createReportModal', { static: false })
  createReportModal!: TemplateRef<any>;
  createReportModalClose: any;
  createReport: any = FormGroup;
  breadcrumbs: any = [];
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  submitted: boolean = false;
  generalMessage: string = '';
  reportsData: any = [];
  reportsDataFilter: any = [];
  _message = new Subject<string>();
  page: number = 1;

  constructor(
    private api: ApiService,
    private modal: NgbModal,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this._message.subscribe(
      (messageData: string) => (this.generalMessage = messageData)
    );
    this._message.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
        this.isSaved = false;
        this.isDisplayed = false;
        this.generalMessage = '';
        this.submitted = false;
      }
    });

    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Reportes',
        link: false,
      },
    ];

    this.validations();
    this.loadReports();
  }

  validations() {
    this.createReport = this.fb.group({
      start_date: ['', [Validators.required]],
      end_date: ['', [Validators.required]],
      type: ['', [Validators.required]],
    });
  }

  loadReports() {
    this.api.get('reports').subscribe({
      next: (report) => {
        this.reportsData = report.reports;
        this.reportsDataFilter = this.reportsData;
      },
      error: (err) => {},
    });
  }

  exportExcel(id: number, start: string, end: string, type: string) {
    const params = `${start}/${end}/${id}/${type}`;
    this.api.getById('report-excel', params).subscribe({
      next: (report) => {
        window.open(report.path_to_download, '_blank');
      },
      error: (err) => {},
    });
  }

  openCreateReport(): void {
    this.createReportModalClose = this.modal.open(this.createReportModal, {
      size: 'lg',
    });
  }

  get f() {
    return this.createReport.controls;
  }

  close(): void {
    this.createReportModalClose.close();
  }

  createReportRequest() {
    this.submitted;

    if (this.createReport.invalid) {
      this.submitted = false;
      return;
    }

    const obj = {
      start: `${this.createReport.value.start_date.year}-${this.createReport.value.start_date.month}-${this.createReport.value.start_date.day}`,
      end: `${this.createReport.value.end_date.year}-${this.createReport.value.end_date.month}-${this.createReport.value.end_date.day}`,
      type: this.createReport.value.type,
    };

    this.api.create('reports', obj).subscribe({
      next: (report) => {
        this.submitted = false;
        this.isSaved = true;
        this.isDisplayed = false;
        this.close();
        this._message.next(report.message);
        this.resetCreateReport();
        this.loadReports();
      },
      error: (err) => {},
    });
  }

  resetCreateReport() {
    this.createReport.reset();
  }
}
