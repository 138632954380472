import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  invokeEvent: Subject<any> = new Subject();
  invokeEvent2: Subject<any> = new Subject();

  callMethodName(data: any) {
    this.invokeEvent.next(data);
  }

  callMethodName2(data: any) {
    this.invokeEvent2.next(data);
  }
}
