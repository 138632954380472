import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { NgbTimepickerConfig, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-create-employee',
  templateUrl: './create-employee.component.html',
  styleUrls: ['./create-employee.component.scss'],
  providers: [NgbTimepickerConfig],
})
export class CreateEmployeeComponent implements OnInit {
  employeesForm: any = FormGroup;
  submitted: boolean = false;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  generalMessage: string = '';
  breadcrumbs: any;
  offices: any = [];
  cities: any = [];
  roles: any = [];
  filterOffices: any = [];
  officesData: any = [];
  flagErrors: boolean = false;
  flagTimeErr1: boolean = false;
  flagTimeErr2: boolean = false;
  timeStart: NgbTimeStruct = { hour: 13, minute: 0, second: 0 };
  timeEnd: NgbTimeStruct = { hour: 14, minute: 0, second: 0 };
  startEmployee: { hour: any; minute: any; second: any } = {
    hour: '',
    minute: '',
    second: '',
  };
  endEmployee: { hour: any; minute: any; second: any } = {
    hour: '',
    minute: '',
    second: '',
  };
  hasSecondary: boolean = false;

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private currencyPipe: CurrencyPipe,
    public config: NgbTimepickerConfig
  ) {
    config.seconds = false;
    config.spinners = false;
  }

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de colaboradores',
        path: '/employees/list',
        link: true,
      },
      {
        name: 'Crear colaborador',
        link: false,
      },
    ];
    this.startEmployee = { hour: 10, minute: 0, second: 0 };
    this.endEmployee = { hour: 20, minute: 0, second: 0 };

    this.validations();
    this.getOffices();
    this.getCities();
    this.getRoles();
  }

  validations(): void {
    this.employeesForm = this.fb.group({
      cityId: ['', [Validators.required]],
      officeId: ['', [Validators.required]],
      roleId: ['', [Validators.required]],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      password: ['', [Validators.required, Validators.minLength(5)]],
      cpassword: ['', [Validators.required, Validators.minLength(5)]],
      firstName: ['', [Validators.required, Validators.minLength(3)]],
      lastName: ['', [Validators.required, Validators.minLength(3)]],
      phone: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(15),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      address: ['', [Validators.required, Validators.minLength(5)]],
      salaryBase: ['', [Validators.required]],
      restDay: ['', [Validators.required]],
      extraRestDay: this.fb.array([]),
      secondary_offices: [''],
      temp_offices: [''],
      has_secondary: [''],
    });

    this.employeesForm.controls['officeId'].disable();
  }

  numbersOnly(evt: any): boolean {
    const charCode = evt.which ? evt.which : evt.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  format_number(event: any) {
    var charCode = event.which ? event.which : event.keyCode;
    if (
      charCode != 46 &&
      charCode != 44 &&
      charCode > 31 &&
      (charCode < 48 || charCode > 57)
    ) {
      return false;
    }

    const currencyFormat = this.currencyPipe.transform(+event.target.value);
    this.employeesForm.patchValue({
      salaryBase: currencyFormat,
    });
    return true;
  }

  get f() {
    return this.employeesForm.controls;
  }

  /**
   * new code
   */
  newRestDay(): FormGroup {
    return this.fb.group({
      newRestDay: '',
    });
  }

  totalNewRestDay(): FormArray {
    return this.employeesForm.get('extraRestDay') as FormArray;
  }

  addExtraRestDay() {
    this.totalNewRestDay().push(this.newRestDay());
  }

  removeExtraRestDay(i: number): void {
    this.totalNewRestDay().removeAt(i);
  }

  /**
   * end new code
   */

  getOffices(): void {
    this.api.get('offices').subscribe({
      next: (offices) => {
        this.offices = offices.offices;
        const offices_data = this.offices.map((data: any) => {
          return {
            value: data.id,
            label: data.name,
          };
        });

        this.officesData = [{ options: offices_data }];
      },
      error: (err) => {},
    });
  }

  getCities(): void {
    this.api.get('cities').subscribe({
      next: (cities) => {
        this.cities = cities.cities;
      },
      error: (err) => {},
    });
  }

  getRoles(): void {
    this.api.get('roles').subscribe({
      next: (roles) => {
        this.roles = roles.roles;
      },
      error: (err) => {},
    });
  }

  createNewEmployee(): void {
    this.submitted = true;
    this.flagErrors = false;
    this.flagTimeErr1 = false;
    this.flagTimeErr2 = false;

    if (this.employeesForm.invalid) {
      this.flagErrors = true;
      return;
    }

    if (
      this.employeesForm.value.password !== this.employeesForm.value.cpassword
    ) {
      this.flagErrors = true;
      return;
    }

    if (this.timeStart == null) {
      this.flagTimeErr1 = true;

      return;
    }

    if (this.timeEnd == null) {
      this.flagTimeErr2 = true;

      return;
    }

    const timeHour1 =
      String(this.timeStart.hour).length == 1
        ? '0' + this.timeStart.hour
        : this.timeStart.hour;
    const timeMinute1 =
      String(this.timeStart.minute).length == 1
        ? '0' + this.timeStart.minute
        : this.timeStart.minute;
    const timeHour2 =
      String(this.timeEnd.hour).length == 1
        ? '0' + this.timeEnd.hour
        : this.timeEnd.hour;
    const timeMinute2 =
      String(this.timeEnd.minute).length == 1
        ? '0' + this.timeEnd.minute
        : this.timeEnd.minute;
    const timeStartData = `${timeHour1}:${timeMinute1}:00`;
    const timeEndData = `${timeHour2}:${timeMinute2}:00`;

    const obj = {
      cityId: this.employeesForm.value.cityId,
      officeId: this.employeesForm.value.officeId,
      roleId: this.employeesForm.value.roleId,
      email: this.employeesForm.value.email,
      password: this.employeesForm.value.password,
      first_name: this.employeesForm.value.firstName,
      last_name: this.employeesForm.value.lastName,
      phone: this.employeesForm.value.phone,
      address: this.employeesForm.value.address,
      salaryBase: this.employeesForm.value.salaryBase
        .replace(',', '')
        .replace('$', ''),
      foodStart: timeStartData,
      foodEnd: timeEndData,
      freeDay: this.employeesForm.value.restDay,
      extraFreeDay: this.employeesForm.value.extraRestDay,
      startHour: `${this.startEmployee.hour}:${this.startEmployee.minute}:${this.startEmployee.second}`,
      endHour: `${this.endEmployee.hour}:${this.endEmployee.minute}:${this.endEmployee.second}`,
      hasSecondary: this.employeesForm.value.has_secondary,
      secOffices: this.employeesForm.value.secondary_offices,
    };

    this.api.create('employees', obj).subscribe({
      next: (employee) => {
        this.submitted = false;
        this.isSaved = true;
        this.generalMessage = employee.message;

        this.resetEmployees();
      },
      error: (err) => {},
    });
  }

  updateOffices(evt: any) {
    this.employeesForm.patchValue({
      secondary_offices: evt.value,
    });
  }

  activateSecondary(evt: any) {
    if (evt.target.checked) {
      this.hasSecondary = true;
    } else {
      this.hasSecondary = false;
    }
  }

  selectState(evt: any) {
    const stateId = Number(evt.target.value);
    this.filterOffices = this.offices.filter(
      (office: any) => office.city.id === stateId
    );

    if (this.filterOffices.length == 0) {
      this.employeesForm.controls['officeId'].disable();
    } else {
      this.employeesForm.controls['officeId'].enable();
    }
  }

  resetEmployees(): void {
    this.employeesForm.reset();
    this.employeesForm.get('cityId').setValue('');
    this.employeesForm.get('officeId').setValue('');
    this.employeesForm.get('roleId').setValue('');
    this.employeesForm.get('restDay').setValue('');
    this.timeStart = { hour: 13, minute: 0, second: 0 };
    this.timeEnd = { hour: 14, minute: 0, second: 0 };
    this.startEmployee = { hour: 10, minute: 0, second: 0 };
    this.endEmployee = { hour: 21, minute: 0, second: 0 };
    this.hasSecondary = false;

    setTimeout(() => {
      this.flagErrors = false;
      this.submitted = false;
      this.isDisplayed = false;
      this.isSaved = false;
      this.flagTimeErr1 = false;
      this.flagTimeErr2 = false;
      this.generalMessage = '';
      this.employeesForm.controls['officeId'].disable();
    }, 4000);
  }
}
