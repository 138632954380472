import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-list-office',
  templateUrl: './list-office.component.html',
  styleUrls: ['./list-office.component.scss'],
})
export class ListOfficeComponent implements OnInit {
  filterText: string = '';
  filterOffice: any = [];
  offices: any = [];
  breadcrumbs: any;
  page: number = 1;

  constructor(private api: ApiService) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de sucursales',
        link: false,
      },
    ];

    this.getOffices();
  }

  getOffices() {
    this.api.get('offices').subscribe({
      next: (offices) => {
        this.offices = offices.offices;
        this.filterOffice = this.offices;
      },
      error: (err) => {},
    });
  }

  clearSearch() {
    this.filterText = '';
    this.getOffices();
  }

  matches(listData: any, term: string) {
    return (
      listData.name.toLowerCase().includes(term.toLowerCase()) ||
      listData.address.toLowerCase().includes(term.toLowerCase()) ||
      listData.city.name.toLowerCase().includes(term.toLowerCase())
    );
  }

  filterRecords(): void {
    const filterData = this.offices.filter((data: any) => {
      return this.matches(data, this.filterText);
    });

    this.filterOffice = filterData;
  }
}
