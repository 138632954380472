<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Editar cabinas</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-5">
    <a [routerLink]="['/cabins/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de cabinas
    </a>
  </div>

  <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
    <div *ngIf="isDisplayed" class="alert alert-danger" role="alert">
      {{ generalMessage }}
    </div>
    <div *ngIf="isSaved" class="alert alert-success" role="alert">
      {{ generalMessage }}
    </div>

    <form [formGroup]="cabinsForm" (ngSubmit)="updateCabins()" novalidate>
      <div class="accordion" id="cities_accordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="cities-information">
            <button
              class="accordion-button color-header-collapse"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              disabled
            >
              <span class="color-text"> Actualizaci&oacute;n de Cabinas </span>
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-6 mb-3 form-floating">
                  <input
                    type="text"
                    name="cabinName"
                    id="cabinName"
                    formControlName="cabinName"
                    class="form-control"
                    placeholder="Nombre de la cabina"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.cabinName.errors,
                      'is-valid': submitted && !f.cabinName.errors
                    }"
                  />
                  <label class="ms-2" for="cabinName">
                    Nombre de la cabina
                  </label>
                  <div>
                    <div
                      *ngIf="
                        flagErrors &&
                        f.cabinName?.invalid &&
                        (f.cabinName?.touched || f.cabinName?.errors)
                      "
                    >
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.cabinName?.errors.required"
                      >
                        El nombre de la cabina es requerida.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-3 form-floating">
                  <select
                    class="form-select"
                    aria-label="Floating label select"
                    id="officeId"
                    name="officeId"
                    formControlName="officeId"
                  >
                    <option selected value="" class="">
                      Selecciona una sucursal
                    </option>
                    <option
                      *ngFor="let office of offices"
                      value="{{ office.id }}"
                    >
                      {{ office.name }}
                    </option>
                  </select>
                  <label class="ms-2" for="officeId">
                    Selecciona una sucursal
                  </label>
                  <div>
                    <div
                      *ngIf="
                        flagErrors &&
                        f.officeId?.invalid &&
                        (f.officeId?.touched || f.officeId?.errors)
                      "
                    >
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.officeId?.errors.required"
                      >
                        La selecci&oacute;n de la sucursal es requerida.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <input type="hidden" name="userId" formControlName="userId" />
                  <select2
                    [data]="employeesFilter"
                    [value]="empId"
                    displaySearchStatus="always"
                    placeholder="Empleados"
                    resettable
                    listPosition="below"
                    styleMode="material"
                    formControlName="employee"
                    noResultMessage="Empleado no encontrado"
                    (update)="updateEmployee($event)"
                    style="width: 100%"
                  ></select2>
                </div>
                <div class="col-md-6 mb-3 mt-3">
                  <input
                    *ngIf="flagEnabled"
                    class="form-check-input"
                    type="checkbox"
                    checked
                    formControlName="isEnable"
                    id="isEnable"
                  />
                  <input
                    *ngIf="!flagEnabled"
                    class="form-check-input"
                    type="checkbox"
                    formControlName="isEnable"
                    id="isEnable"
                  />
                  <label class="form-check-label ms-2" for="isEnable">
                    Esta activa la cabina?
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 mt-2 d-grip gap-2 d-md-flex justify-content-md-end">
        <button type="submit" class="btn btn-success">
          <i class="bi bi-plus-circle"></i>
          Actualizar
        </button>
      </div>
    </form>
  </div>
</div>
