import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-next-day-display',
  templateUrl: './next-day-display.component.html',
  styleUrls: ['./next-day-display.component.scss'],
})
export class NextDayDisplayComponent implements OnInit {
  breadcrumbs: any;
  nextPays: any = [];
  paramId: any = '';
  paramOfficeId: any = '';
  formatDate: string = '';

  constructor(private api: ApiService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.paramId = this.route.snapshot.paramMap.get('date');
    this.paramOfficeId = this.route.snapshot.paramMap.get('office_id');
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de ventas por dia',
        path: '/daily-sells/list',
        link: true,
      },
      {
        name: 'Cuentas por cobrar del siguiente dia',
        link: false,
      },
    ];

    this.formatDates();
    this.getNextPays();
  }

  formatDates(): void {
    const split = this.paramId.split('-');
    const date = new Date(Date.UTC(split[2], split[1] - 1, split[0]));
    const dates = new Intl.DateTimeFormat('es-MX', {
      dateStyle: 'full',
      timeStyle: 'long',
      timeZone: 'UTC',
    })
      .format(date)
      .split(' ');

    this.formatDate = `${this.capitalizeLabel(dates[0])}
      ${dates[1]} de ${this.capitalizeLabel(dates[3])} de
      ${dates[5].replace(',', '')}`;
  }

  private capitalizeLabel(label: string): string {
    return label.charAt(0).toUpperCase() + label.slice(1);
  }

  getNextPays() {
    const params = `${this.paramId}/${this.paramOfficeId}`;
    this.api.getById('displayPaymentNextDayReport', params).subscribe({
      next: (nextPay) => {
        this.nextPays = nextPay.sessions;
      },
      error: (err) => {},
    });
  }
}
