import { Component } from '@angular/core';

@Component({
  selector: 'app-list-daily-payments',
  templateUrl: './list-daily-payments.component.html',
  styleUrls: ['./list-daily-payments.component.scss']
})
export class ListDailyPaymentsComponent {

}
