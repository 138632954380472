import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgbAlert, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-list-commissions',
  templateUrl: './list-commissions.component.html',
  styleUrls: ['./list-commissions.component.scss'],
})
export class ListCommissionsComponent implements OnInit {
  private _message = new Subject<string>();
  flagDeleted: boolean = false;
  @ViewChild('modalComision', { static: false })
  modalComision!: TemplateRef<any>;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  @ViewChild('activeCom', { static: false }) activeCom!: NgbAlert;
  modalComisionClose: any;
  filterText: string = '';
  filterComision: any = [];
  comision: any = [];
  breadcrumbs: any = [];
  idDeleted: number = 0;
  idActive: number = 0;
  comisionDeleted: string = '';
  generalMessage: string = '';
  flagActive: boolean = false;
  statusActive: boolean = false;
  typeAlert: string = '';
  page: number = 1;
  offices: any = [];
  rolename: string = '';
  rolekey: string = '';
  roleofficeid: number = 0;
  currentOfficeId: number = 0;

  constructor(private api: ApiService, private modal: NgbModal) {}

  ngOnInit(): void {
    this._message.subscribe(
      (messageData: string) => (this.generalMessage = messageData)
    );
    this._message.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
        this.generalMessage = '';
        this.idDeleted = 0;
        this.idActive = 0;
        this.flagActive = false;
        this.comisionDeleted = '';
      }
    });

    this.breadcrumbs = [
      {
        path: '/dashboard/calenndar',
        name: 'Agenda',
        link: true,
      },
      {
        name: 'Lista de Comisiones',
        link: false,
      },
    ];

    this.getRoles();
    this.getOffices();
    this.getCommission();
  }

  getRoles() {
    this.api.get('employees/roles').subscribe({
      next: (roles) => {
        this.rolename = roles.user.role;
        this.rolekey = roles.user.key.toLowerCase();
        this.roleofficeid = roles.user.office.id;
        this.currentOfficeId = this.roleofficeid;
      },
      error: (err) => {},
    });
  }

  getCommission(): void {
    this.api.get('commissions').subscribe({
      next: (comision) => {
        this.comision = comision.commissions;
        this.filterComision = this.comision;
      },
      error: (err) => {},
    });
  }

  getOffices() {
    this.api.get('offices').subscribe({
      next: (offices) => {
        this.offices = offices.offices;
      },
      error: (err) => {},
    });
  }

  setCurrentOffice(evt: any) {
    this.api.get(`commissions/${evt.target.value}`).subscribe({
      next: (comision) => {
        this.comision = comision.commissions;
        this.filterComision = this.comision;
      },
      error: (err) => {},
    });
  }

  clearSearch() {
    this.filterText = '';
    this.currentOfficeId = this.roleofficeid;
    this.getCommission();
  }

  close(): void {
    this.modalComisionClose.close();
  }

  openDeleteModal(id: number, comisionName: string): void {
    this.idDeleted = id;
    this.comisionDeleted = comisionName;

    this.modalComisionClose = this.modal.open(this.modalComision, {
      size: 'md',
    });
  }

  openActiveModalCommision(id: number, flag: boolean): void {
    this.idActive = id;
    this.statusActive = flag;

    this.modalComisionClose = this.modal.open(this.activeCom, { size: 'md' });
  }

  activeInactiveData(id: number, status: boolean): void {
    this.api
      .update('commission/status', id, { status: this.statusActive })
      .subscribe({
        next: (data) => {
          this.flagActive = true;
          this.typeAlert = 'success';
          this._message.next(data.message);
          this.modalComisionClose.close();
          this.getCommission();
        },
        error: (err) => {},
      });
  }

  deleteCommission(id: number): void {
    this.api.delete('commissions', Number(id)).subscribe({
      next: (comision) => {
        this.flagDeleted = true;
        this._message.next(comision.message);
        this.typeAlert = 'success';
        this.close();
        this.getCommission();
      },
      error: (err) => {},
    });
  }

  matches(listData: any, term: string) {
    return (
      listData.title.toLowerCase().includes(term.toLowerCase()) ||
      listData.office.name.toLowerCase().includes(term.toLowerCase()) ||
      listData.role.name.toLowerCase().includes(term.toLowerCase())
    );
  }

  filterRecords() {
    const filterData = this.comision.filter((data: any) => {
      return this.matches(data, this.filterText);
    });

    this.filterComision = filterData;
  }
}
