<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Crear comisi&oacute;n</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-5">
    <a [routerLink]="['/commissions/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de comisiones
    </a>
  </div>

  <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
    <ngb-alert
      #selfClosingAlert
      type="danger"
      *ngIf="generalMessage != '' && isDisplayed"
    >
      {{ generalMessage }}
    </ngb-alert>
    <ngb-alert
      #selfClosingAlert
      type="success"
      *ngIf="generalMessage != '' && isSaved"
    >
      {{ generalMessage }}
    </ngb-alert>

    <form [formGroup]="comisionForm" (ngSubmit)="createCommission()" novalidate>
      <div class="accordion" id="cities_accordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="cities-information">
            <button
              class="accordion-button color-header-collapse"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              disabled
            >
              <span class="color-text"> Creaci&oacute;n de Comisiones </span>
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div class="row">
                <div class="mb-3 form-floating col-md-6">
                  <input
                    type="text"
                    name="title"
                    id="title"
                    formControlName="title"
                    class="form-control"
                    placeholder="Nombre de la comisi&oacute;n"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.title.errors,
                      'is-valid': submitted && !f.title.errors
                    }"
                  />
                  <label for="title" class="ms-2"
                    >Nombre de la comisi&oacute;n</label
                  >
                </div>
                <div class="form-floating mb-3 col-md-6">
                  <select
                    name="office"
                    id="office"
                    class="form-select"
                    aria-label="floating label select"
                    formControlName="office"
                    [ngClass]="{
                      'is-invalid': submitted && f.office.errors,
                      'is-valid': submitted && !f.office.errors
                    }"
                  >
                    <option value="" selected>Selecciona una oficina</option>
                    <option
                      value="{{ office.id }}"
                      *ngFor="let office of offices"
                    >
                      {{ office.name }}
                    </option>
                  </select>
                  <label for="office" class="ms-2">
                    Selecciona una oficina
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="mb-3 mt-2 col-md-6">
                  <label for="percentage" class="ms-2">
                    Porcentaje de la comisi&oacute;n
                  </label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      name="percentage"
                      id="percentage"
                      formControlName="percentage"
                      class="form-control"
                      placeholder="Porcentaje de la comisi&oacute;n"
                      required
                      [ngClass]="{
                        'is-invalid': submitted && f.percentage.errors,
                        'is-valid': submitted && !f.percentage.errors
                      }"
                    />
                    <span class="input-group-text">%</span>
                  </div>
                </div>
                <div class="form-floating mb-3 mt-3 col-md-6">
                  <select
                    name="roleId"
                    id="roleId"
                    class="form-select"
                    aria-label="floating label select"
                    formControlName="roleId"
                    [ngClass]="{
                      'is-invalid': submitted && f.roleId.errors,
                      'is-valid': submitted && !f.roleId.errors
                    }"
                  >
                    <option value="" selected>Selecciona un rol</option>
                    <option value="{{ role.id }}" *ngFor="let role of roles">
                      {{ role.role }}
                    </option>
                  </select>
                  <label for="roleId" class="ms-2"> Selecciona un rol </label>
                </div>
              </div>

              <div class="row">
                <div class="form-floating col-md-6 mb-3 mt-1">
                  <select
                    name="type"
                    id="type"
                    class="form-select"
                    aria-label="floating label select"
                    formControlName="type"
                    [ngClass]="{
                      'is-invalid': submitted && f.type.errors,
                      'is-valid': submitted && !f.type.errors
                    }"
                  >
                    <option value="" selected>
                      Selecciona el tipo de comision
                    </option>
                    <option value="0">Efectivo</option>
                    <option value="1">Tarjeta de Credito</option>
                  </select>
                  <label for="type" class="ms-2">
                    Selecciona el tipo de comision
                  </label>
                </div>
                <div class="col-md-6 mb-3 mt-1">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="active"
                    id="active"
                  />
                  <label class="form-check-label ms-2" for="active">
                    Esta la comisión activa?
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 mt-2 d-grip gap-2 d-md-flex justify-content-md-end">
        <button type="button" class="btn btn-danger" (click)="resetBonusForm()">
          <i class="bi bi-eraser"></i>
          Limpiar Informaci&oacute;n
        </button>
        <button type="submit" class="btn btn-success">
          <i class="bi bi-plus-circle"></i>
          Crear
        </button>
      </div>
    </form>
  </div>
</div>
