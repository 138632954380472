import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-list-payment-methods',
  templateUrl: './list-payment-methods.component.html',
  styleUrls: ['./list-payment-methods.component.scss'],
})
export class ListPaymentMethodsComponent implements OnInit {
  filterText: string = '';
  paymentMethods: any = [];
  filterPaymentMethods: any = [];
  breadcrumbs: any;
  page: number = 1;

  constructor(private api: ApiService) {}

  ngOnInit() {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de metodos de pago',
        link: false,
      },
    ];

    this.loadPaymentMethods();
  }

  loadPaymentMethods() {
    this.api.get('payment_methods').subscribe({
      next: (paymentMethods) => {
        this.paymentMethods = paymentMethods.payment_methods;
        this.filterPaymentMethods = this.paymentMethods;
      },
      error: (err) => {},
    });
  }

  clearSearch() {
    this.filterText = '';
    this.loadPaymentMethods();
  }

  matches(listData: any, term: string) {
    return listData.payment_name.toLowerCase().includes(term);
  }

  filterRecords() {
    const filterData = this.paymentMethods.filter((data: any) => {
      return this.matches(data, this.filterText);
    });

    this.filterPaymentMethods = filterData;
  }
}
