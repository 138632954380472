import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-update-packages',
  templateUrl: './update-packages.component.html',
  styleUrls: ['./update-packages.component.scss'],
})
export class UpdatePackagesComponent implements OnInit {
  @ViewChild('contractModal', { static: false })
  contractModal!: TemplateRef<any>;
  modalContractClose: any;
  packagesForm: any = FormGroup;
  submitted: boolean = false;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  generalMessage: string = '';
  paramId: string | null = '';
  breadcrumbs: any;
  areas: string = '';
  clientName: string = '';
  numberSession: any = 0;
  areasNames: any = [];
  dataAreas: any = [];
  dataDisplay: any = [];
  columnsPayments: number = 12;
  classPayments: number = 12;
  imageContract: string = '';
  status: boolean = false;
  index = 0;
  rolename: string = '';
  rolekey: string = '';
  existsPaymentMethod: boolean = false;
  payments: any = [];
  payment_methods: any = [];
  paymentsFilter: any = [];
  flagErrors: boolean = false;

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private router: ActivatedRoute,
    private modal: NgbModal
  ) {}

  ngOnInit(): void {
    this.paramId = this.router.snapshot.paramMap.get('id');
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de paquetes',
        path: '/packages/list',
        link: true,
      },
      {
        name: 'Editar paquete',
        link: false,
      },
    ];

    this.loadPackages();
    this.validations();
    this.roleInfo();
    this.getPayments();
    this.getPaymentMethods();
  }

  loadPackages() {
    console.log('holas');
    this.api.getById('packages/single', Number(this.paramId)).subscribe({
      next: (packages) => {
        const durationSplit = packages.package.duration.split(':');
        this.clientName = packages.package.client_name;
        this.areas = packages.package.areas;
        this.numberSession = packages.package.sessionNumber;
        this.areasNames = packages.package.areasSessions;
        this.imageContract = packages.package.contract;
        this.getAreasInfo(packages.package.areasSessions);
        this.getPaymentsInfo(packages.package.payments_data);

        this.packagesForm.patchValue({
          name: this.clientName,
          areas: this.areas,
          price: packages.package.price,
          price_discount: packages.package.subtotal,
          session_number: packages.package.sessionNumber,
          discount: packages.package.percentage,
          minutes: durationSplit[1],
          hours: durationSplit[0],
          observations: packages.package.observations,
        });
      },
      error: (err) => {},
    });
  }

  roleInfo() {
    this.api.get('employees/roles').subscribe({
      next: (roles) => {
        this.rolename = roles.user.role;
        this.rolekey = roles.user.key.toLowerCase();
      },
      error: (err) => {},
    });
  }

  getAreasInfo(areas: []): void {
    this.dataAreas = areas;

    this.packagesForm.patchValue({
      custom_sessions: this.dataAreas,
    });
  }

  getPaymentMethods() {
    this.api.get('payment_methods').subscribe({
      next: (paymentMethods) => {
        this.payment_methods = paymentMethods.payment_methods;
      },
      error: (err) => {},
    });
  }

  getPayments() {
    this.api.get('payments').subscribe({
      next: (payments) => {
        this.payments = payments.payments;
      },
      error: (err) => {},
    });
  }

  getPaymentsInfo(data: any): void {
    if (data.payment_name != '') {
      this.existsPaymentMethod = true;
      let sumInvalid: number = 1;

      if (data.terminal.length != 0) {
        sumInvalid++;
      }

      if (data.payment_name.length != 0) {
        sumInvalid++;
      }

      this.columnsPayments = this.columnsPayments / sumInvalid;
      this.packagesForm.patchValue({
        paymentMethodId: data.payment_method_name,
        paymentId: data.payment_name,
        terminal: data.terminal,
      });
    }
  }

  checkPaymentMethods(evt: any): void {
    this.paymentsFilter = this.payments
      .sort((data1: any, data2: any) => data1.order - data2.order)
      .filter(
        (data: any) => data.payment_method_id === Number(evt.target.value)
      );

    if (this.paymentsFilter.length > 0) {
      this.classPayments = 4;
    } else {
      this.classPayments = 12;
    }
  }

  setValueCustomAreas(evt: any, id: number): void {
    const newValue = Number(evt.target.value);
    const newNumberSess: any = [];

    const newArray = this.dataAreas.map((data: any) => {
      if (data.sess_id == id) {
        data.number = newValue;
      }

      return data;
    });

    for (const data of newArray) {
      newNumberSess.push(data.number);
    }
    const totalNumberSess = Math.max(...newNumberSess);

    this.packagesForm.patchValue({
      custom_session: this.dataAreas,
      session_number: totalNumberSess,
    });
  }

  validations() {
    this.packagesForm = this.fb.group({
      name: [''],
      areas: [''],
      price: [''],
      price_discount: [''],
      discount: [''],
      session_number: ['', [Validators.required]],
      hours: ['', [Validators.required]],
      minutes: ['', [Validators.required]],
      custom_sessions: ['', [Validators.required]],
      paymentMethodId: [''],
      paymentId: [''],
      terminal: [''],
      imageData: [''],
      observations: [''],
    });
  }

  async onFileChange(evt: any) {
    this.status = false;
    const file = evt.target.files[0];
    this.status = evt.target.files.length > 0 ? true : false;

    if (this.status) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.packagesForm.patchValue({
          imageData: reader.result,
        });
      };
    }
  }

  get f() {
    return this.packagesForm.controls;
  }

  updatePackages() {
    this.submitted = true;
    this.flagErrors = false;

    if (this.packagesForm.invalid) {
      this.flagErrors = true;
      return;
    }

    const obj = {
      duration: `${this.packagesForm.value.hours}:${this.packagesForm.value.minutes}`,
      session_number: this.packagesForm.value.session_number,
      custom_sessions: this.packagesForm.value.custom_sessions,
      observations: this.packagesForm.value.observations,
      imageData: this.packagesForm.value.imageData,
    };

    this.api.update('packages', Number(this.paramId), obj).subscribe({
      next: (packages) => {
        this.submitted = false;
        this.isSaved = true;
        this.flagErrors = false;
        this.generalMessage = packages.message;
        this.loadPackages();
      },
      error: (err) => {},
    });

    setTimeout(() => {
      this.submitted = false;
      this.isDisplayed = false;
      this.isSaved = false;
      this.flagErrors = false;
      this.generalMessage = '';
    }, 4000);
  }

  displayContract(): void {
    this.modalContractClose = this.modal.open(this.contractModal, {
      size: 'lg',
    });
  }

  close(): void {
    this.modalContractClose.close();
  }
}
