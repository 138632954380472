import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-show-product',
  templateUrl: './show-product.component.html',
  styleUrls: ['./show-product.component.scss'],
})
export class ShowProductComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  breadcrumbs: any = [];
  paramId: string | null = '';
  editProductForm: any = FormGroup;
  submitted: boolean = false;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  generalMessage: string = '';
  private _message = new Subject<string>();

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this._message.subscribe(
      (messageData: string) => (this.generalMessage = messageData)
    );
    this._message.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
        this.generalMessage = '';
        this.isDisplayed = false;
        this.isSaved = false;
        this.submitted = false;
      }
    });

    this.paramId = this.route.snapshot.paramMap.get('id');
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de insumos en venta',
        path: '/products/list',
        link: true,
      },
      {
        name: 'Editar Producto',
        link: false,
      },
    ];

    this.validations();
    this.loadDataProduct();
  }

  validations() {
    this.editProductForm = this.fb.group({
      name: ['', [Validators.required]],
      price: ['', [Validators.required]],
      pieces: ['', [Validators.required]],
      sells: ['', [Validators.required]],
    });
  }

  loadDataProduct(): void {
    this.api.getById('product', Number(this.paramId)).subscribe({
      next: (product) => {
        this.editProductForm.patchValue({
          name: product.product.name,
          price: product.product.price,
          pieces: product.product.pieces,
          sells: product.product.sells,
        });
      },
      error: (err) => {},
    });
  }

  get f() {
    return this.editProductForm.controls;
  }

  updateProduct(): void {
    this.submitted = true;

    if (this.editProductForm.invalid) {
      this.submitted = false;
      return;
    }

    const obj = {
      price: this.editProductForm.value.price,
      name: this.editProductForm.value.name,
      pieces: this.editProductForm.value.pieces,
      sells: this.editProductForm.value.sells,
    };

    this.api.update('product', Number(this.paramId), obj).subscribe({
      next: (product) => {
        this.submitted = false;
        this.isSaved = true;
        this.isDisplayed = false;
        this._message.next(product.message);
      },
      error: (err) => {},
    });
  }
}
