import { Component } from '@angular/core';

@Component({
  selector: 'app-weekly-sells',
  templateUrl: './weekly-sells.component.html',
  styleUrls: ['./weekly-sells.component.scss']
})
export class WeeklySellsComponent {

}
