import { Component } from '@angular/core';

@Component({
  selector: 'app-show-payroll',
  templateUrl: './show-payroll.component.html',
  styleUrls: ['./show-payroll.component.scss']
})
export class ShowPayrollComponent {

}
