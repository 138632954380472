import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-update-cycle',
  templateUrl: './update-cycle.component.html',
  styleUrls: ['./update-cycle.component.scss'],
})
export class UpdateCycleComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  cyclesForm: any = FormGroup;
  submitted: boolean = false;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  generalMessage: string = '';
  messageResponse: string = '';
  breadcrumbs: any;
  paramId: string | null = '';
  private _error = new Subject<string>();

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this._error.subscribe(
      (messageData: string) => (this.generalMessage = messageData)
    );
    this._error.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
        this.isDisplayed = false;
        this.isSaved = false;
        this.generalMessage = '';
        this.messageResponse;
      }
    });

    this.paramId = this.route.snapshot.paramMap.get('id');
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de periodos de pagos',
        path: '/cycles/list',
        link: true,
      },
      {
        name: 'Editar periodo de pago',
        link: false,
      },
    ];

    this.validations();
    this.loadCycle();
  }

  validations() {
    this.cyclesForm = this.fb.group({
      cycle: ['', [Validators.required, Validators.minLength(4)]],
      year: ['', [Validators.required, Validators.minLength(4)]],
      date_start: ['', [Validators.required]],
      date_end: ['', [Validators.required]],
    });
  }

  get f() {
    return this.cyclesForm.controls;
  }

  loadCycle() {
    this.api.getById('cycles', Number(this.paramId)).subscribe({
      next: (cycle) => {
        const startDate = cycle.cycle.start.split('-');
        const endDate = cycle.cycle.end.split('-');

        this.cyclesForm.patchValue({
          cycle: cycle.cycle.cycle,
          year: cycle.cycle.year,
          date_start: {
            year: parseInt(startDate[2]),
            month: parseInt(startDate[1]),
            day: parseInt(startDate[0]),
          },
          date_end: {
            year: parseInt(endDate[2]),
            month: parseInt(endDate[1]),
            day: parseInt(endDate[0]),
          },
        });
      },
      error: (err) => {},
    });
  }

  updateCycle() {
    this.submitted = true;

    if (this.cyclesForm.invalid) {
      return;
    }

    const obj = {
      cycle: this.cyclesForm.value.cycle,
      year: this.cyclesForm.value.year,
      start: `${this.cyclesForm.value.date_start.year}-${this.cyclesForm.value.date_start.month}-${this.cyclesForm.value.date_start.day}`,
      end: `${this.cyclesForm.value.date_end.year}-${this.cyclesForm.value.date_end.month}-${this.cyclesForm.value.date_end.day}`,
    };

    this.api.update('cycles', Number(this.paramId), obj).subscribe({
      next: (cycle) => {
        this.submitted = false;
        this.isDisplayed = false;
        this.isSaved = true;
        this.messageResponse = cycle.message;
        this._error.next(this.messageResponse);
      },
      error: (err) => {},
    });
  }
}
