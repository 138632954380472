<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Informacion de negritas</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-5">
    <a [routerLink]="['/black-debts/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de negritas
    </a>
  </div>
  <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
    <table class="table table-striped">
      <thead class="table-dark">
        <tr>
          <th>Nombre</th>
          <th>Email</th>
          <th>Telefono</th>
          <th>Sucursal</th>
          <th>Areas del paquete</th>
          <th>Direcci&oacute;n</th>
          <th>Total a pagar</th>
          <th>Restante a pagar</th>
          <th>Razon</th>
          <th>Numero de sesi&oacute;n</th>
        </tr>
      </thead>
      <tbody *ngFor="let blackInfo of blackData">
        <tr>
          <td>
            {{ blackInfo.client.first_name }}
            {{ blackInfo.client.last_name }}
          </td>
          <td>{{ blackInfo.client.email }}</td>
          <td>{{ blackInfo.client.phone }}</td>
          <td>{{ blackInfo.office.name }}</td>
          <td>{{ blackInfo.package.info }}</td>
          <td>{{ blackInfo.office.address }}</td>
          <td>{{ blackInfo.remaining | currency }}</td>
          <td style="color: red">{{ blackInfo.debts | currency }}</td>
          <td>{{ blackInfo.reason }}</td>
          <td>{{ blackInfo.session_number }}</td>
        </tr>
      </tbody>
    </table>

    <!-- Historico Negritas -->
    <div class="card">
      <div class="card-header">
        <h4>Historial de Negritas</h4>
      </div>
      <div class="card-body">
        <table class="table table-striped">
          <thead class="table-dark">
            <tr>
              <th>Numero de sesi&oacute;n</th>
              <th>Total del paquete</th>
              <th>Abono inicial</th>
              <th>Restante a pagar</th>
              <th>Razon</th>
            </tr>
          </thead>
          <tbody *ngFor="let historical of blackHistorical; let last = last">
            <tr [ngStyle]="{ 'background-color': last ? '#D1E8FF' : null }">
              <td>{{ historical.sess_num }}</td>
              <td>{{ historical.subtotal | currency }}</td>
              <td>{{ historical.initial | currency }}</td>
              <td style="color: red">
                {{ historical.subtotal - historical.remaining | currency }}
              </td>
              <td>{{ historical.reason }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="mt-5">
      <ngb-alert
        #selfClosingAlert
        type="success"
        *ngIf="generalMessage != '' && isSaved"
      >
        {{ generalMessage }}
      </ngb-alert>
      <form [formGroup]="blackComments" (ngSubmit)="createComment()" novalidate>
        <div class="accordion" id="cities_accordion">
          <div class="accordion-item">
            <h2 class="accordion-header" id="cities-information">
              <button
                class="accordion-button color-header-collapse"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
                disabled
              >
                <span class="color-text"> Comentarios </span>
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="row">
                  <input
                    type="hidden"
                    name="client_id"
                    formControlName="client_id"
                    id="client_id"
                  />
                  <input
                    type="hidden"
                    name="appt_id"
                    formControlName="appt_id"
                    id="appt_id"
                  />
                  <input
                    type="hidden"
                    name="blackDebtsId"
                    formControlName="blackDebtsId"
                    id="blackDebtsId"
                  />
                  <div class="input-group mb-3">
                    <span class="input-group-text"> Comentarios </span>
                    <textarea
                      name="comment"
                      id="comment"
                      class="form-control"
                      formControlName="comment"
                      aria-label="Comentarios"
                      [ngClass]="{
                        'is-invalid': submitted && f.comment.errors,
                        'is-valid': submitted && !f.comment.errors
                      }"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3 mt-2 d-grip gap-2 d-md-flex justify-content-md-end">
          <button type="submit" class="btn btn-success">
            <i class="bi bi-plus-circle"></i>
            Crear
          </button>
        </div>
      </form>
    </div>

    <div class="card">
      <div class="card-header">
        <h4>Comentarios</h4>
      </div>
      <div class="card-body">
        <div *ngIf="blackCommentsData.length == 0">No hay comentarios</div>
        <div *ngIf="blackCommentsData.length != 0">
          <table class="table table-striped">
            <thead class="table-dark">
              <tr>
                <th>ID</th>
                <th>Nombre del cliente</th>
                <th>Numero de Cita</th>
                <th>Comentario hecho por:</th>
                <th>Comentario</th>
                <th>Fecha del comentario</th>
              </tr>
            </thead>
            <tbody *ngFor="let blackComment of blackCommentsData">
              <tr>
                <th>{{ blackComment.black_debts_id }}</th>
                <th>{{ blackComment.client_name }}</th>
                <th>{{ blackComment.appt_id }}</th>
                <th>{{ blackComment.user_name }}</th>
                <th>{{ blackComment.comment }}</th>
                <th>{{ blackComment.created }}</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
