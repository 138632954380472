import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-list-client',
  templateUrl: './list-client.component.html',
  styleUrls: ['./list-client.component.scss'],
})
export class ListClientComponent implements OnInit {
  @ViewChild('modalClient', { static: false }) modalClient!: TemplateRef<any>;
  @ViewChild('modalClientPackages', { static: false })
  modalClientPackages!: TemplateRef<any>;
  modalClientClose: any;
  filterText: string = '';
  clients: any = [];
  filterClients: any = [];
  breadcrumbs: any = [];
  page: number = 1;
  rolename: any = '';
  rolekey: any = '';
  idClient: number = 0;
  clientNameDeleted: string = '';
  flagDeleted: boolean = false;
  message: string = '';
  currentOfficeId: number = 0;
  roleofficeid: number = 0;
  offices: any = [];
  filterPackages: any = [];
  flagDeletePackage: boolean = false;
  flagOpenDeletePackage: boolean = false;
  flagDeletedP: boolean = false;
  packageId: number = 0;

  constructor(private api: ApiService, private modal: NgbModal) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de clientes',
        link: false,
      },
    ];

    this.getClients();
    this.roleInfo();
    this.getOffices();
  }

  roleInfo() {
    this.api.get('employees/roles').subscribe({
      next: (roles) => {
        this.rolename = roles.user.role;
        this.rolekey = roles.user.key.toLowerCase();
        this.roleofficeid = roles.user.office.id;
        this.currentOfficeId = this.roleofficeid;
      },
      error: (err) => {},
    });
  }

  getClients(): void {
    this.api.get('clients').subscribe({
      next: (client) => {
        this.clients = client.clients;
        this.filterClients = this.clients;
      },
      error: (err) => {},
    });
  }

  getOffices() {
    this.api.get('offices').subscribe({
      next: (offices) => {
        this.offices = offices.offices;
      },
      error: (err) => {},
    });
  }

  setCurrentOffice(evt: any) {
    this.api.get(`clients/${evt.target.value}`).subscribe({
      next: (clients) => {
        this.clients = clients.clients;
        this.filterClients = this.clients;
      },
      error: (err) => {},
    });
  }

  deleteClients(id: number): void {
    this.api.delete('clients', id).subscribe({
      next: (data) => {
        this.message = data.message;
        const id = data.client.id;
        this.flagDeleted = true;

        this.filterClients = this.filterClients.filter(
          (clientData: any) => clientData.id !== id
        );

        this.modalClientClose.close();
      },
      error: (err) => {},
    });
  }

  openDeleteClient(
    id: number,
    clientFirstName: string,
    clientLastName: string
  ): void {
    this.idClient = id;
    this.clientNameDeleted = `${clientFirstName} ${clientLastName}`;

    this.modalClientClose = this.modal.open(this.modalClient, { size: 'md' });
  }

  openListPackages(
    id: number,
    clientFirstName: string,
    clientLastName: string
  ): void {
    this.idClient = id;
    this.clientNameDeleted = `${clientFirstName} ${clientLastName}`;
    this.filterPackages = this.filterClients.filter(
      (data: any) => data.id == this.idClient
    );

    this.modalClientClose = this.modal.open(this.modalClientPackages, {
      size: 'xl',
    });
  }

  close(): void {
    this.modalClientClose.close();
  }

  clearSearch() {
    this.filterText = '';
    this.currentOfficeId = this.roleofficeid;
    this.getClients();
  }

  openDeletePackages(id: number): void {
    this.flagOpenDeletePackage = true;
    this.packageId = id;
  }

  deletePackages(id: number): void {
    this.api.delete('packages/delete', Number(id)).subscribe({
      next: (packages) => {
        this.message = packages.message;
        this.flagDeletedP = true;
        this.flagOpenDeletePackage = false;
        this.flagDeletePackage = false;
        this.getClients();
        this.modalClientClose.close();
      },
      error: (err) => {},
    });

    setTimeout(() => {
      this.flagDeletedP = false;
      this.message = '';
    }, 4000);
  }

  matches(listData: any, term: string) {
    return (
      listData.complete.toLowerCase().includes(term.toLowerCase()) ||
      listData.email.toLowerCase().includes(term.toLowerCase()) ||
      listData.phone.includes(term.toLowerCase()) ||
      listData.cp.includes(term.toLowerCase()) ||
      listData.office.name.toLowerCase().includes(term.toLowerCase())
    );
  }

  cancelDeletePackage() {
    this.flagOpenDeletePackage = false;
  }

  filterRecords(): void {
    const filterData = this.clients.filter((data: any) => {
      return this.matches(data, this.filterText);
    });

    this.filterClients = filterData;
  }
}
