<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Lista de Comisiones</h3>
  </div>
  <hr />
  <div class="row align-items-center mb-3">
    <div class="col-md-6">
      <div class="input-group">
        <input
          type="text"
          id="table-complete-search"
          name="searchText"
          class="form-control"
          placeholder="Buscar comisiones"
          [(ngModel)]="filterText"
          (keyup)="filterRecords()"
        />

        <select
          class="form-select ms-1"
          name="currentOfficeId"
          [(ngModel)]="currentOfficeId"
          id="currentOfficeId"
          (change)="setCurrentOffice($event)"
          *ngIf="rolekey == 'admin'"
        >
          <option value="0" selected="selected">Selecciona sucursal</option>
          <option
            *ngFor="let office of offices"
            value="{{ office.id }}"
            selected="{ office.id == roleofficeid ? 'selected' : '' }"
          >
            {{ office.name }}
          </option>
        </select>

        <button
          class="btn btn-outline-danger ms-1"
          type="button"
          (click)="clearSearch()"
        >
          Limpiar busqueda
        </button>
      </div>
    </div>
    <div class="col-md-6">
      <div class="d-grip gap-1 d-md-flex justify-content-md-end">
        <a
          [routerLink]="['/commissions/create']"
          class="btn btn-outline-success"
        >
          <i class="bi bi-plus-circle"></i>
          Crear comisi&oacute;n
        </a>
      </div>
    </div>
  </div>
  <hr />

  <form>
    <ngb-alert
      #selfClosingAlert
      type="{{ typeAlert }}"
      *ngIf="generalMessage != '' && (flagDeleted || flagActive)"
    >
      {{ generalMessage }}
    </ngb-alert>

    <table class="table table-striped">
      <thead>
        <tr>
          <th class="col">ID</th>
          <th class="col">Nombre de la comisi&oacute;n</th>
          <th class="col">Oficina</th>
          <th class="col">Porcentaje</th>
          <th class="col">Rol</th>
          <th class="col">Activo?</th>
          <th class="col">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let com of filterComision
              | paginate : { itemsPerPage: 15, currentPage: page }
          "
        >
          <td scope="row">
            {{ com.id }}
          </td>
          <td>
            {{ com.title }}
          </td>
          <td>
            {{ com.office.name }}
          </td>
          <td>{{ com.percentage }} %</td>
          <td>{{ com.role.name }} ({{ com.role.key | uppercase }})</td>
          <td>{{ com.isEnabledFlag }}</td>
          <td>
            <div class="d-flex d-flex-row">
              <div>
                <a
                  (click)="openActiveModalCommision(com.id, com.isEnabled)"
                  placement="top"
                  ngbTooltip="Habilitar / Deshabilitar"
                  role="button"
                  class="btn btn-primary"
                >
                  <i
                    class="bi bi-eye-fill"
                    *ngIf="com.isEnabledFlag == 'Si'"
                  ></i>
                  <i
                    class="bi bi-eye-slash-fill"
                    *ngIf="com.isEnabledFlag != 'Si'"
                  ></i>
                </a>
              </div>
              <div class="ms-2">
                <a
                  [routerLink]="['/commissions/edit', com.id]"
                  placement="top"
                  ngbTooltip="Editar"
                  role="button"
                  class="btn btn-primary"
                >
                  <i class="bi bi-pencil-square"></i>
                </a>
              </div>
              <div class="ms-2">
                <a
                  (click)="openDeleteModal(com.id, com.title)"
                  placement="top"
                  ngbTooltip="Eliminar"
                  role="button"
                  class="btn btn-primary"
                >
                  <i class="bi bi-trash-fill"></i>
                </a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="col-md-12 d-flex align-items-center justify-content-center">
      <pagination-controls
        (pageChange)="page = $event"
        nextLabel="Siguiente"
        previousLabel="Anterior"
        class="mt-3"
      ></pagination-controls>
    </div>
  </form>
</div>

<ng-template #modalComision let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">Eliminar Comisi&oacute;n</h4>
    <button class="close" type="button" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Desea eliminar la comisi&oacute;n "{{ comisionDeleted }}"</p>
    <div class="row">
      <div class="d-grip gap-2 d-md-flex justify-content-md-end">
        <button
          type="button"
          (click)="deleteCommission(idDeleted)"
          class="btn btn-outline-success"
        >
          Confirmar
        </button>
        <button class="btn btn-outline-danger" type="button" (click)="close()">
          Cancelar
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #activeCom let-close="close">
  <div class="modal-header">
    <h4 class="modal-title" *ngIf="!statusActive">Activar Comisión</h4>
    <h4 class="modal-title" *ngIf="statusActive">Desactivar Comisión</h4>
    <button class="close" type="button" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p *ngIf="!statusActive">Desea activar la comisión?</p>
    <p *ngIf="statusActive">Desea desactivar la comisión?</p>
    <div class="row">
      <div class="d-grip gap-2 d-md-flex justify-content-md-end">
        <button
          type="button"
          (click)="activeInactiveData(idActive, statusActive)"
          class="btn btn-outline-success"
        >
          Confirmar
        </button>
        <button class="btn btn-outline-danger" type="button" (click)="close()">
          Cancelar
        </button>
      </div>
    </div>
  </div>
</ng-template>
