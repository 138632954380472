import { Component } from '@angular/core';

@Component({
  selector: 'app-create-payroll',
  templateUrl: './create-payroll.component.html',
  styleUrls: ['./create-payroll.component.scss']
})
export class CreatePayrollComponent {

}
