<div class="row">
  <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
    <ngb-alert
      #selfClosingAlert
      type="danger"
      *ngIf="errorMessage != '' && isDisplayed"
    >
      {{ errorMessage }}
    </ngb-alert>
    <div class="card">
      <div class="card-header">
        <h4>Iniciar sesi&oacute;n</h4>
      </div>
      <div class="card-body">
        <form [formGroup]="loginForm" (ngSubmit)="loginSubmit()" novalidate>
          <div class="mb-3 form-floating">
            <input
              formControlName="username"
              type="text"
              name="username"
              id="username"
              class="form-control"
              placeholder="name@example.com"
              required
              [ngClass]="{
                'is-invalid': submitted && f.username.errors,
                'is-valid': submitted && !f.username.errors
              }"
            />
            <label for="username">Correo electr&oacute;nico</label>
            <div
              *ngIf="submitted && f.username.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.username.errors.required">
                El correo electr&oacute;nico es requerido.
              </div>
              <div *ngIf="f.username.errors.email">
                El formato del correo electr&oacute;nico es incorrecto.
              </div>
            </div>
          </div>

          <div class="mb-3 form-floating">
            <input
              formControlName="password"
              type="password"
              name="password"
              id="password"
              class="form-control"
              placeholder="password"
              [ngClass]="{
                'is-invalid': submitted && f.password.errors,
                'is-valid': submitted && !f.password.errors
              }"
              required
            />
            <label for="password" class="form-label">Contrase&ntilde;a</label>
            <div
              *ngIf="submitted && f.password.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.password.errors.required">
                La contrase&ntilde;a es requerida.
              </div>
              <div *ngIf="f.password.errors.minlength">
                La contrase&ntilde;a debe contener m&iacute;nimo 6 caracteres.
              </div>
            </div>
          </div>

          <div class="mb-3 d-grid gap-2 d-md-flex justify-content-md-end">
            <button type="submit" class="btn btn-primary">Ingresar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
