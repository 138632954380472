<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Crear m&eacute;todo de pago</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-5">
    <a
      [routerLink]="['/payments-methods/list']"
      class="btn btn-outline-success"
    >
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de m&eacute;todos de pago
    </a>
  </div>

  <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
    <div *ngIf="isDisplayed" class="alert alert-danger" role="alert">
      {{ generalMessage }}
    </div>
    <div *ngIf="isSaved" class="alert alert-success" role="alert">
      {{ generalMessage }}
    </div>

    <form
      class="needs-validation"
      [formGroup]="paymentMethods"
      (ngSubmit)="createPaymentMethod()"
    >
      <!--div class="alert alert-danger" role="alert" *ngIf="flagErrors">
        <div
          *ngIf="
            f.payment_method?.invalid &&
            (f.payment_method?.errors || f.payment_method?.touched)
          "
        >
          <div class="ms-3" *ngIf="f.payment_method?.errors.required">
            Metodo de pago obligatorio, seleccione uno.
          </div>
        </div>
      </div-->
      <div class="accordion" id="cities_accordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="cities-information">
            <button
              class="accordion-button color-header-collapse"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              disabled
            >
              <span class="color-text">
                Creaci&oacute;n de Metodos de Pago
              </span>
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div class="row">
                <div class="mb-3 form-floating">
                  <select
                    class="form-select"
                    aria-label="Floating label select"
                    id="payment_method"
                    name="payment_method"
                    formControlName="payment_method"
                    [ngClass]="{
                      'is-invalid':
                        flagErrors && submitted && f.payment_method.errors,
                      'is-valid':
                        !flagErrors && submitted && !f.payment_method.errors
                    }"
                  >
                    <option selected value="">
                      Selecciona un metodo de pago
                    </option>
                    <option value="Tarjeta de credito">
                      Tarjeta de credito
                    </option>
                    <option value="Tarjeta de debito">
                      Tarjeta de d&eacute;bito
                    </option>
                    <option value="efectivo">Efectivo</option>
                    <option value="Transferencia">Transferencia</option>
                    <option value="Cargo recurrente">Cargo recurrente</option>
                  </select>
                  <label for="select_state" class="ms-2">
                    Selecciona un metodo de pago
                  </label>
                  <div>
                    <div
                      *ngIf="
                        flagErrors &&
                        f.payment_method?.invalid &&
                        (f.payment_method?.errors || f.payment_method?.touched)
                      "
                    >
                      <div
                        class="ms-2 text-danger"
                        *ngIf="f.payment_method?.errors.required"
                      >
                        Metodo de pago obligatorio, seleccione uno.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 mt-2 d-grip gap-2 d-md-flex justify-content-md-end">
        <button
          type="button"
          class="btn btn-danger"
          (click)="resetPaymentMethods()"
        >
          <i class="bi bi-eraser"></i>
          Limpiar Informaci&oacute;n
        </button>
        <button type="submit" class="btn btn-success">
          <i class="bi bi-plus-circle"></i>
          Crear
        </button>
      </div>
    </form>
  </div>
</div>
