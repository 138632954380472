import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-create-cabins',
  templateUrl: './create-cabins.component.html',
  styleUrls: ['./create-cabins.component.scss'],
})
export class CreateCabinsComponent implements OnInit {
  cabinsForms: any = FormGroup;
  submitted: boolean = false;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  generalMessage: string = '';
  breadcrumbs: any = [];
  offices: any = [];
  employees: any = [];
  employeesFilter: any = [];
  flagErrors: boolean = false;
  flagMultiple: boolean = false;
  arrayDataSend: any = [];
  arrayUsers: any = [];

  constructor(private api: ApiService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de cabinas',
        path: '/cabins/list',
        link: true,
      },
      {
        name: 'Crear cabina',
        link: false,
      },
    ];

    this.validations();
    this.getOffices();
    this.getUsers();
  }

  getOffices() {
    this.api.get('offices').subscribe({
      next: (offices) => {
        this.offices = offices.offices;
      },
      error: (err) => {},
    });
  }

  getUsers() {
    this.api.get('employees/office').subscribe({
      next: (employees) => {
        this.employees = employees.employees;
        const employeesPackage = this.employees.map((employeeData: any) => {
          return {
            value: employeeData.id,
            label: `${employeeData.first_name} ${employeeData.last_name}`,
          };
        });
        this.employeesFilter = employeesPackage;
      },
      error: (err) => {},
    });
  }

  get f() {
    return this.cabinsForms.controls;
  }

  validations() {
    this.cabinsForms = this.fb.group({
      officeId: ['', [Validators.required]],
      userId: ['', [Validators.required]],
      cabinName: ['', [Validators.required]],
      isEnable: [true],
      employee: [''],
      schedules: [''],
    });
  }

  createArrayUsers(users: any) {
    this.arrayUsers = [];

    for (let i = 0; i < users.length; i++) {
      const info = this.employees.find((dd: any) => dd.id == users[i]);
      this.arrayUsers.push({
        index: i,
        id: info.id,
        completeName: `${info.first_name} ${info.last_name}`,
      });
    }
  }

  saveInfo(index: number, id: number, horario: any) {
    console.log(this.arrayDataSend);
    this.arrayDataSend.push({
      userId: id,
      option: horario,
    });

    this.cabinsForms.patchValue({
      schedules: this.arrayDataSend,
    });
  }

  updateEmployee(evt: any): void {
    const total = evt.value.length;
    if (total >= 2) {
      this.createArrayUsers(evt.value);
      this.flagMultiple = true;
    } else {
      this.flagMultiple = false;
      this.arrayDataSend = [];
      this.cabinsForms.patchValue({
        schedules: '',
      });
    }

    this.cabinsForms.patchValue({
      userId: evt.value,
    });
  }

  createCabin() {
    this.submitted = true;
    this.flagErrors = false;
    let flagEnabled;

    if (this.cabinsForms.invalid) {
      this.flagErrors = true;
      return;
    }

    if (this.cabinsForms.value.isEnable == true) {
      flagEnabled = true;
    } else {
      flagEnabled = false;
    }

    const obj = {
      cabinName: this.cabinsForms.value.cabinName,
      officeId: this.cabinsForms.value.officeId,
      userId: this.cabinsForms.value.userId,
      isEnabled: flagEnabled,
      schedules: this.cabinsForms.value.schedules,
    };

    this.api.create('cabin', obj).subscribe({
      next: (cabinData) => {
        if (cabinData.cabin.enabled == 1) {
          this.api.setTotalCabins(this.api.getTotalCabinsPerOffice + 1);
        } else {
          this.api.setTotalCabins(this.api.getTotalCabinsPerOffice - 1);
        }

        this.submitted = false;
        this.generalMessage = cabinData.message;
        this.isSaved = true;
        this.isDisplayed = false;
        this.flagErrors = false;
        this.resetCabin();
      },
      error: (err) => {},
    });
  }

  resetCabin() {
    this.cabinsForms.reset();
    this.cabinsForms.get('employee').reset();
    this.cabinsForms.patchValue({
      userId: '',
      schedules: '',
    });
    this.arrayUsers = [];

    setTimeout(() => {
      this.isDisplayed = false;
      this.isSaved = false;
      this.generalMessage = '';
      this.flagErrors = false;
      this.submitted = false;
      this.flagMultiple = false;
    }, 4000);
  }
}
