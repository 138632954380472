<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Lista de m&eacute;todos de pago</h3>
  </div>
  <hr />
  <div class="row align-items-center mb-3">
    <div class="col-md-6">
      <div class="input-group">
        <input
          type="text"
          id="table-complete-search"
          name="searchText"
          class="form-control"
          placeholder="Buscar metodo de pago"
          [(ngModel)]="filterText"
          (keyup)="filterRecords()"
        />

        <button
          class="btn btn-outline-danger ms-1"
          type="button"
          (click)="clearSearch()"
        >
          Limpiar busqueda
        </button>
      </div>
    </div>
    <div class="col-md-6">
      <div class="d-grip gap-1 d-md-flex justify-content-md-end">
        <a
          [routerLink]="['/payments-methods/create']"
          class="btn btn-outline-success"
        >
          <i class="bi bi-plus-circle"></i>
          Crear m&eacute;todo de pago
        </a>
      </div>
    </div>
  </div>
  <hr />

  <form>
    <table class="table table-striped">
      <thead>
        <tr>
          <th class="col">ID</th>
          <th class="col">M&eacute;todo de pago</th>
          <th class="col">Est&aacute; activo ?</th>
          <th class="col">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let payment of filterPaymentMethods
              | paginate : { itemsPerPage: 15, currentPage: page }
          "
        >
          <td scope="row">
            {{ payment.id }}
          </td>
          <td>
            {{ payment.payment_name }}
          </td>
          <td>
            <!-- <i class="bi bi-check-circle-fill"></i> -->
            <!-- <i class="bi bi-x-circle-fill"></i> -->
            {{ payment.is_active ? "Si" : "No" }}
          </td>
          <td>
            <div class="d-flex d-flex-row">
              <div>
                <a
                  [routerLink]="['/payments-methods/edit', payment.id]"
                  placement="top"
                  ngbTooltip="Editar"
                  role="button"
                  class="btn btn-primary"
                >
                  <i class="bi bi-pencil-square"></i>
                </a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="col-md-12 d-flex align-items-center justify-content-center">
      <pagination-controls
        (pageChange)="page = $event"
        nextLabel="Siguiente"
        previousLabel="Anterior"
        class="mt-3"
      ></pagination-controls>
    </div>
  </form>
</div>
