import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  loginForm: any = FormGroup;
  submitted: boolean = false;
  isDisplayed: boolean = false;
  errorMessage: string = '';
  private _error = new Subject<string>();

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this._error.subscribe(
      (messageData: string) => (this.errorMessage = messageData)
    );
    this._error.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
        this.isDisplayed = false;
        this.errorMessage = '';
      }
    });

    this.loginFormValidations();
  }

  get f() {
    return this.loginForm.controls;
  }

  loginFormValidations() {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(5)]],
    });
  }

  getTotalCabins() {
    this.api.get('cabin/total').subscribe({
      next: (data) => {
        localStorage.setItem('total_cabins', data.total_cabins);
        this.router.navigate(['dashboard/calendar']);
      },
      error: (err) => {},
    });
  }

  loginSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    const username = this.loginForm.value.username;
    const password = this.loginForm.value.password;

    this.api.login({ username: username, password: password }).subscribe({
      next: (data) => {
        localStorage.setItem('access_token', data.token);
        localStorage.setItem('refresh_token', data.refresh_token);

        this.getTotalCabins();
        //this.router.navigate(['dashboard/calendar']);
      },
      error: (err) => {
        this.isDisplayed = true;
        this._error.next(
          'Correo electrónico / Contraseña invalida. Por favor verifique sus credenciales.'
        );
      },
    });
  }
}
