import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-create-cycle',
  templateUrl: './create-cycle.component.html',
  styleUrls: ['./create-cycle.component.scss'],
})
export class CreateCycleComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  cyclesForm: any = FormGroup;
  submitted: boolean = false;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  generalMessage: string = '';
  messageResponse: string = '';
  breadcrumbs: any;
  private _error = new Subject<string>();

  constructor(private api: ApiService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this._error.subscribe(
      (messageData: string) => (this.generalMessage = messageData)
    );
    this._error.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
        this.isDisplayed = false;
        this.isSaved = false;
        this.generalMessage = '';
        this.messageResponse = '';
      }
    });

    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de periodos  de pago',
        path: '/cycles/list',
        link: true,
      },
      {
        name: 'Crear periodo de pago',
        link: false,
      },
    ];

    this.validations();
  }

  validations() {
    this.cyclesForm = this.fb.group({
      cycle: ['', [Validators.required, Validators.minLength(4)]],
      year: ['', [Validators.required, Validators.minLength(4)]],
      date_start: ['', [Validators.required]],
      date_end: ['', [Validators.required]],
    });
  }

  get f() {
    return this.cyclesForm.controls;
  }

  createCycle() {
    this.submitted = true;

    if (this.cyclesForm.invalid) {
      return;
    }

    const obj = {
      cycle: this.cyclesForm.value.cycle,
      year: this.cyclesForm.value.year,
      start: `${this.cyclesForm.value.date_start.year}-${this.cyclesForm.value.date_start.month}-${this.cyclesForm.value.date_start.day}`,
      end: `${this.cyclesForm.value.date_end.year}-${this.cyclesForm.value.date_end.month}-${this.cyclesForm.value.date_end.day}`,
    };

    this.api.create('cycles', obj).subscribe({
      next: (cycles) => {
        this.submitted = false;
        this.isDisplayed = false;
        this.isSaved = true;
        this.messageResponse = cycles.message;
        this._error.next(this.messageResponse);

        this.resetCycleForm();
      },
      error: (err) => {},
    });
  }

  resetCycleForm() {
    return this.cyclesForm.reset();
  }
}
