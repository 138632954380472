<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Reporte de {{ paramStart }} al {{ paramEnd }}</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-5">
    <a [routerLink]="['/weekly-sells/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de reportes
    </a>
  </div>

  <div class="alert alert-danger" role="alert" *ngIf="dataReport == 0">
    No hay datos para reportes
  </div>

  <div *ngIf="dataReport.length != 0">
    <div *ngFor="let userReport of dataReport; let index = index">
      <div class="card mb-2" *ngIf="userReport.report">
        <div class="card-body">
          <h5 class="card-title">
            {{ userReport.name }}
          </h5>
          <div class="card-text">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="col">Venta</th>
                  <th class="col">Cobranza</th>
                  <th class="col">Dia</th>
                  <th class="col">GIO / Reventa</th>
                </tr>
              </thead>
              <tbody *ngFor="let report of userReport.report; let idx = index">
                <tr>
                  <td class="col">{{ report.venta | currency }}</td>
                  <td class="col">{{ report.cobranza | currency }}</td>
                  <td class="col">{{ report.dia }}</td>
                  <td class="col">{{ report.reventa }}</td>
                </tr>
                <tr *ngIf="idx == userReport.totalRows">
                  <td colspan="2" class="fw-bold">
                    Total de ventas: {{ userReport.ventaTotal | currency }}
                  </td>
                  <td colspan="2" class="fw-bold">
                    Total de cobranza: {{ userReport.cobranzaTotal | currency }}
                  </td>
                </tr>
              </tbody>
            </table>

            <table
              class="table table-striped mt-2 mb-2"
              *ngFor="let bonus of userReport.bonus"
            >
              <thead>
                <tr>
                  <th colspan="3" class="text-center">{{ bonus.typeName }}</th>
                </tr>
                <tr>
                  <th>Faltante</th>
                  <th>Bono</th>
                  <th>Ganado</th>
                </tr>
              </thead>
              <tbody class="table-group-divider">
                <td>{{ bonus.restante }}</td>
                <td>{{ bonus.amount | currency }}</td>
                <td>
                  <p class="{{ bonus.color }}">
                    {{ bonus.cumplido }}
                  </p>
                </td>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-2">
      <div class="card-body">
        <div class="card-text">
          <table class="table table-striped">
            <thead>
              <tr>
                <th colspan="2">Resultado Semana</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Venta Sucursal</td>
                <td>{{ amountsTotal.totalVenta | currency }}</td>
              </tr>
              <tr>
                <td>Cobranza Total</td>
                <td>{{ amountsTotal.totalCobranza | currency }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
