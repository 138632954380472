<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Informacion de la promoci&oacute;n</h3>
  </div>
  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-5">
    <a [routerLink]="['/promotions/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de promociones
    </a>
  </div>
  <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
    <div class="card mx-auto" style="width: 70%">
      <div class="card-header">Information de la promoci&oacute;n</div>
      <div class="card-body">
        <h5>
          <strong>{{ promotions.name }}</strong>
        </h5>
        <p class="card-text">
          <span> <strong>Mensaje extra:</strong>{{ promotions.text }} </span>
          <br />
          <span>
            <strong>Promoci&oacute;n:</strong>{{ promotions.type_text }}
          </span>
          <br />
          <span *ngIf="promotions.type == 1">
            <strong>Email enviado:</strong>
            <span *ngIf="promotions.send_promotion[0].is_send">Si</span>
            <span *ngIf="!promotions.send_promotion[0].is_send">Si</span>
          </span>
          <span *ngIf="promotions.type == 2">
            <span>
              <strong>Sucursal:</strong>
              {{ promotions.send_promotion[0].office.name }}
            </span>
            <br />
            <span>
              <strong>Direcci&oacute;n:</strong>
              {{ promotions.send_promotion[0].office.address }}
            </span>
            <br />
            <span>
              <strong>Ciudad:</strong>
              {{ promotions.send_promotion[0].office.city.name }}
            </span>
            <br />
            <strong>Email enviado:</strong>
            <span *ngIf="promotions.send_promotion[0].is_send">Si</span>
            <span *ngIf="!promotions.send_promotion[0].is_send">No</span>
          </span>
        </p>
        <table class="table" *ngIf="promotions.type == 3">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Email</th>
              <th>Telefono</th>
              <th>Correo enviado</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let singleRow of promotions.send_promotion">
              <td>{{ singleRow.client.name }}</td>
              <td>{{ singleRow.client.email }}</td>
              <td>{{ singleRow.client.phone }}</td>
              <td *ngIf="singleRow.is_send">Si</td>
              <td *ngIf="!singleRow.is_send">No</td>
            </tr>
          </tbody>
        </table>
        <img src="{{ promotions.image }}" class="card-img-top" />
      </div>
      <!--img src="{{ promotions.image }}" class="card-img-top" />
      <div class="card-body">
        <h5 class="card-title">{{ promotions.name }}</h5>
        <p class="card-text">
          <span><strong>Tipo:</strong>{{ promotions.type_text }}</span>
        </p>
      </div-->
    </div>
  </div>
</div>
