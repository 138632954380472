import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-show-weekly-sells',
  templateUrl: './show-weekly-sells.component.html',
  styleUrls: ['./show-weekly-sells.component.scss'],
})
export class ShowWeeklySellsComponent implements OnInit {
  breadcrumbs: any = [];
  paramId: string | null = '';
  paramStart: string | null = '';
  paramEnd: string | null = '';
  paramType: string | null = '';
  dataReport: any = [];
  amountsTotal: any = [];

  constructor(private api: ApiService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.paramId = this.route.snapshot.paramMap.get('id');
    this.paramStart = this.route.snapshot.paramMap.get('start');
    this.paramEnd = this.route.snapshot.paramMap.get('end');
    this.paramType = this.route.snapshot.paramMap.get('type');

    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de Reportes',
        path: '/weekly-sells/list',
        link: true,
      },
      {
        name: 'Reporte',
        link: false,
      },
    ];

    this.loadData();
  }

  loadData() {
    const params = `${this.paramStart}/${this.paramEnd}/${this.paramId}/${this.paramType}`;
    this.api.getById('report', params).subscribe({
      next: (reports) => {
        this.dataReport = reports.reports;
        this.amountsTotal = reports.totalData;
      },
      error: (err) => {},
    });
  }
}
