<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Actualizar insumo</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-5">
    <a [routerLink]="['/products/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de insumos en venta
    </a>
  </div>
  <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
    <ngb-alert
      #selfClosingAlert
      type="danger"
      *ngIf="generalMessage != '' && isDisplayed"
    >
      {{ generalMessage }}
    </ngb-alert>
    <ngb-alert
      #selfClosingAlert
      type="success"
      *ngIf="generalMessage != '' && isSaved"
    >
      {{ generalMessage }}
    </ngb-alert>

    <form [formGroup]="editProductForm" (ngSubmit)="updateProduct()" novalidate>
      <div class="accordion" id="packages_sessions">
        <div class="accordion-item">
          <h2 class="accordion-header" id="package-information">
            <button
              class="accordion-button color-header-collapse"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              disabled
            >
              <span class="color-text"> Actualizar Insumo </span>
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <input
                      class="form-control"
                      type="text"
                      name="name"
                      id="name"
                      formControlName="name"
                      placeholder="Nombre del insumo"
                      required
                      [ngClass]="{
                        'is-invalid': submitted && f.name.errors,
                        'is-valid': submitted && !f.name.errors
                      }"
                    />
                    <label for="name" class="form-label">
                      Nombre del insumo
                    </label>
                  </div>
                </div>
                <div class="col-md-6 form-floating mb-3">
                  <input
                    type="text"
                    name="price"
                    id="price"
                    formControlName="price"
                    class="form-control"
                    placeholder="Precio de insumo"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.price.errors,
                      'is-valid': submitted && !f.price.errors
                    }"
                  />
                  <label for="price" class="ms-2"> Precio de insumo </label>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 form-floating mb-3">
                  <input
                    type="number"
                    name="pieces"
                    id="pieces"
                    formControlName="pieces"
                    class="form-control"
                    placeholder="Numero de piezas del insumo"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.pieces.errors,
                      'is-valid': submitted && !f.pieces.errors
                    }"
                  />
                  <label for="pieces" class="ms-2">
                    Numero de piezas del insumo
                  </label>
                </div>
                <div class="col-md-6 form-floating mb-3">
                  <input
                    type="number"
                    name="sells"
                    id="sells"
                    formControlName="sells"
                    class="form-control"
                    placeholder="Numero de piezas vendidas"
                    required
                    readonly
                    [ngClass]="{
                      'is-invalid': submitted && f.sells.errors,
                      'is-valid': submitted && !f.sells.errors
                    }"
                  />
                  <label for="sells" class="ms-2">
                    Numero de piezas vendidas
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 mt-2 d-grip gap-2 d-md-flex justify-content-md-end">
        <button type="submit" class="btn btn-success">
          <i class="bi bi-plus-circle"></i>
          Actualizar
        </button>
      </div>
    </form>
  </div>
</div>
