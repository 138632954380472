import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-payment-methods',
  templateUrl: './create-payment-methods.component.html',
  styleUrls: ['./create-payment-methods.component.scss'],
})
export class CreatePaymentMethodsComponent implements OnInit {
  paymentMethods: any = FormGroup;
  submitted: boolean = false;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  generalMessage: string = '';
  breadcrumbs: any;
  flagErrors: boolean = false;

  constructor(private api: ApiService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de metodos de pago',
        path: '/payments-methods/list',
        link: true,
      },
      {
        name: 'Crear metodo de pago',
        link: false,
      },
    ];

    this.validators();
  }

  validators() {
    this.paymentMethods = this.fb.group({
      payment_method: ['', [Validators.required]],
      is_active: [''],
    });
  }

  get f() {
    return this.paymentMethods.controls;
  }

  createPaymentMethod() {
    this.submitted = true;
    this.flagErrors = false;

    if (this.paymentMethods.invalid) {
      this.flagErrors = true;
      return;
    }

    const obj = {
      payment_method_name: this.paymentMethods.value.payment_method,
      is_active: true,
    };

    this.api.create('payment_methods', obj).subscribe({
      next: (paymentMethod) => {
        this.submitted = false;
        this.isSaved = true;
        this.generalMessage = paymentMethod.message;
        this.resetPaymentMethods();
      },
      error: (err) => {},
    });
  }

  resetPaymentMethods() {
    this.flagErrors = false;
    this.paymentMethods.reset();
    this.paymentMethods.get('payment_method').setValue('');

    setTimeout(() => {
      this.submitted = false;
      this.isDisplayed = false;
      this.isSaved = false;
      this.generalMessage = '';
    }, 4000);
  }
}
