<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Crear cliente</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-5">
    <a [routerLink]="['/clients/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de clientes
    </a>
  </div>

  <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
    <ngb-alert
      #selfClosingAlert
      type="danger"
      *ngIf="generalMessage != '' && isDisplayed"
    >
      {{ generalMessage }}
    </ngb-alert>
    <ngb-alert
      #selfClosingAlert
      type="success"
      *ngIf="generalMessage != '' && isSaved"
    >
      {{ generalMessage }}
    </ngb-alert>

    <form [formGroup]="clientsForm" (ngSubmit)="createClients()" novalidate>
      <div class="accordion" id="cities_accordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="cities-information">
            <button
              class="accordion-button color-header-collapse"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              disabled
            >
              <span class="color-text"> Creaci&oacute;n de Clientes </span>
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-6 mb-3 form-floating">
                  <input
                    type="text"
                    name="first_name"
                    id="first_name"
                    formControlName="first_name"
                    class="form-control"
                    placeholder="Nombre"
                    required
                    [ngClass]="{
                      'is-invalid':
                        submitted && f.first_name.errors && flagErrors,
                      'is-valid':
                        submitted && !f.first_name.errors && !flagErrors
                    }"
                  />
                  <label for="first_name" class="ms-2">Nombre</label>
                  <div>
                    <div
                      *ngIf="
                        flagErrors &&
                        f.first_name?.invalid &&
                        (f.first_name?.errors || f.first_name?.touched)
                      "
                    >
                      <div
                        class="ms-2 text-danger"
                        *ngIf="f.first_name?.errors.required"
                      >
                        El nombre es requerido.
                      </div>
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.first_name?.errors.minlength"
                      >
                        El nombre debe ser minimo de 3 caracteres.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-3 form-floating">
                  <input
                    type="text"
                    name="last_name"
                    id="last_name"
                    formControlName="last_name"
                    class="form-control"
                    placeholder="Apellidos"
                    required
                    [ngClass]="{
                      'is-invalid':
                        submitted && f.last_name.errors && flagErrors,
                      'is-valid':
                        submitted && !f.last_name.errors && !flagErrors
                    }"
                  />
                  <label for="last_name" class="ms-2">Apellidos</label>
                  <div>
                    <div
                      *ngIf="
                        flagErrors &&
                        f.last_name?.invalid &&
                        (f.last_name?.errors || f.last_name?.touched)
                      "
                    >
                      <div
                        class="ms-2 text-danger"
                        *ngIf="f.last_name?.errors.required"
                      >
                        El apellido es requerido.
                      </div>
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.last_name?.errors.minlength"
                      >
                        El apellido debe ser minimo de 3 caracteres.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mb-3 form-floating">
                  <input
                    type="number"
                    name="reference"
                    id="reference"
                    formControlName="reference"
                    class="form-control"
                    placeholder="Numero de expediente"
                    required
                    [ngClass]="{
                      'is-invalid':
                        submitted && f.reference.errors && flagErrors,
                      'is-valid':
                        submitted && !f.reference.errors && !flagErrors
                    }"
                  />
                  <label for="reference" class="ms-2">
                    Numero de expediente
                  </label>
                  <div>
                    <div
                      *ngIf="
                        flagErrors &&
                        f.reference?.invalid &&
                        (f.reference?.errors || f.reference?.touched)
                      "
                    >
                      <div
                        style="margin-top: 5px !important"
                        class="ms-2 text-danger"
                        *ngIf="f.reference?.errors.required"
                      >
                        El numero de expediente es requerido.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-3 form-floating">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    formControlName="email"
                    class="form-control"
                    placeholder="Email"
                  />
                  <label for="last_name" class="ms-2">Email</label>
                  <!--div>
                    [ngClass]="{
                      'is-invalid': submitted && f.email.errors && flagErrors,
                      'is-valid': submitted && !f.email.errors && !flagErrors
                    }"
                    <div
                      *ngIf="
                        flagErrors &&
                        f.email?.invalid &&
                        (f.email?.touched || f.email?.errors)
                      "
                    >
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.email?.errors.required"
                      >
                        El correo electr&oacute;nico es requerido.
                      </div>
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.email?.errors.email"
                      >
                        El correo electr&oacute;nico debe ser una correo valido.
                      </div>
                    </div>
                  </div-->
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mb-3 form-floating">
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    formControlName="phone"
                    class="form-control"
                    placeholder="Telefono"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.phone.errors && flagErrors,
                      'is-valid': submitted && !f.phone.errors && !flagErrors
                    }"
                  />
                  <label for="phone" class="ms-2">Telefono</label>
                  <div>
                    <div
                      *ngIf="
                        flagErrors &&
                        f.phone?.invalid &&
                        (f.phone?.touched || f.phone?.errors)
                      "
                    >
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.phone?.errors.required"
                      >
                        El tel&eacute;fono es requerido.
                      </div>
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.phone?.errors.minlength"
                      >
                        El tel&eacute;fono debe ser minimo de 10 caracteres.
                      </div>
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.phone?.errors.maxlength"
                      >
                        El tel&eacute;fono debe ser maximo de 15 caracteres.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-3 form-floating">
                  <div class="input-group">
                    <input
                      type="text"
                      name="date_of_birthday"
                      class="form-control"
                      id="date_of_birthday"
                      formControlName="date_of_birthday"
                      placeholder="Fecha de nacimiento"
                      ngbDatepicker
                      [minDate]="{ year: 1950, month: 1, day: 1 }"
                      #d="ngbDatepicker"
                      readonly
                      style="height: 3.5rem"
                    />
                    <button
                      class="btn btn-outline-secondary bi bi-calendar3"
                      (click)="d.toggle()"
                      type="button"
                    ></button>
                  </div>
                  <!--div>
                    [ngClass]="{
                        'is-invalid':
                          submitted && f.date_of_birthday.errors && flagErrors,
                        'is-valid':
                          submitted && !f.date_of_birthday.errors && !flagErrors
                      }"
                    <div
                      *ngIf="
                        flagErrors &&
                        f.date_of_birthday?.invalid &&
                        (f.date_of_birthday?.touched ||
                          f.date_of_birthday?.errors)
                      "
                    >
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.date_of_birthday?.errors.required"
                      >
                        La fecha de nacimiento es requerida.
                      </div>
                    </div>
                  </div-->
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mb-3 form-floating">
                  <input
                    type="text"
                    name="cp"
                    id="cp"
                    formControlName="cp"
                    class="form-control"
                    placeholder="Codigo postal"
                  />
                  <label for="cp" class="ms-2">Codigo postal</label>
                  <!--div>
                    [ngClass]="{
                      'is-invalid': submitted && f.cp.errors && flagErrors,
                      'is-valid': submitted && !f.cp.errors && !flagErrors
                    }"
                    <div
                      *ngIf="
                        flagErrors &&
                        f.cp?.invalid &&
                        (f.cp?.touched || f.cp?.errors)
                      "
                    >
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.cp?.errors.required"
                      >
                        El codigo postal es requerido.
                      </div>
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.cp?.errors.minlength"
                      >
                        El codigo postal debe ser minimo de 10 caracteres.
                      </div>
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.cp?.errors.maxlength"
                      >
                        El codigo postal debe ser maximo de 15 caracteres.
                      </div>
                    </div>
                  </div-->
                </div>
                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <select
                      class="form-select"
                      aria-label="Floating label select"
                      id="office_id"
                      name="office_id"
                      formControlName="office_id"
                      [ngClass]="{
                        'is-invalid':
                          submitted && f.office_id.errors && flagErrors,
                        'is-valid':
                          submitted && f.office_id.errors && !flagErrors
                      }"
                    >
                      <option selected value="">Selecciona una sucursal</option>
                      <option
                        *ngFor="let office of offices"
                        value="{{ office.id }}"
                      >
                        {{ office.name }}
                      </option>
                    </select>
                    <label for="office_id" class="">
                      Selecciona una sucursal
                    </label>
                  </div>
                  <div>
                    <div
                      *ngIf="
                        flagErrors &&
                        f.office_id?.invalid &&
                        (f.office_id?.touched || f.office_id?.errors)
                      "
                    >
                      <div
                        style="margin-top: -12px !important"
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.office_id?.errors.required"
                      >
                        La selecci&oacute;n de la sucursal es requerida.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3 mt-2 d-grip gap-2 d-md-flex justify-content-md-end">
        <button type="button" class="btn btn-danger" (click)="resetClients()">
          <i class="bi bi-eraser"></i>
          Limpiar Informaci&oacute;n
        </button>
        <button type="submit" class="btn btn-success">
          <i class="bi bi-plus-circle"></i>
          Crear
        </button>
      </div>
    </form>
  </div>
</div>
