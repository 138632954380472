<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Actualizar contrase&ntilde;a</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-5">
    <a [routerLink]="['/employees/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de colaboradores
    </a>
  </div>

  <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
    <ngb-alert
      #selfClosingAlert
      type="danger"
      *ngIf="generalMessage != '' && isDisplayed"
    >
      {{ generalMessage }}
    </ngb-alert>
    <ngb-alert
      #selfClosingAlert
      type="success"
      *ngIf="generalMessage != '' && isSaved"
    >
      {{ generalMessage }}
    </ngb-alert>

    <form [formGroup]="passwordUpdate" (ngSubmit)="updatePassword()" novalidate>
      <div class="accordion" id="cities_accordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="cities-information">
            <button
              class="accordion-button color-header-collapse"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              disabled
            >
              <span class="color-text"> Actualizar Contrase&ntilde;a </span>
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div class="row">
                <div class="mb-3 form-floating col-md-6">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    formControlName="password"
                    class="form-control"
                    placeholder="Nueva contrase&ntilde;a"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.password.errors,
                      'is-valid': submitted && !f.password.errors
                    }"
                  />
                  <label for="password" class="ms-2"
                    >Nueva contrase&ntilde;a</label
                  >
                </div>
                <div class="mb-3 form-floating col-md-6">
                  <input
                    type="password"
                    name="cpassword"
                    id="cpassword"
                    formControlName="cpassword"
                    class="form-control"
                    placeholder="Confirmar nueva contrase&ntilde;a"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.cpassword.errors,
                      'is-valid': submitted && !f.cpassword.errors
                    }"
                  />
                  <label for="cpassword" class="ms-2"
                    >Confirmar nueva contrase&ntilde;a</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3 mt-2 d-grip gap-2 d-md-flex justify-content-md-end">
        <button type="submit" class="btn btn-success">
          <i class="bi bi-plus-circle"></i>
          Actualizar contraseña
        </button>
      </div>
    </form>
  </div>
</div>
