<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Crear colaborador</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-5">
    <a [routerLink]="['/employees/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de colaboradores
    </a>
  </div>

  <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
    <div *ngIf="isDisplayed" class="alert alert-danger" role="alert">
      {{ generalMessage }}
    </div>
    <div *ngIf="isSaved" class="alert alert-success" role="alert">
      {{ generalMessage }}
    </div>

    <form
      [formGroup]="employeesForm"
      (ngSubmit)="createNewEmployee()"
      novalidate
    >
      <div class="accordion" id="cities_accordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="cities-information">
            <button
              class="accordion-button color-header-collapse"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              disabled
            >
              <span class="color-text"> Creaci&oacute;n de colaboradores </span>
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-6 mb-3 form-floating">
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    formControlName="firstName"
                    class="form-control"
                    placeholder="Nombre"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.firstName.errors,
                      'is-valid': submitted && !f.firstName.errors
                    }"
                  />
                  <label for="firstName" class="ms-2">Nombre</label>
                  <div>
                    <div
                      *ngIf="
                        flagErrors &&
                        f.firstName?.invalid &&
                        (f.firstName?.touched || f.firstName?.errors)
                      "
                    >
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.firstName?.errors.required"
                      >
                        El nombre es requerido.
                      </div>
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.firstName?.errors.minlength"
                      >
                        El nombre debe ser al menos de 3 caracteres.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-3 form-floating">
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    formControlName="lastName"
                    class="form-control"
                    placeholder="Apellidos"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.lastName.errors,
                      'is-valid': submitted && !f.lastName.errors
                    }"
                  />
                  <label for="lastName" class="ms-2">Apellidos</label>
                  <div>
                    <div
                      *ngIf="
                        flagErrors &&
                        f.lastName?.invalid &&
                        (f.lastName?.touched || f.lastName?.errors)
                      "
                    >
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.lastName?.errors.required"
                      >
                        El apellido es requerido.
                      </div>
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.lastName?.errors.minlength"
                      >
                        El apellido debe ser al menos de 3 caracteres.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mb-3 form-floating">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    formControlName="email"
                    class="form-control"
                    placeholder="Email"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.email.errors,
                      'is-valid': submitted && !f.email.errors
                    }"
                  />
                  <label for="last_name" class="ms-2">
                    Correo electr&oacute;nico
                  </label>
                  <div>
                    <div
                      *ngIf="
                        flagErrors &&
                        f.email?.invalid &&
                        (f.email?.touched || f.email?.errors)
                      "
                    >
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.email?.errors.required"
                      >
                        El correo electr&oacute;nico es requerido.
                      </div>
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.email?.errors.email"
                      >
                        El correo electr&oacute;nico debe ser una correo valido.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-3 form-floating col-md-6">
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    formControlName="phone"
                    class="form-control"
                    placeholder="Tel&eacute;fono"
                    required
                    (keypress)="numbersOnly($event)"
                    [ngClass]="{
                      'is-invalid': submitted && f.phone.errors,
                      'is-valid': submitted && !f.phone.errors
                    }"
                  />
                  <label for="phone" class="ms-2">Tel&eacute;fono</label>
                  <div>
                    <div
                      *ngIf="
                        flagErrors &&
                        f.phone?.invalid &&
                        (f.phone?.touched || f.phone?.errors)
                      "
                    >
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.phone?.errors.required"
                      >
                        El tel&eacute;fono es requerido.
                      </div>
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.phone?.errors.minlength"
                      >
                        El tel&eacute;fono debe ser minimo de 10 caracteres.
                      </div>
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.phone?.errors.maxlength"
                      >
                        El tel&eacute;fono debe ser maximo de 15 caracteres.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mb-3 form-floating">
                  <input
                    type="text"
                    name="address"
                    id="address"
                    formControlName="address"
                    class="form-control"
                    placeholder="Direcci&oacute;n"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.address.errors,
                      'is-valid': submitted && !f.address.errors
                    }"
                  />
                  <label for="address" class="ms-2">Direcci&oacute;n</label>
                  <div>
                    <div
                      *ngIf="
                        flagErrors &&
                        f.address?.invalid &&
                        (f.address?.touched || f.address?.errors)
                      "
                    >
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.address?.errors.required"
                      >
                        La direcci&oacute;n es requerida.
                      </div>
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.address?.errors.minlength"
                      >
                        La direcci&oacute;n debe ser minimo de 5 caracteres.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-3 form-floating col-md-6">
                  <input
                    type="text"
                    name="salaryBase"
                    id="salaryBase"
                    formControlName="salaryBase"
                    class="form-control"
                    placeholder="Salario base"
                    (blur)="format_number($event)"
                    (keypress)="numbersOnly($event)"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.salaryBase.errors,
                      'is-valid': submitted && !f.salaryBase.errors
                    }"
                  />
                  <label for="salaryBase" class="ms-2">Salario base</label>
                  <div>
                    <div
                      *ngIf="
                        flagErrors &&
                        f.salaryBase?.invalid &&
                        (f.salaryBase?.touched || f.salaryBase?.errors)
                      "
                    >
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.salaryBase?.errors.required"
                      >
                        El salario base es requerido.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="mb-3 form-floating col-md-6">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    formControlName="password"
                    class="form-control"
                    placeholder="Contrase&ntilde;a"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.password.errors,
                      'is-valid': submitted && !f.password.errors
                    }"
                  />
                  <label for="password" class="ms-2">Contrase&ntilde;a</label>
                  <div>
                    <div
                      *ngIf="
                        flagErrors &&
                        f.password?.invalid &&
                        (f.password?.touched || f.password?.errors)
                      "
                    >
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.password?.errors.required"
                      >
                        La contrase&ntilde;a es requerida.
                      </div>
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.password?.errors.minlength"
                      >
                        La contrase&ntilde;a debe ser minimo de 5 caracteres.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-3 form-floating col-md-6">
                  <input
                    type="password"
                    name="cpassword"
                    id="cpassword"
                    formControlName="cpassword"
                    class="form-control"
                    placeholder="Confirmar contrase&ntilde;a"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.cpassword.errors,
                      'is-valid': submitted && !f.cpassword.errors
                    }"
                  />
                  <label for="cpassword" class="ms-2">
                    Confirmar contrase&ntilde;a
                  </label>
                  <div>
                    <div
                      *ngIf="
                        flagErrors &&
                        f.cpassword?.invalid &&
                        (f.cpassword?.touched || f.cpassword?.errors)
                      "
                    >
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.cpassword?.errors.required"
                      >
                        La confirmaci&oacute;n de la contrase&ntilde;a es
                        requerida.
                      </div>
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.cpassword?.errors.minlength"
                      >
                        La confirmaci&oacute;n de la contrase&ntilde;a debe ser
                        minimo de 5 caracteres.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-floating col-md-3">
                  <label for=""> Hora de unicio de comida: </label>
                </div>
                <div class="col-md-3 mt-2">
                  <ngb-timepicker
                    [(ngModel)]="timeStart"
                    [ngModelOptions]="{ standalone: true }"
                  ></ngb-timepicker>
                  <div
                    class="mt-1 text-danger"
                    *ngIf="flagTimeErr1"
                    style="margin-left: -13.5rem !important"
                  >
                    Elige una hora de inicio.
                  </div>
                </div>

                <div class="form-floating col-md-3">
                  <label for=""> Hora de fin de comida: </label>
                </div>
                <div class="col-md-3 mt-2">
                  <ngb-timepicker
                    [(ngModel)]="timeEnd"
                    [ngModelOptions]="{ standalone: true }"
                  ></ngb-timepicker>
                  <div
                    class="mt-1 text-danger"
                    *ngIf="flagTimeErr2"
                    style="margin-left: -13.5rem !important"
                  >
                    Elige una hora de fin.
                  </div>
                </div>
              </div>

              <div class="row mt-4">
                <div class="mb-3 form-floating col-md-10">
                  <select
                    name="restDay"
                    class="form-select"
                    id="restDay"
                    aria-label="Floating label select"
                    formControlName="restDay"
                    [ngClass]="{
                      'is-invalid': submitted && flagErrors && f.restDay.errors,
                      'is-valid': submitted && !flagErrors && !f.restDay.errors
                    }"
                  >
                    <option value="" selected>
                      Seleccione el dia de descanso
                    </option>
                    <option value="-1">No Definido</option>
                    <option value="1">Lunes</option>
                    <option value="2">Martes</option>
                    <option value="3">Miercoles</option>
                    <option value="4">Jueves</option>
                    <option value="5">Viernes</option>
                    <option value="6">Sabado</option>
                    <option value="7">Domingo</option>
                  </select>
                  <label for="restDay" class="ms-2">
                    Seleccione el dia de descanso
                  </label>
                  <div>
                    <div
                      *ngIf="
                        flagErrors &&
                        f.restDay?.invalid &&
                        (f.restDay?.touched || f.restDay?.errors)
                      "
                    >
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.restDay?.errors.required"
                      >
                        La selecci&oacute;n del d&iacute;a de descanso es
                        requerida.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 mt-1">
                  <button
                    class="btn btn-primary btn-lg"
                    type="button"
                    (click)="addExtraRestDay()"
                    ngbTooltip="Agregar dia de descanso"
                    placement="top"
                  >
                    <i class="bi bi-plus-lg"></i>
                  </button>
                </div>
              </div>
              <div formArrayName="extraRestDay">
                <div
                  class="row mb-3"
                  *ngFor="
                    let field of totalNewRestDay().controls;
                    let i = index
                  "
                  [formGroupName]="i"
                >
                  <div class="col-md-10 form-floating">
                    <select
                      id="newRestDay"
                      name="newRestDay"
                      formControlName="newRestDay"
                      class="form-select"
                      aria-label="Floating label select"
                    >
                      <option value="" selected>
                        Seleccione el dia de descanso
                      </option>
                      <option value="-1">No Definido</option>
                      <option value="1">Lunes</option>
                      <option value="2">Martes</option>
                      <option value="3">Miercoles</option>
                      <option value="4">Jueves</option>
                      <option value="5">Viernes</option>
                      <option value="6">Sabado</option>
                      <option value="7">Domingo</option>
                    </select>
                    <label for="newRestDay" class="ms-2">
                      Seleccione el dia de descanso
                    </label>
                  </div>
                  <div class="col-md-2 mt-1">
                    <button
                      type="button"
                      class="btn btn-danger btn-lg"
                      (click)="removeExtraRestDay(i)"
                      ngbTooltip="Elimiar dia de descanso"
                      placement="top"
                    >
                      <i class="bi bi-x-lg"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="mb-3 form-floating col-md-4">
                  <select
                    class="form-select"
                    aria-label="Floating label select"
                    id="roleId"
                    name="roleId"
                    formControlName="roleId"
                    [ngClass]="{
                      'is-invalid': submitted && flagErrors && f.roleId.errors,
                      'is-valid': submitted && !flagErrors && !f.roleId.errors
                    }"
                  >
                    <option selected value="">Selecciona un rol</option>
                    <option *ngFor="let role of roles" value="{{ role.id }}">
                      {{ role.role }}
                    </option>
                  </select>
                  <label for="select_state" class="ms-2">
                    Selecciona un rol
                  </label>
                  <div>
                    <div
                      *ngIf="
                        flagErrors &&
                        f.roleId?.invalid &&
                        (f.roleId?.touched || f.roleId?.errors)
                      "
                    >
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.roleId?.errors.required"
                      >
                        La selecci&oacute;n del rol es requerida.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-3 form-floating col-md-4">
                  <select
                    class="form-select"
                    aria-label="Floating label select"
                    id="cityId"
                    name="cityId"
                    formControlName="cityId"
                    (change)="selectState($event)"
                    [ngClass]="{
                      'is-invalid': submitted && flagErrors && f.cityId.errors,
                      'is-valid': submitted && !flagErrors && !f.cityId.errors
                    }"
                  >
                    <option selected value="">Selecciona una ciudad</option>
                    <option *ngFor="let city of cities" value="{{ city.id }}">
                      {{ city.city_name }}
                    </option>
                  </select>
                  <label for="select_state" class="ms-2">
                    Selecciona una ciudad
                  </label>
                  <div>
                    <div
                      *ngIf="
                        flagErrors &&
                        f.cityId?.invalid &&
                        (f.cityId?.touched || f.cityId?.errors)
                      "
                    >
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.cityId?.errors.required"
                      >
                        La selecci&oacute;n de la ciudad es requerida.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-3 form-floating col-md-4">
                  <select
                    class="form-select"
                    aria-label="Floating label select"
                    id="officeId"
                    name="officeId"
                    formControlName="officeId"
                    [ngClass]="{
                      'is-invalid':
                        submitted && flagErrors && f.officeId.errors,
                      'is-valid': submitted && !flagErrors && !f.officeId.errors
                    }"
                  >
                    <option selected value="">Selecciona una sucursal</option>
                    <option
                      *ngFor="let office of filterOffices"
                      value="{{ office.id }}"
                    >
                      {{ office.name }}
                    </option>
                  </select>
                  <label for="select_state" class="ms-2">
                    Selecciona una sucursal
                  </label>
                  <div>
                    <div
                      *ngIf="
                        flagErrors &&
                        f.officeId?.invalid &&
                        (f.officeId?.touched || f.officeId?.errors)
                      "
                    >
                      <div
                        class="ms-2 mt-1 text-danger"
                        *ngIf="f.officeId?.errors.required"
                      >
                        La selecci&oacute;n de la sucursal es requerida.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mb-3 mt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="has_secondary"
                    id="has_secondary"
                    (change)="activateSecondary($event)"
                  />
                  <label class="form-check-label ms-2" for="has_secondary">
                    Tiene sucursales secundarias
                  </label>
                </div>
              </div>

              <div class="row" *ngIf="hasSecondary">
                <input
                  type="hidden"
                  name="secondary_offices"
                  formControlName="secondary_offices"
                  id="secondary_offices"
                />
                <select2
                  [data]="officesData"
                  style="width: 100%"
                  displaySearchStatus="always"
                  placeholder="Sucursal secundaria"
                  formControlName="temp_offices"
                  multiple
                  listPosition="below"
                  styleMode="material"
                  noResultMessage="Sucursal no encontrada"
                  hideSelectedItems
                  (update)="updateOffices($event)"
                ></select2>
              </div>

              <div class="row">
                <div class="col-md-3 offset-md-2">
                  <label for="startSession">Hora de entrada</label>
                  <ngb-timepicker
                    [(ngModel)]="startEmployee"
                    [ngModelOptions]="{ standalone: true }"
                    [spinners]="true"
                  ></ngb-timepicker>
                </div>
                <div class="col-md-3 offset-md-2">
                  <label for="endSession">Hora de salida</label>
                  <ngb-timepicker
                    [(ngModel)]="endEmployee"
                    [ngModelOptions]="{ standalone: true }"
                    [spinners]="true"
                  ></ngb-timepicker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3 mt-2 d-grip gap-2 d-md-flex justify-content-md-end">
        <button type="button" class="btn btn-danger" (click)="resetEmployees()">
          <i class="bi bi-eraser"></i>
          Limpiar Informaci&oacute;n
        </button>
        <button type="submit" class="btn btn-success">
          <i class="bi bi-plus-circle"></i>
          Crear
        </button>
      </div>
    </form>
  </div>
</div>
