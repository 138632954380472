<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Laser Clinic</title>
  </head>
  <body>
    <header class="container-fluid">
      <div class="row">
        <div class="col ms-5">
          <img src="assets/logo.jpeg" alt="Logo" style="width: 15em" />
        </div>
      </div>
    </header>
    <router-outlet></router-outlet>
  </body>
</html>
