<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Lista de prospectos</h3>
  </div>
  <hr />
  <div class="row align-items-center mb-3">
    <div class="col-md-6">
      <div class="input-group">
        <input
          type="text"
          id="table-complete-search"
          name="searchText"
          class="form-control"
          placeholder="Buscar prospecto"
          [(ngModel)]="filterText"
          (keyup)="filterRecords()"
        />

        <select
          class="form-select ms-1"
          name="currentOfficeId"
          [(ngModel)]="currentOfficeId"
          id="currentOfficeId"
          (change)="setCurrentOffice($event)"
          *ngIf="rolekey == 'admin'"
        >
          <option value="0" selected="selected">Selecciona sucursal</option>
          <option
            *ngFor="let office of offices"
            value="{{ office.id }}"
            selected="{ office.id == roleofficeid ? 'selected' : '' }"
          >
            {{ office.name }}
          </option>
        </select>

        <button
          class="btn btn-outline-danger ms-1"
          type="button"
          (click)="clearSearch()"
        >
          Limpiar busqueda
        </button>
      </div>
    </div>
    <div class="col-md-6">
      <div class="d-grip gap-1 d-md-flex justify-content-md-end">
        <a [routerLink]="['/prospects/create']" class="btn btn-outline-success">
          <i class="bi bi-plus-circle"></i>
          Dar de alta prospecto
        </a>
      </div>
    </div>
  </div>
  <hr />

  <div class="alert alert-success" role="alert" *ngIf="flagDeleted">
    {{ message }}
  </div>

  <form>
    <table class="table table-striped">
      <thead>
        <tr>
          <th class="col">ID</th>
          <th class="col">Nombre del prospecto</th>
          <th class="col">Telefono</th>
          <th class="col">Atendio</th>
          <th class="col">Medio de contacto</th>
          <th class="col">Sucursal</th>
          <th class="col">Comentarios</th>
          <th class="col">Areas de interes</th>
          <th class="col">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let prospect of filterProspects
              | paginate : { itemsPerPage: 15, currentPage: page }
          "
        >
          <td scope="row">
            {{ prospect.id }}
          </td>
          <td>
            {{ prospect.nombre }}
          </td>
          <td>
            {{ prospect.telefono }}
          </td>
          <td>
            {{ prospect.atendio }}
          </td>
          <td>
            {{ prospect.captacion }}
          </td>
          <td>
            {{ prospect.officeName }}
          </td>
          <td>{{ prospect.comments }}</td>
          <td>{{ prospect.areas }}</td>
          <td>
            <div class="d-flex d-flex-row">
              <!--div>
                <a
                  *ngIf="rolekey == 'admin'"
                  [routerLink]="['/prospects/edit', prospect.id]"
                  placement="top"
                  ngbTooltip="Editar"
                  role="button"
                  class="btn btn-primary"
                >
                  <i class="bi bi-pencil-square"></i>
                </a>
              </div-->
              <div class="ms-2">
                <a
                  *ngIf="rolekey == 'admin'"
                  (click)="openDeleteMessage(prospect.id, prospect.nombre)"
                  placement="top"
                  ngbTooltip="Eliminar"
                  role="button"
                  class="btn btn-primary"
                >
                  <i class="bi bi-trash3-fill"></i>
                </a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="col-md-12 d-flex align-items-center justify-content-center">
      <pagination-controls
        (pageChange)="page = $event"
        nextLabel="Siguiente"
        previousLabel="Anterior"
        class="mt-3"
      ></pagination-controls>
    </div>
  </form>
</div>

<ng-template #modalProspect let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">Eliminar prospecto</h4>
    <button class="close" type="button" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Deseas eliminar al prospecto <strong>{{ nameDeleted }}</strong>
    </p>
    <div class="row">
      <div class="d-grip gap-2 d-md-flex justify-content-md-end">
        <button
          type="button"
          (click)="deleteProspect(idDeleted)"
          class="btn btn-outline-success"
        >
          Confirmar
        </button>
        <button class="btn btn-outline-danger" type="button" (click)="close()">
          Cancelar
        </button>
      </div>
    </div>
  </div>
</ng-template>
