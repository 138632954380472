import { Component } from '@angular/core';

@Component({
  selector: 'app-show-daily-payments',
  templateUrl: './show-daily-payments.component.html',
  styleUrls: ['./show-daily-payments.component.scss']
})
export class ShowDailyPaymentsComponent {

}
