import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-list-payments',
  templateUrl: './list-payments.component.html',
  styleUrls: ['./list-payments.component.scss'],
})
export class ListPaymentsComponent implements OnInit {
  filterText: string = '';
  payments: any = [];
  filterPayments: any = [];
  breadcrumbs: any;
  page: number = 1;

  constructor(private api: ApiService) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de pagos',
        link: false,
      },
    ];

    this.loadPayments();
  }

  loadPayments() {
    this.api.get('payments').subscribe({
      next: (payments) => {
        this.payments = payments.payments;
        this.filterPayments = this.payments;
      },
      error: (err) => {},
    });
  }

  clearSearch() {
    this.filterText = '';
    this.loadPayments();
  }

  matches(listData: any, term: string) {
    let variable: string = '';

    if (listData.payment_method) {
      variable = listData.payment_method
        .toLowerCase()
        .includes(term.toLowerCase());
    }

    return listData.name.toLowerCase().includes(term.toLowerCase()) || variable;
  }

  filterRecords() {
    const filterData = this.payments.filter((data: any) => {
      return this.matches(data, this.filterText);
    });

    this.filterPayments = filterData;
  }
}
