import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-payments',
  templateUrl: './create-payments.component.html',
  styleUrls: ['./create-payments.component.scss'],
})
export class CreatePaymentsComponent implements OnInit {
  paymentsForm: any = FormGroup;
  submitted: boolean = false;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  generalMessage: string = '';
  breadcrumbs: any;
  paymentsMethods: any = [];
  flagErrors: boolean = false;

  constructor(private api: ApiService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de pagos',
        path: '/payments/list',
        link: true,
      },
      {
        name: 'Crear pago',
        link: false,
      },
    ];

    this.validators();
    this.getPaymentsMethods();
  }

  getPaymentsMethods() {
    this.api.get('payment_methods').subscribe({
      next: (paymentMethods) => {
        this.paymentsMethods = paymentMethods.payment_methods;
      },
      error: (err) => {},
    });
  }

  validators() {
    this.paymentsForm = this.fb.group({
      payment_method_id: ['', [Validators.required]],
      payment_name: ['', [Validators.required, Validators.minLength(5)]],
      order: ['', [Validators.required]],
      is_active: [''],
    });
  }

  get f() {
    return this.paymentsForm.controls;
  }

  createPayments() {
    this.submitted = true;
    this.flagErrors = false;

    if (this.paymentsForm.invalid) {
      this.flagErrors = true;
      return;
    }

    const obj = {
      payment_name: this.paymentsForm.value.payment_name,
      paymentMethodId:
        this.paymentsForm.value.payment_method_id == 0
          ? ''
          : this.paymentsForm.value.payment_method_id,
      is_active:
        this.paymentsForm.value.is_active == null
          ? false
          : this.paymentsForm.value.is_active,
      order: this.paymentsForm.value.order,
    };

    this.api.create('payments', obj).subscribe({
      next: (payments) => {
        this.submitted = false;
        this.isSaved = true;
        this.generalMessage = payments.message;

        this.resetPaymentsForm();
      },
      error: (err) => {},
    });
  }

  resetPaymentsForm() {
    this.paymentsForm.reset();
    this.paymentsForm.get('payment_method_id').setValue('');

    setTimeout(() => {
      this.submitted = false;
      this.isDisplayed = false;
      this.isSaved = false;
      this.generalMessage = '';
      this.flagErrors = false;
    }, 4000);
  }
}
