<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Registrar pago adelantado</h3>
  </div>
</div>
<hr />

<div class="d-grip gap-1 d-md-flex justify-content-md-end mb-3">
  <a [routerLink]="['/packages/list']" class="btn btn-outline-success">
    <i class="bi bi-arrow-left-circle"></i>
    Regresar a lista de paquetes
  </a>
</div>
<hr />

<div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
  <ngb-alert
    #selfClosingAlert
    type="success"
    *ngIf="generalMessage != '' && submitted"
  >
    {{ generalMessage }}
  </ngb-alert>

  <form [formGroup]="advanceForm" (ngSubmit)="addAdvancePayments()" novalidate>
    <div class="accordion" id="advance_payments">
      <div class="accordion-item">
        <h2 class="accordion-header" id="advance-payments-information">
          <button
            class="accordion-button color-header-collapse"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
            disabled
          >
            <span class="color-text"> Registrar pago adelantado </span>
          </button>
        </h2>
        <div
          id="collapseOne"
          class="accordion-collapse collapse show"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <input type="hidden" name="sessionId" formControlName="sessionId" />
            <input type="hidden" name="apptId" formControlName="apptId" />
            <div class="mb-3 form-floating">
              <input
                type="text"
                name="amount"
                id="amount"
                formControlName="amount"
                class="form-control"
                placeholder="Cantidad a pagar"
                required
                [ngClass]="{
                  'is-invalid': submitted && f.amount.errors,
                  'is-valid': submitted && !f.amount.errors
                }"
              />
              <label for="amount">Cantidad a pagar</label>
            </div>
            <div class="form-floating mb-3">
              <select
                name="paymentMethodId"
                id="paymentMethodId"
                class="form-control"
                formControlName="paymentMethodId"
                placeholcer="Metodo de pago"
                (change)="checkPaymentMethods($event)"
                required
                [ngClass]="{
                  'is-invalid': submitted && f.paymentMethodId.errors,
                  'is-valid': submitted && !f.paymentMethodId.errors
                }"
              >
                <option value="" selected>Seleccione un metodo de pago</option>
                <option
                  value="{{ paymentMethod.id }}"
                  *ngFor="let paymentMethod of payment_methods"
                >
                  {{ paymentMethod.payment_name }}
                </option>
              </select>
              <label for="paymentMethodId">Seleccione un metodo de pago</label>
            </div>
            <div class="form-floating mb-3" *ngIf="paymentsFilter.length > 0">
              <select
                name="paymentId"
                id="paymentId"
                class="form-control"
                formControlName="paymentId"
                placeholcer="Tipo de pago"
                required
                [ngClass]="{
                  'is-invalid': submitted && f.paymentId.errors,
                  'is-valid': submitted && !f.paymentId.errors
                }"
              >
                <option value="0" selected>Seleccione un tipo de pago</option>
                <option
                  value="{{ payment.id }}"
                  *ngFor="let payment of paymentsFilter"
                >
                  {{ payment.name }}
                </option>
              </select>
              <label for="paymentMethodId">Tipo de pago</label>
            </div>
            <div class="form-floating mb-3" *ngIf="paymentsFilter.length > 0">
              <select
                name="terminal"
                id="terminal"
                class="form-control"
                formControlName="terminal"
                placeholcer="Selecciona una terminal"
                required
                [ngClass]="{
                  'is-invalid': submitted && f.terminal.errors,
                  'is-valid': submitted && !f.terminal.errors
                }"
              >
                <option value="" selected>Seleccione una terminal</option>
                <option value="Banorte">Banorte</option>
                <option value="BBVA">BBVA</option>
                <option value="Firstdata">Firstdata</option>
                <option value="Netpay">Netpay</option>
                <option value="Getnet">Getnet</option>
              </select>
              <label for="paymentMethodId">Selecciona una terminal</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-3 mt-2 d-grip gap-2 d-md-flex justify-content-md-end">
      <button
        type="button"
        class="btn btn-danger"
        (click)="resetAdvancePayments()"
      >
        <i class="bi bi-eraser"></i>
        Limpiar Informaci&oacute;n
      </button>
      <button type="submit" class="btn btn-success" [disabled]="submitted">
        <i class="bi bi-plus-circle"></i>
        Agregar pago adelantado
      </button>
    </div>
  </form>
</div>
