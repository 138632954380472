import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-list-sells',
  templateUrl: './list-sells.component.html',
  styleUrls: ['./list-sells.component.scss'],
})
export class ListSellsComponent implements OnInit {
  breadcrumbs: any;
  sells: any = [];
  filterText: string = '';
  filterSells: any = [];
  page: number = 1;
  offices: any = [];
  rolename: string = '';
  rolekey: string = '';
  roleofficeid: number = 0;
  currentOfficeId: number = 0;

  constructor(private api: ApiService) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de ventas por dia',
        link: false,
      },
    ];

    this.getRoles();
    this.getOffices();
    this.loadSells();
  }

  getRoles() {
    this.api.get('employees/roles').subscribe({
      next: (roles) => {
        this.rolename = roles.user.role;
        this.rolekey = roles.user.key.toLowerCase();
        this.roleofficeid = roles.user.office.id;
        this.currentOfficeId = this.roleofficeid;
      },
      error: (err) => {},
    });
  }

  loadSells(): void {
    this.api.get('payments-per-day').subscribe({
      next: (sells) => {
        this.sells = sells.dailyPayments;
        this.filterSells = this.sells;
      },
      error: (err) => {},
    });
  }

  getOffices() {
    this.api.get('offices').subscribe({
      next: (offices) => {
        this.offices = offices.offices;
      },
      error: (err) => {},
    });
  }

  setCurrentOffice(evt: any) {
    this.api.get(`payments-per-day/${evt.target.value}`).subscribe({
      next: (sells) => {
        this.sells = sells.dailyPayments;
        this.filterSells = this.sells;
      },
      error: (err) => {},
    });
  }

  clearSearch() {
    this.filterText = '';
    this.currentOfficeId = this.roleofficeid;
    this.loadSells();
  }

  matches(listData: any, term: string) {
    return listData.date.includes(term) || listData.amount.includes(term);
  }

  filterRecords() {
    const filterData = this.sells.filter((data: any) => {
      return this.matches(data, this.filterText);
    });

    this.filterSells = filterData;
  }
}
