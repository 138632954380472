import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, finalize, retry } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  //API_SERVICE = 'http://127.0.0.1:8000';
  API_SERVICE = 'https://www.laserclinic.mx';

  constructor(private http: HttpClient, private router: Router) {}

  get(module: string): Observable<any> {
    const url = `${this.API_SERVICE}/api/${module}`;

    return this.http.get<any>(url).pipe(retry(1), catchError(this.handleError));
  }

  getById(module: string, params: number | string): Observable<any> {
    const url = `${this.API_SERVICE}/api/${module}/${params}`;

    return this.http.get<any>(url).pipe(retry(1), catchError(this.handleError));
  }

  create(module: string, params: object): Observable<any> {
    const url = `${this.API_SERVICE}/api/${module}`;

    return this.http
      .post<any>(url, JSON.stringify(params))
      .pipe(retry(1), catchError(this.handleError));
  }

  update(module: string, id: number, params: object): Observable<any> {
    const url = `${this.API_SERVICE}/api/${module}/${id}`;

    return this.http
      .put<any>(url, JSON.stringify(params))
      .pipe(retry(1), catchError(this.handleError));
  }

  delete(module: string, id: number): Observable<any> {
    const url = `${this.API_SERVICE}/api/${module}/${id}`;

    return this.http
      .delete<any>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  login(params: object): Observable<any> {
    const url = `${this.API_SERVICE}/api/login_check`;

    return this.http
      .post<any>(url, JSON.stringify(params))
      .pipe(retry(1), catchError(this.handleError));
  }

  getToken() {
    return localStorage.getItem('access_token');
  }

  getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }

  getTotalCabins() {
    return localStorage.getItem('total_cabins');
  }

  setTotalCabins(total: any) {
    localStorage.setItem('total_cabins', total);
  }

  get isLoggedIn(): boolean {
    let authToken = this.getToken();

    return authToken !== null;
  }

  get getTotalCabinsPerOffice(): number {
    const totalCabins = Number(this.getTotalCabins());

    return totalCabins;
  }

  logout() {
    const url = 'logout/office';
    return this.get(url).subscribe({
      next: (logoutData) => {
        if (logoutData.done) {
          let removeToken = localStorage.removeItem('access_token');

          if (removeToken === null || removeToken === undefined) {
            this.router.navigate(['']);
          }
        }
      },
      error: (err) => {
        this.router.navigate(['']);
      },
    });
  }

  handleError(error: any) {
    let errorMessage = '';

    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    console.log(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
