<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Actualizar paquete</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-3">
    <a [routerLink]="['/packages/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de paquetes
    </a>
  </div>
  <hr />

  <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
    <div *ngIf="isDisplayed" class="alert alert-danger" role="alert">
      {{ generalMessage }}
    </div>
    <div *ngIf="isSaved" class="alert alert-success" role="alert">
      {{ generalMessage }}
    </div>

    <form [formGroup]="packagesForm" (ngSubmit)="updatePackages()" novalidate>
      <div class="accordion" id="packages_sessions">
        <div class="accordion-item">
          <h2 class="accordion-header" id="package-information">
            <button
              class="accordion-button color-header-collapse"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              disabled
            >
              <span class="color-text"> Actualizaci&oacute;n del paquete </span>
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div class="col-md-12">
                <div class="row">
                  <div class="form-floating mb-1 col-md-12">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      formControlName="name"
                      class="form-control"
                      placeholder="Nombre del cliente"
                      required
                      readonly
                    />
                    <label for="name" class="ms-2">Nombre del cliente</label>
                  </div>
                </div>
                <div class="row">
                  <div class="form-floating mb-3 mt-2 col-md-12">
                    <input
                      type="text"
                      name="areas"
                      id="areas"
                      formControlName="areas"
                      class="form-control"
                      placeholder="Areas del paquete"
                      required
                      readonly
                    />
                    <label for="areas" class="ms-2">Areas del paquete</label>
                  </div>
                </div>

                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Sesiones por area</h5>
                    <hr />
                    <div class="row mb-2" *ngFor="let fields of areasNames">
                      <div class="col-md-6 form-floating">
                        <input
                          type="text"
                          name="custom-area-{{ fields.value }}"
                          id="custom-area-{{ fields.value }}"
                          class="form-control"
                          placeholder="Area"
                          value="{{ fields.area }}"
                          readonly
                        />
                        <label for="" class="ms-2"> Area </label>
                      </div>
                      <div class="col-md-6 form-floating">
                        <!-- <input
                          type="text"
                          name="custom-session-{{ fields.sess_id }}"
                          id="custom-session-{{ fields.sess_id }}"
                          class="form-control"
                          placeholder="Numero de Sesiones"
                          value="{{ fields.number }}"
                          (keyup)="setValueCustomAreas($event, fields.sess_id)"
                        /> -->
                        <select
                          name="custom-session-{{ fields.sess_id }}"
                          id="custom-session-{{ fields.sess_id }}"
                          class="form-control"
                          placeholder="Numero de sesiones"
                          [disabled]="rolekey != 'admin'"
                          (change)="setValueCustomAreas($event, fields.sess_id)"
                        >
                          <option value="">
                            Seleccione el numero de sesiones *
                          </option>
                          <option
                            selected="{{
                              fields.number == i ? 'selected' : ''
                            }}"
                            value="{{ i }}"
                            *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                          >
                            {{ i }} {{ i == 1 ? "Sesion" : "Sesiones" }}
                          </option>
                        </select>
                        <label for="" class="ms-2"> Numero de Sesiones </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="form-floating col-md-6 mt-3">
                    <!-- <select
                      name="session_number"
                      id="session_number"
                      class="form-control"
                      formControlName="session_number"
                      placeholcer="Numero de sesiones"
                      [ngClass]="{
                        'is-invalid': submitted && f.session_number.errors,
                        'is-valid': submitted && !f.session_number.errors
                      }"
                    >
                      <option value="" selected>
                        Selecciona el numero de sesiones
                      </option>
                      <option
                        [selected]="numberSession == i"
                        value="{{ i }}"
                        *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                      >
                        {{ i }} {{ i == 1 ? "Sesion" : "Sesiones" }}
                      </option>
                    </select> -->
                    <input
                      type="text"
                      id="session_number"
                      class="form-control"
                      name="session_number"
                      formControlName="session_number"
                      placeholder="Numero de sesiones"
                      readonly
                    />
                    <label for="session_number" class="ms-2">
                      Numero total de sesiones del paquete
                    </label>
                  </div>

                  <div class="col-md-6">
                    <label for="duration" class="form-label"
                      >Duraci&oacute;n</label
                    >
                    <div class="input-group mb-3">
                      <input
                        type="number"
                        name="hours"
                        id="hours"
                        formControlName="hours"
                        class="form-control"
                        placeholder="Ejemplo: 5"
                        [ngClass]="{
                          'is-invalid': submitted && f.hours.errors,
                          'is-valid': submitted && !f.hours.errors
                        }"
                      />
                      <span class="input-group-text">horas</span>
                      <input
                        type="number"
                        name="minutes"
                        id="minutes"
                        formControlName="minutes"
                        class="form-control"
                        placeholder="Ejemplo: 30"
                        [ngClass]="{
                          'is-invalid': submitted && f.minutes.errors,
                          'is-valid': submitted && !f.minutes.errors
                        }"
                      />
                      <span class="input-group-text">minutos</span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label for="price" class="form-label">Total</label>
                    <div class="input-group mb-3">
                      <span class="input-group-text">$</span>
                      <input
                        type="text"
                        class="form-control"
                        id="price"
                        name="price"
                        formControlName="price"
                        readonly
                        [ngClass]="{
                          'is-invalid': submitted && f.price.errors,
                          'is-valid': submitted && !f.price.errors
                        }"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="price" class="form-label"
                      >Total con descuento</label
                    >
                    <div class="input-group mb-3">
                      <span class="input-group-text">$</span>
                      <input
                        type="text"
                        class="form-control"
                        id="price_discount"
                        name="price_discount"
                        formControlName="price_discount"
                        readonly
                        [ngClass]="{
                          'is-invalid': submitted && f.price_discount.errors,
                          'is-valid': submitted && !f.price_discount.errors
                        }"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="price" class="form-label">
                      Descuento aplicado %
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="discount"
                        name="discount"
                        formControlName="discount"
                        readonly
                        [ngClass]="{
                          'is-invalid': submitted && f.discount.errors,
                          'is-valid': submitted && !f.discount.errors
                        }"
                      />
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="!existsPaymentMethod">
                  <div class="form-floating mb-2 col-md-{{ classPayments }}">
                    <select
                      name="paymentMethodId"
                      id="paymentMethodId"
                      class="form-control"
                      formControlName="paymentMethodId"
                      placeholcer="Metodo de pago *"
                      (change)="checkPaymentMethods($event)"
                      required
                      [ngClass]="{
                        'is-invalid': submitted && f.paymentMethodId.errors,
                        'is-valid': submitted && !f.paymentMethodId.errors
                      }"
                    >
                      <option value="" selected="">
                        Seleccione un metodo de pago
                      </option>
                      <option
                        value="{{ paymentMethod.id }}"
                        *ngFor="let paymentMethod of payment_methods"
                      >
                        {{ paymentMethod.payment_name }}
                      </option>
                    </select>
                    <label for="paymentMethodId" class="ms-2">
                      Seleccione un metodo de pago *
                    </label>
                    <div>
                      <div
                        *ngIf="
                          f.paymentMethodId?.invalid &&
                          flagErrors &&
                          (f.paymentMethodId?.errors ||
                            f.paymentMethodId?.touched)
                        "
                      >
                        <div
                          class="ms-2 mt-1 text-danger"
                          *ngIf="f.paymentMethodId?.errors.required"
                        >
                          El metodo de pago es obligatorio.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="form-floating mb-2 col-md-{{ classPayments }}"
                    *ngIf="paymentsFilter.length > 0"
                  >
                    <select
                      name="paymentId"
                      id="paymentId"
                      class="form-control"
                      formControlName="paymentId"
                      placeholcer="Opcion de pago *"
                      required
                      [ngClass]="{
                        'is-invalid': submitted && f.paymentId.errors,
                        'is-valid': submitted && !f.paymentId.errors
                      }"
                    >
                      <option value="" selected="">
                        Seleccione la opci&oacute;n de pago
                      </option>
                      <option
                        value="{{ payment.id }}"
                        *ngFor="let payment of paymentsFilter"
                      >
                        {{ payment.name }}
                      </option>
                    </select>
                    <label for="paymentMethodId" class="ms-2"
                      >Opci&oacute;n de pago *</label
                    >
                  </div>
                  <div
                    class="form-floating mb-2 col-md-{{ classPayments }}"
                    *ngIf="paymentsFilter.length > 0"
                  >
                    <select
                      name="terminal"
                      id="terminal"
                      class="form-control"
                      formControlName="terminal"
                      placeholcer="Selecciona una terminal *"
                      required
                      [ngClass]="{
                        'is-invalid': submitted && f.terminal.errors,
                        'is-valid': submitted && !f.terminal.errors
                      }"
                    >
                      <option value="" selected>Seleccione una terminal</option>
                      <option value="Banorte">Banorte</option>
                      <option value="BBVA">BBVA</option>
                      <option value="Firstdata">Firstdata</option>
                      <option value="Netpay">Netpay</option>
                      <option value="Getnet">Getnet</option>
                    </select>
                    <label for="paymentMethodId" class="ms-2">
                      Selecciona una terminal *</label
                    >
                  </div>
                </div>

                <div class="row" *ngIf="existsPaymentMethod">
                  <div class="col-md-{{ columnsPayments }} form-floating">
                    <input
                      type="text"
                      name="paymentMethodId"
                      id="paymentMethodId"
                      formControlName="paymentMethodId"
                      class="form-control"
                      placeholder="Metodo de pago"
                      readonly
                    />
                    <label for="" class="ms-2"> Metodo de pago </label>
                  </div>
                  <div class="col-md-{{ columnsPayments }} form-floating">
                    <input
                      type="text"
                      name="paymentId"
                      id="paymentId"
                      class="form-control"
                      placeholder="Opciones de pago"
                      formControlName="paymentId"
                      readonly
                    />
                    <label for="" class="ms-2"> Opciones de pago </label>
                  </div>
                  <div class="col-md-{{ columnsPayments }} form-floating">
                    <input
                      type="text"
                      name="terminal"
                      id="terminal"
                      class="form-control"
                      placeholder="Terminal"
                      formControlName="terminal"
                      readonly
                    />
                    <label for="" class="ms-2"> Terminal </label>
                  </div>
                </div>

                <div class="col-md-12 mt-3">
                  <div class="mb-3">
                    <div class="form-floating">
                      <textarea
                        class="form-control"
                        placeholder="Leave a comment here"
                        id="floatingTextarea"
                        formControlName="observations"
                        style="height: 100px"
                      ></textarea>
                      <label for="floatingTextarea">Comments</label>
                    </div>
                  </div>
                </div>

                <div
                  class="row mt-3"
                  *ngIf="imageContract == '' || imageContract == '0'"
                >
                  <div class="mb-3">
                    <label for="formFile" class="form-label">
                      Selecciona una imagen
                    </label>
                    <input
                      class="form-control"
                      type="file"
                      id="formFile"
                      (change)="onFileChange($event)"
                      accept="image/*"
                    />
                  </div>
                </div>
                <div class="row mt-3" *ngIf="imageContract != ''">
                  <div class="">
                    <button
                      class="btn btn-success"
                      type="button"
                      (click)="displayContract()"
                    >
                      Abrir imagen del contrato
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 mt-2 d-grip gap-2 d-md-flex justify-content-md-end">
        <button type="submit" class="btn btn-success">
          <i class="bi bi-plus-circle"></i>
          Actualizar
        </button>
      </div>
    </form>
  </div>
</div>

<ng-template #contractModal let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">Contrato</h4>
    <button class="close" type="button" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="{{ imageContract }}" class="card-img-top" />
  </div>
</ng-template>
