import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class CreateComponent implements OnInit {
  breadcrumbs: any;
  prospectsForm: any = FormGroup;
  submittedProspect: boolean = false;
  packages: any = [];
  packagesFilter: any = [];
  isDisplayed: boolean = false;
  isSaved: boolean = false;
  generalMessage: string = '';
  offices: any = [];

  constructor(private api: ApiService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de prospectos',
        path: '/prospects/list',
        link: true,
      },
      {
        name: 'Dar de alta prospecto',
        link: false,
      },
    ];

    this.validations();
    this.getAreas();
    this.getOffices();
  }

  validations(): void {
    this.prospectsForm = this.fb.group({
      nombre: ['', [Validators.required]],
      telefono: ['', [Validators.required]],
      comments: ['', [Validators.required]],
      atendio: ['', [Validators.required]],
      areas: ['', [Validators.required]],
      office: ['', [Validators.required]],
      medio: ['', [Validators.required]],
    });
  }

  get f() {
    return this.prospectsForm.controls;
  }

  getAreas() {
    this.api.get('areas/catalog').subscribe({
      next: (packages) => {
        this.packages = packages.areas;
        const packagesFormat = this.packages.map((packageName: any) => {
          return {
            value: packageName.id,
            label: packageName.area,
          };
        });
        this.packagesFilter = [{ options: packagesFormat }];
      },
      error: (err) => {},
    });
  }

  getOffices() {
    this.api.get('offices').subscribe({
      next: (offices) => {
        this.offices = offices.offices;
      },
      error: (err) => {},
    });
  }

  updateAreas(evt: any) {
    this.prospectsForm.patchValue({
      areas_sessions: evt.value,
    });
  }

  createProspect(): void {
    this.submittedProspect = true;

    if (this.prospectsForm.invalid) {
      console.log(this.prospectsForm);
      return;
    }

    const obj = {
      name: this.prospectsForm.value.nombre,
      phone: this.prospectsForm.value.telefono,
      comments: this.prospectsForm.value.comments,
      atendio: this.prospectsForm.value.atendio,
      areas: this.prospectsForm.value.areas,
      office: this.prospectsForm.value.office,
      medio: this.prospectsForm.value.medio,
    };

    this.api.create('prospects', obj).subscribe({
      next: (prospect) => {
        this.isDisplayed = false;
        this.isSaved = true;
        this.submittedProspect = false;
        this.generalMessage = prospect.message;
        this.resetProspectForm();
      },
      error: (err) => {},
    });
  }

  resetProspectForm(): void {
    this.prospectsForm.get('areas').reset();
    this.prospectsForm.reset();
    this.prospectsForm.patchValue({
      areas: '',
    });

    setTimeout(() => {
      this.submittedProspect = false;
      this.isSaved = false;
      this.isDisplayed = false;
      this.generalMessage = '';
    }, 4000);
  }
}
