<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Editar cliente</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-5">
    <a [routerLink]="['/clients/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de clientes
    </a>
  </div>

  <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
    <ngb-alert
      #selfClosingAlert
      type="danger"
      *ngIf="generalMessage != '' && isDisplayed"
    >
      {{ generalMessage }}
    </ngb-alert>
    <ngb-alert
      #selfClosingAlert
      type="success"
      *ngIf="generalMessage != '' && isSaved"
    >
      {{ generalMessage }}
    </ngb-alert>

    <form [formGroup]="clients" (ngSubmit)="updateClient()" novalidate>
      <div class="accordion" id="cities_accordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="cities-information">
            <button
              class="accordion-button color-header-collapse"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              disabled
            >
              <span class="color-text"> Actualizaci&oacute;n de Clientes </span>
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-6 mb-3 form-floating">
                  <input
                    type="text"
                    name="first_name"
                    id="first_name"
                    formControlName="first_name"
                    class="form-control"
                    placeholder="Nombre"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.first_name.errors,
                      'is-valid': submitted && !f.first_name.errors
                    }"
                  />
                  <label for="first_name" class="ms-2">Nombre</label>
                </div>
                <div class="col-md-6 mb-3 form-floating">
                  <input
                    type="text"
                    name="last_name"
                    id="last_name"
                    formControlName="last_name"
                    class="form-control"
                    placeholder="Apellidos"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.last_name.errors,
                      'is-valid': submitted && !f.last_name.errors
                    }"
                  />
                  <label for="last_name" class="ms-2">Apellidos</label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mb-3 form-floating">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    formControlName="email"
                    class="form-control"
                    placeholder="Email"
                    [ngClass]="{
                      'is-invalid': submitted && f.email.errors,
                      'is-valid': submitted && !f.email.errors
                    }"
                  />
                  <label for="last_name" class="ms-2">Email</label>
                </div>
                <div class="col-md-6 mb-3 form-floating">
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    formControlName="phone"
                    class="form-control"
                    placeholder="Telefono"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.phone.errors,
                      'is-valid': submitted && !f.phone.errors
                    }"
                  />
                  <label for="phone" class="ms-2">Telefono</label>
                </div>
              </div>

              <div class="row">
                <div class="mb-3 form-floating col-md-6">
                  <div class="input-group">
                    <input
                      type="text"
                      name="date_of_birthday"
                      class="form-control"
                      id="date_of_birthday"
                      formControlName="date_of_birthday"
                      placeholder="Fecha de nacimiento"
                      ngbDatepicker
                      [minDate]="{ year: 1970, month: 1, day: 1 }"
                      #d="ngbDatepicker"
                      required
                      readonly
                      style="height: 3.5rem"
                      [ngClass]="{
                        'is-invalid': submitted && f.date_of_birthday.errors,
                        'is-valid': submitted && !f.date_of_birthday.errors
                      }"
                    />
                    <button
                      class="btn btn-outline-secondary bi bi-calendar3"
                      (click)="d.toggle()"
                      type="button"
                    ></button>
                  </div>
                </div>
                <div class="col-md-6 mb-3 form-floating">
                  <input
                    type="text"
                    name="cp"
                    id="cp"
                    formControlName="cp"
                    class="form-control"
                    placeholder="Codigo postal"
                    [ngClass]="{
                      'is-invalid': submitted && f.cp.errors,
                      'is-valid': submitted && !f.cp.errors
                    }"
                  />
                  <label for="cp" class="ms-2">Codigo postal</label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mb-3 form-floating">
                  <input
                    type="number"
                    name="reference"
                    id="reference"
                    formControlName="reference"
                    class="form-control"
                    placeholder="Numero de expediente"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.reference.errors,
                      'is-valid': submitted && !f.reference.errors
                    }"
                  />
                  <label for="cp" class="ms-2">Numero de expediente</label>
                </div>
                <div class="col-md-6 mb-3 form-floating">
                  <select
                    class="form-select"
                    aria-label="Floating label select"
                    id="office_id"
                    name="office_id"
                    formControlName="office_id"
                  >
                    <option selected value="">Selecciona una sucursal</option>
                    <option
                      *ngFor="let office of offices"
                      value="{{ office.id }}"
                    >
                      {{ office.name }}
                    </option>
                  </select>
                  <label for="office_id" class="ms-1">
                    Selecciona una sucursal
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3 mt-2 d-grip gap-2 d-md-flex justify-content-md-end">
        <button type="submit" class="btn btn-success">
          <i class="bi bi-plus-circle"></i>
          Actualizar
        </button>
      </div>
    </form>
  </div>
</div>
