<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Lista de colaboradores</h3>
  </div>
  <hr />
  <div class="row align-items-center mb-3">
    <div class="col-md-6">
      <div class="input-group">
        <input
          type="text"
          id="table-complete-search"
          name="searchText"
          class="form-control"
          placeholder="Buscar colaboradores"
          [(ngModel)]="filterText"
          (keyup)="filterRecords()"
        />

        <select
          class="form-select ms-1"
          name="currentOfficeId"
          [(ngModel)]="currentOfficeId"
          id="currentOfficeId"
          (change)="setCurrentOffice($event)"
          *ngIf="rolekey == 'admin'"
        >
          <option value="0" selected="selected">Selecciona sucursal</option>
          <option
            *ngFor="let office of offices"
            value="{{ office.id }}"
            selected="{ office.id == roleofficeid ? 'selected' : '' }"
          >
            {{ office.name }}
          </option>
        </select>

        <button
          class="btn btn-outline-danger ms-1"
          type="button"
          (click)="clearSearch()"
        >
          Limpiar busqueda
        </button>
      </div>
    </div>
    <div class="col-md-6">
      <div class="d-grip gap-1 d-md-flex justify-content-md-end">
        <a [routerLink]="['/employees/create']" class="btn btn-outline-success">
          <i class="bi bi-plus-circle"></i>
          Crear colaborador
        </a>
      </div>
    </div>
  </div>
  <hr />

  <div class="alert alert-success" role="alert" *ngIf="flagDeleted">
    {{ message }}
  </div>

  <form>
    <table class="table table-striped">
      <thead>
        <tr>
          <!--th class="col">ID</th-->
          <th class="col">Nombre(s)</th>
          <th class="col">Apellido(s)</th>
          <th class="col">Correo electr&oacute;nico</th>
          <th class="col">Tel&eacute;fono</th>
          <th class="col">Sucursal</th>
          <th class="col">Ciudad</th>
          <th class="col">Rol</th>
          <th class="col">Hora entrada</th>
          <th class="col">Hora salida</th>
          <th class="col">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let employee of filterEmployees
              | paginate : { itemsPerPage: 15, currentPage: page }
          "
        >
          <!--td scope="row">
            {{ employee.id }}
          </td-->
          <td>
            {{ employee.first_name }}
          </td>
          <td>
            {{ employee.last_name }}
          </td>
          <td>{{ employee.email }}</td>
          <td>{{ employee.phone }}</td>
          <td>{{ employee.office.name }}</td>
          <td>{{ employee.city.name }}</td>
          <td>{{ employee.roles.name }}</td>
          <td>{{ employee.startHour }}</td>
          <td>{{ employee.endHour }}</td>
          <td>
            <div class="d-flex d-flex-row">
              <div>
                <a
                  [routerLink]="['/employees/edit', employee.id]"
                  placement="top"
                  ngbTooltip="Editar"
                  role="button"
                  class="btn btn-primary"
                >
                  <i class="bi bi-pencil-square"></i>
                </a>
              </div>
              <div class="ms-1">
                <a
                  [routerLink]="['/employees/update-password', employee.id]"
                  placement="top"
                  ngbTooltip="Cambiar contraseña"
                  role="button"
                  class="btn btn-primary"
                >
                  <i class="bi bi-key-fill"></i>
                </a>
              </div>
              <div class="ms-1">
                <a
                  [routerLink]="['employee-permissions', employee.id]"
                  placement="top"
                  ngbTooltip="Dar permisos"
                  role="button"
                  class="btn btn-primary"
                >
                  <i class="bi bi-file-lock2"></i>
                </a>
              </div>
              <div class="ms-1">
                <a
                  (click)="openDeleteEmployee(employee.id)"
                  placement="top"
                  ngbTooltip="Eliminar colaborador"
                  role="button"
                  class="btn btn-primary"
                >
                  <i class="bi bi-trash3-fill"></i>
                </a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="col-md-12 d-flex align-items-center justify-content-center">
      <pagination-controls
        (pageChange)="page = $event"
        nextLabel="Siguiente"
        previousLabel="Anterior"
        class="mt-3"
      ></pagination-controls>
    </div>
  </form>
</div>

<ng-template #modalEmployee let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">Baja de colaborador</h4>
    <button class="close" type="button" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Deseas dar de baja al colaborador <strong>{{ employeeName }}</strong>
    </p>
    <div class="row">
      <div class="d-grip gap-2 d-md-flex justify-content-md-end">
        <button
          type="button"
          (click)="deleteEmployee(idDeleted)"
          class="btn btn-outline-success"
        >
          Confirmar
        </button>
        <button class="btn btn-outline-danger" type="button" (click)="close()">
          Cancelar
        </button>
      </div>
    </div>
  </div>
</ng-template>
