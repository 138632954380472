import { Component } from '@angular/core';

@Component({
  selector: 'app-set-permissions',
  templateUrl: './set-permissions.component.html',
  styleUrls: ['./set-permissions.component.scss']
})
export class SetPermissionsComponent {

}
