import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-list-employees',
  templateUrl: './list-employees.component.html',
  styleUrls: ['./list-employees.component.scss'],
})
export class ListEmployeesComponent implements OnInit {
  @ViewChild('modalEmployee', { static: false })
  modalEmployee!: TemplateRef<any>;
  modalEmployeeClose: any;
  idDeleted: number = 0;
  employeeName: string = '';
  flagDeleted: boolean = false;
  message: string = '';
  filterText: string = '';
  filterEmployees: any = [];
  employees: any = [];
  breadcrumbs: any;
  page: number = 1;
  offices: any = [];
  rolename: string = '';
  rolekey: string = '';
  roleofficeid: number = 0;
  currentOfficeId: number = 0;

  constructor(private api: ApiService, private modal: NgbModal) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de colaboradores',
        link: false,
      },
    ];

    this.getEmployees();
    this.roleInfo();
    this.getOffices();
  }

  roleInfo() {
    this.api.get('employees/roles').subscribe({
      next: (roles) => {
        this.rolename = roles.user.role;
        this.rolekey = roles.user.key.toLowerCase();
        this.roleofficeid = roles.user.office.id;
        this.currentOfficeId = this.roleofficeid;
      },
      error: (err) => {},
    });
  }

  getEmployees(): void {
    this.api.get('employees').subscribe({
      next: (employees) => {
        this.employees = employees.employees;
        this.filterEmployees = this.employees;
      },
      error: (err) => {},
    });
  }

  getOffices() {
    this.api.get('offices').subscribe({
      next: (offices) => {
        this.offices = offices.offices;
      },
      error: (err) => {},
    });
  }

  setCurrentOffice(evt: any) {
    this.api.get(`employees/${evt.target.value}`).subscribe({
      next: (employees) => {
        this.employees = employees.employees;
        this.filterEmployees = this.employees;
      },
      error: (err) => {},
    });
  }

  matches(listData: any, term: string): any {
    return (
      listData.email.toLowerCase().includes(term.toLowerCase()) ||
      listData.first_name.toLowerCase().includes(term.toLowerCase()) ||
      listData.last_name
        .toLowerCase()
        .includes(
          term.toLowerCase() ||
            listData.city.name.toLowerCase().includes(term.toLowerCase()) ||
            listData.office.name.toLowerCase().includes(term.toLowerCase()) ||
            listData.phone.includes(term)
        )
    );
  }

  filterRecords() {
    const filterData = this.employees.filter((data: any) => {
      return this.matches(data, this.filterText);
    });

    this.filterEmployees = filterData;
  }

  openDeleteEmployee(id: number): void {
    this.idDeleted = id;
    const employeeDelete = this.employees.filter((emp: any) => emp.id === id);
    this.employeeName = `${employeeDelete[0].first_name} ${employeeDelete[0].last_name}`;

    this.modalEmployeeClose = this.modal.open(this.modalEmployee, {
      size: 'md',
    });
  }

  deleteEmployee(id: number): void {
    this.api.delete('employees', Number(id)).subscribe({
      next: (employee) => {
        this.message = employee.message;
        const id = employee.employee.id;
        this.flagDeleted = true;
        this.filterEmployees = this.filterEmployees.filter(
          (employeeData: any) => employeeData.id !== id
        );
        this.modalEmployeeClose.close();
      },
      error: (err) => {},
    });

    setTimeout(() => {
      this.flagDeleted = false;
      this.message = '';
    }, 4000);
  }

  close(): void {
    this.modalEmployeeClose.close();
  }

  clearSearch() {
    this.filterText = '';
    this.currentOfficeId = this.roleofficeid;
    this.getEmployees();
  }
}
