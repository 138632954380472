import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-lists-prospects',
  templateUrl: './lists-prospects.component.html',
  styleUrls: ['./lists-prospects.component.scss'],
})
export class ListsProspectsComponent implements OnInit {
  @ViewChild('modalProspect', { static: false })
  modalProspect!: TemplateRef<any>;
  modalProspectClose: any;
  filterText: any = [];
  prospects: any = [];
  filterProspects: any = [];
  breadcrumbs: any;
  rolename: string = '';
  rolekey: string = '';
  message: string = '';
  idDeleted: number = 0;
  nameDeleted: string = '';
  flagDeleted: boolean = false;
  page: number = 1;
  roleofficeid: number = 0;
  currentOfficeId: number = 0;
  offices: any = [];

  constructor(private api: ApiService, private modal: NgbModal) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'List de prospectos',
        link: false,
      },
    ];

    this.loadProspects();
    this.loadRoles();
    this.getOffices();
  }

  loadRoles() {
    this.api.get('employees/roles').subscribe({
      next: (roles) => {
        this.rolename = roles.user.role;
        this.rolekey = roles.user.key.toLowerCase();
        this.roleofficeid = roles.user.office.id;
        this.currentOfficeId = this.roleofficeid;
      },
      error: (err) => {},
    });
  }

  loadProspects() {
    this.api.get('prospects').subscribe({
      next: (prospect) => {
        this.prospects = prospect.prospects;
        this.filterProspects = prospect.prospects;
      },
      error: (err) => {},
    });
  }

  getOffices() {
    this.api.get('offices').subscribe({
      next: (offices) => {
        this.offices = offices.offices;
      },
      error: (err) => {},
    });
  }

  deleteProspect(id: number): void {
    this.api.delete('prospects', Number(id)).subscribe({
      next: (prospect) => {
        this.message = prospect.message;
        const id = prospect.prospect.id;

        this.filterProspects = this.filterProspects.filter(
          (prospectData: any) => prospectData.id !== id
        );
        this.flagDeleted = true;
        this.modalProspectClose.close();
      },
      error: (err) => {},
    });

    setTimeout(() => {
      this.flagDeleted = false;
      this.message = '';
    }, 4000);
  }

  setCurrentOffice(evt: any) {
    this.api.get(`prospects/${evt.target.value}`).subscribe({
      next: (prospect) => {
        this.prospects = prospect.prospects;
        this.filterProspects = this.prospects;
      },
      error: (err) => {},
    });
  }

  clearSearch() {
    this.filterText = '';
    this.currentOfficeId = this.roleofficeid;
    this.loadProspects();
  }

  openDeleteMessage(id: number, name: string): void {
    this.idDeleted = id;
    this.nameDeleted = name;

    this.modalProspectClose = this.modal.open(this.modalProspect, {
      size: 'md',
    });
  }

  close(): void {
    this.modalProspectClose.close();
  }

  matches(listData: any, term: string) {
    return (
      listData.nombre.toLowerCase().includes(term.toLowerCase()) ||
      listData.telefono.includes(term.toLowerCase()) ||
      listData.atendio.toLowerCase().includes(term.toLowerCase()) ||
      listData.captacion.toLowerCase().includes(term.toLowerCase()) ||
      listData.areas.toLowerCase().includes(term.toLowerCase()) ||
      listData.comments.toLowerCase().includes(term.toLowerCase())
    );
  }

  filterRecords() {
    const filterData = this.prospects.filter((data: any) => {
      return this.matches(data, this.filterText);
    });

    this.filterProspects = filterData;
  }
}
