import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-show-client',
  templateUrl: './show-client.component.html',
  styleUrls: ['./show-client.component.scss'],
})
export class ShowClientComponent implements OnInit {
  @ViewChild('sessionsInfo', { static: false }) sessionsInfo!: TemplateRef<any>;
  @ViewChild('paymentsClient', { static: false })
  paymentsClient!: TemplateRef<any>;
  clientInfo: any = [];
  appointmentInfo: any = [];
  valorationsInfo: any = [];
  sessionsInfoData: any = [];
  breadcrumbs: any = [];
  paramId: string | null = '';
  threatmentNumber: number = 0;
  userInfo: any = [];
  paymentsInfo: any = [];
  submittedAppt: boolean = false;
  message: string = '';
  dataInfo: any = [];

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private modal: NgbModal
  ) {}

  ngOnInit(): void {
    this.paramId = this.route.snapshot.paramMap.get('id');
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de clientes',
        path: '/clients/list',
        link: true,
      },
      {
        name: 'Historico del cliente',
        link: false,
      },
    ];

    this.getClientInfo();
  }

  getClientInfo() {
    this.api.getById('clients/appoinments', Number(this.paramId)).subscribe({
      next: (client) => {
        console.log(client.client);
        this.clientInfo = client.client;
        this.appointmentInfo = client.client.appoinments;
        this.valorationsInfo = client.client.valorations;
      },
      error: (err) => {},
    });
  }

  showSessionsInfo(idData: number, num: number): void {
    this.threatmentNumber = num + 1;
    this.userInfo = this.appointmentInfo.filter(
      (data: any) => data.appoinment.id === idData
    );

    console.log(this.userInfo);
    this.modal.open(this.sessionsInfo, { size: 'lg' });
  }

  closeAppoinmentPackage(id: number): void {
    this.submittedAppt = true;

    this.api.getById('packages/close', Number(id)).subscribe({
      next: (data) => {
        this.message = data.message;
      },
      error: (err) => {},
    });
  }

  showPayments(id: any): void {
    this.api.getById('appoinment/payments', id).subscribe({
      next: (info) => {
        this.dataInfo = info.payments;

        this.modal.open(this.paymentsClient, { size: 'xl' });
      },
      error: (err) => {},
    });
  }
}
