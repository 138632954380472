<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Crear promocion</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-5">
    <a [routerLink]="['/promotions/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de promociones
    </a>
  </div>

  <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
    <div *ngIf="isDisplayed" class="alert alert-danger" role="alert">
      {{ generalMessage }}
    </div>
    <div *ngIf="isSaved" class="alert alert-success" role="alert">
      {{ generalMessage }}
    </div>

    <form
      [formGroup]="promotionsForm"
      (ngSubmit)="createPromotion()"
      novalidate
    >
      <div class="accordion" id="cities_accordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="cities-information">
            <button
              class="accordion-button color-header-collapse"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              disabled
            >
              <span class="color-text"> Creaci&oacute;n de Promociones </span>
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div class="row">
                <div class="mb-3 form-floating col-md-6">
                  <input
                    type="text"
                    name="promotion"
                    id="promotion"
                    formControlName="promotion"
                    class="form-control"
                    placeholder="Nombre de la promocion"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.promotion.errors,
                      'is-valid': submitted && !f.promotion.errors
                    }"
                  />
                  <label for="city" class="ms-2">Nombre de la promocion</label>
                </div>
                <div class="mb-3 form-floating col-md-6">
                  <input
                    type="text"
                    name="text"
                    id="text"
                    formControlName="text"
                    class="form-control"
                    placeholder="Mensaje de la promocionn"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.text.errors,
                      'is-valid': submitted && !f.text.errors
                    }"
                  />
                  <label for="city" class="ms-2">Mensaje de la promocion</label>
                </div>
              </div>

              <div class="row">
                <div class="mb-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="send_options"
                      value="1"
                      id="flexRadioDefault1"
                      formControlName="send_options"
                      (change)="selectOption($event)"
                    />
                    <label class="form-check-label" for="flexRadioDefault1">
                      Enviar a todos los clientes
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="send_options"
                      value="2"
                      id="flexRadioDefault2"
                      formControlName="send_options"
                      (change)="selectOption($event)"
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      Enviar por sucursal
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="send_options"
                      value="3"
                      id="flexRadioDefault3"
                      formControlName="send_options"
                      (change)="selectOption($event)"
                    />
                    <label class="form-check-label" for="flexRadioDefault3">
                      Enviar a clientes seleccionados
                    </label>
                  </div>
                </div>
                <div class="mb-3 form-floating" *ngIf="flagOffices">
                  <select
                    class="form-select"
                    aria-label="Floating label select"
                    id="offices"
                    name="offices"
                    formControlName="offices"
                  >
                    <option selected value="">Selecciona una sucursal</option>
                    <option
                      *ngFor="let office of offices"
                      value="{{ office.id }}"
                    >
                      {{ office.name }}
                    </option>
                  </select>
                  <label for="offices">Selecciona una sucursal</label>
                </div>
                <div class="form-floating mb-1" *ngIf="flagClients">
                  <input
                    type="hidden"
                    name="clientId"
                    id="clientId"
                    class="form-control"
                    formControlName="clientId"
                    required
                  />
                  <select2
                    [data]="clientsFilter"
                    displaySearchStatus="always"
                    style="width: 100%"
                    placeholder="Clientes"
                    resettable
                    multiple
                    listPosition="below"
                    styleMode="material"
                    noResultMessage="Cliente no encontrado"
                    formControlName="client_one"
                    (update)="updateClient($event)"
                    [ngClass]="{
                      'is-invalid': submitted && f.clientId.errors,
                      'is-valid': submitted && !f.clientId.errors
                    }"
                  ></select2>
                </div>
              </div>

              <div class="row">
                <div class="mb-3">
                  <label for="formFile" class="form-label">
                    Selecciona una imagen
                  </label>
                  <input
                    class="form-control"
                    type="file"
                    id="formFile"
                    formControlName="imageData"
                    (change)="onFileChange($event)"
                    accept="image/*"
                  />
                  <div
                    class="imagePreview mt-2"
                    style="margin-left: 12rem !important"
                    *ngIf="imageURL && imageURL !== ''"
                  >
                    <img [src]="imageURL" class="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3 mt-2 d-grip gap-2 d-md-flex justify-content-md-end">
        <button type="button" class="btn btn-danger" (click)="resetPromotion()">
          <i class="bi bi-eraser"></i>
          Limpiar informacion
        </button>
        <button type="submit" class="btn btn-success">
          <i class="bi bi-plus-circle"></i>
          Crear
        </button>
      </div>
    </form>
  </div>
</div>
