import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-update-office',
  templateUrl: './update-office.component.html',
  styleUrls: ['./update-office.component.scss'],
  providers: [NgbTimepickerConfig],
})
export class UpdateOfficeComponent implements OnInit {
  officeForm: any = FormGroup;
  submitted: boolean = false;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  generalMessage: string = '';
  paramId: string | null = '';
  breadcrumbs: any;
  cities: any = [];
  startOffice: { hour: any; minute: any; second: any } = {
    hour: '',
    minute: '',
    second: '',
  };
  endOffice: { hour: any; minute: any; second: any } = {
    hour: '',
    minute: '',
    second: '',
  };

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.paramId = this.route.snapshot.paramMap.get('id');
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de sucursales',
        path: '/offices/list',
        link: true,
      },
      {
        name: 'Editar sucursal',
        link: false,
      },
    ];

    this.validations();
    this.getCities();
    this.getOffice();
  }

  validations() {
    this.officeForm = this.fb.group({
      city_id: ['', [Validators.required]],
      name: ['', [Validators.required, Validators.minLength(5)]],
      address: ['', [Validators.required, Validators.minLength(5)]],
    });
  }

  get f() {
    return this.officeForm.controls;
  }

  getCities() {
    this.api.get('cities').subscribe({
      next: (cities) => {
        this.cities = cities.cities;
      },
      error: (err) => {},
    });
  }

  getOffice() {
    this.api.getById('offices', Number(this.paramId)).subscribe({
      next: (offices) => {
        const splitStart = offices.office.start.split(':');
        const splitEnd = offices.office.end.split(':');

        this.startOffice = {
          hour: parseInt(splitStart[0]),
          minute: parseInt(splitStart[1]),
          second: parseInt(splitStart[2]),
        };
        this.endOffice = {
          hour: parseInt(splitEnd[0]),
          minute: parseInt(splitEnd[1]),
          second: parseInt(splitEnd[2]),
        };

        this.officeForm.setValue({
          city_id: offices.office.city.id,
          name: offices.office.name,
          address: offices.office.address,
        });
      },
      error: (err) => {},
    });
  }

  updateOffice() {
    this.submitted = true;

    if (this.officeForm.invalid) {
      return;
    }

    const obj = {
      city_id: this.officeForm.value.city_id,
      office_name: this.officeForm.value.name,
      office_address: this.officeForm.value.address,
      start_office: `${this.startOffice.hour}:${this.startOffice.minute}:${this.startOffice.second}`,
      end_office: `${this.endOffice.hour}:${this.endOffice.minute}:${this.endOffice.second}`,
    };

    this.api.update('offices', Number(this.paramId), obj).subscribe({
      next: (office) => {
        this.submitted = false;
        this.isSaved = true;
        this.generalMessage = office.message;
      },
      error: (err) => {},
    });

    setTimeout(() => {
      this.submitted = false;
      this.isDisplayed = false;
      this.isSaved = false;
      this.generalMessage = '';
    }, 4000);
  }
}
