import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.scss'],
})
export class CreateClientComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  clientsForm: any = FormGroup;
  submitted: boolean = false;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  generalMessage: string = '';
  responseMessage: string = '';
  breadcrumbs: any = [];
  offices: any = [];
  flagDate: boolean = false;
  private _alert = new Subject<string>();
  flagErrors: boolean = false;

  constructor(private api: ApiService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this._alert.subscribe(
      (messageData: string) => (this.generalMessage = messageData)
    );
    this._alert.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
        this.isSaved = false;
        this.isDisplayed = false;
        this.submitted = false;
        this.flagErrors = false;
        this.generalMessage = '';
        this.responseMessage = '';
      }
    });

    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de clientes',
        path: '/clients/list',
        link: true,
      },
      {
        name: 'Crear cliente',
        link: false,
      },
    ];

    this.validations();
    this.getOffices();
  }

  validations(): void {
    this.clientsForm = this.fb.group({
      first_name: ['', [Validators.required, Validators.minLength(3)]],
      last_name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', 0],
      phone: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(15),
        ],
      ],
      date_of_birthday: [''],
      cp: ['', 0],
      office_id: ['', [Validators.required]],
      reference: ['', [Validators.required]],
    });
    //,[Validators.required, Validators.minLength(5), Validators.maxLength(7)],
    //,[Validators.required, Validators.minLength(5), Validators.email],
    //, [Validators.required]
  }

  getOffices(): void {
    this.api.get('offices').subscribe({
      next: (offices) => {
        this.offices = offices.offices;
      },
      error: (err) => {},
    });
  }

  get f() {
    return this.clientsForm.controls;
  }

  createClients(): void {
    this.submitted = true;
    this.flagErrors = false;

    if (
      this.clientsForm.invalid &&
      this.clientsForm.value.date_of_birthday.length != 0
    ) {
      console.log('test val: ', this.clientsForm);
      this.flagErrors = true;
      return;
    }

    let dob;
    if (this.clientsForm.value.date_of_birthday.length == 0) {
      dob = '';
    } else {
      dob = `${this.clientsForm.value.date_of_birthday.year}-${this.clientsForm.value.date_of_birthday.month}-${this.clientsForm.value.date_of_birthday.day}`;
    }
    const obj = {
      first_name: this.clientsForm.value.first_name,
      last_name: this.clientsForm.value.last_name,
      email: this.clientsForm.value.email,
      phone: this.clientsForm.value.phone,
      dob: dob,
      cp: this.clientsForm.value.cp,
      office_id: this.clientsForm.value.office_id,
      reference: this.clientsForm.value.reference,
    };

    this.api.create('clients', obj).subscribe({
      next: (client) => {
        if (client.code == 201) {
          this.submitted = false;
          this.isSaved = true;
          this.isDisplayed = false;
          this.responseMessage = client.message;
          this.resetClients();
        } else {
          this.submitted = false;
          this.isSaved = false;
          this.isDisplayed = true;
          this.responseMessage = client.message;
        }

        this._alert.next(this.responseMessage);
      },
      error: (err) => {},
    });
  }

  resetClients(): void {
    this.submitted = false;
    this.clientsForm.reset();
    this.clientsForm.controls.office_id.setValue('');

    setTimeout(() => {
      this.submitted = false;
      this.isDisplayed = false;
      this.isSaved = false;
      this.generalMessage = '';
    }, 4000);
  }
}
