<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Lista de Reportes</h3>
  </div>
  <hr />
  <ngb-alert
    class="mt-2 mb-2"
    #selfClosingAlert
    type="danger"
    *ngIf="generalMessage != '' && isDisplayed"
  >
    {{ generalMessage }}
  </ngb-alert>
  <ngb-alert
    class="mt-2 mb-2"
    #selfClosingAlert
    type="success"
    *ngIf="generalMessage != '' && isSaved"
  >
    {{ generalMessage }}
  </ngb-alert>
  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-3">
    <a class="btn btn-outline-success" (click)="openCreateReport()">
      <i class="bi bi-plus-circle"></i>
      Crear Reporte
    </a>
  </div>
  <hr />

  <ngb-accordion [closeOthers]="true" activeIds="static-0" class="mt-3">
    <ngb-panel
      *ngFor="
        let report of reportsDataFilter
          | paginate : { itemsPerPage: 15, currentPage: page };
        let i = index
      "
      id="static-{{ i }}"
      title="Reporte {{ report.type_report }} ( {{ report.start_date }} al {{
        report.end_date
      }})"
    >
      <ng-template ngbPanelContent>
        <div class="accordion-body">
          <table class="table table-striped">
            <thead class="table-dark">
              <tr>
                <td>Sucursal</td>
                <td>Direccion</td>
                <td>Ciudad</td>
                <td>Estado</td>
                <td>Opciones</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let office of report.offices">
                <td>{{ office.name }}</td>
                <td>{{ office.address }}</td>
                <td>{{ office.city.name }}</td>
                <td>{{ office.city.state.name }}</td>
                <td>
                  <div class="me-2">
                    <a
                      [routerLink]="[
                        '/weekly-sells/show',
                        office.id,
                        report.start_date,
                        report.end_date,
                        report.type
                      ]"
                      placement="top"
                      ngbTooltip="Mostrar Reporte"
                      role="button"
                      class="btn btn-primary"
                    >
                      <i class="bi bi-file-bar-graph-fill"></i>
                    </a>
                    <a
                      (click)="
                        exportExcel(
                          office.id,
                          report.start_date,
                          report.end_date,
                          report.type
                        )
                      "
                      class="btn btn-primary ms-2"
                      placement="top"
                      ngbTooltip="Descargar Excel"
                      role="button"
                    >
                      <i class="bi bi-file-earmark-excel-fill"></i>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
  <div class="col-md-12 d-flex align-items-center justify-content-center">
    <pagination-controls
      (pageChange)="page = $event"
      nextLabel="Siguiente"
      previousLabel="Anterior"
      class="mt-3"
    ></pagination-controls>
  </div>

  <ng-template #createReportModal let-close="close">
    <div class="modal-header">
      <h4 class="modal-title">Crear Reporte</h4>
      <button class="close" type="button" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <form
        [formGroup]="createReport"
        (ngSubmit)="createReportRequest()"
        novalidate
      >
        <div class="row">
          <div class="form-check ms-2">
            <input
              class="form-check-input"
              type="radio"
              name="type"
              id="type1"
              value="1"
              formControlName="type"
              [checked]="true"
            />
            <label class="form-check-label" for="type1">
              Reporte General
            </label>
          </div>
          <div class="form-check ms-2">
            <input
              class="form-check-input"
              type="radio"
              name="type"
              id="type2"
              value="2"
              formControlName="type"
            />
            <label class="form-check-label" for="type2">
              Reporte de bonos
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="input-group">
              <input
                type="text"
                name="start_date"
                class="form-control"
                id="start_date"
                formControlName="start_date"
                placeholder="Fecha de inicio de reportes"
                ngbDatepicker
                #d="ngbDatepicker"
                required
                readonly
                style="height: 3.5rem"
                [ngClass]="{
                  'is-valid': submitted && !f.start_date.errors,
                  'is-invalid': submitted && f.start_date.errors
                }"
              />
              <button
                class="btn btn-outline-secondary bi bi-calendar3"
                (click)="d.toggle()"
                type="button"
              ></button>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="input-group">
              <input
                type="text"
                name="end_date"
                class="form-control"
                id="end_date"
                formControlName="end_date"
                placeholder="Fecha de fin de reporte"
                ngbDatepicker
                #de="ngbDatepicker"
                required
                readonly
                style="height: 3.5rem"
                [ngClass]="{
                  'is-valid': submitted && !f.end_date.errors,
                  'is-invalid': submitted && f.end_date.errors
                }"
              />
              <button
                class="btn btn-outline-secondary bi bi-calendar3"
                (click)="de.toggle()"
                type="button"
              ></button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="d-grip gap-2 d-md-flex justify-content-md-end">
            <button type="submit" class="btn btn-outline-success">
              Crear Reporte
            </button>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
</div>
