import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgbAlert, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-list-areas',
  templateUrl: './list-areas.component.html',
  styleUrls: ['./list-areas.component.scss'],
})
export class ListAreasComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  areas: any = [];
  breadcrumbs: any = [];
  filterText: string = '';
  filterAreas: any = [];
  generalMessage: string = '';
  typeAlert: string = '';
  page: number = 1;
  pageNumber: number = 0;
  rolekey: string = '';
  rolename: string = '';
  roleofficeid: number = 0;

  constructor(private api: ApiService) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de bonos',
        link: false,
      },
    ];

    this.getRoles();
    this.loadAreas();
  }

  getRoles() {
    this.api.get('employees/roles').subscribe({
      next: (roles) => {
        this.rolename = roles.user.role;
        this.rolekey = roles.user.key.toLowerCase();
        this.roleofficeid = roles.user.office.id;
      },
      error: (err) => {},
    });
  }

  loadAreas() {
    this.api.getById('areas/catalog', this.roleofficeid).subscribe({
      next: (areas) => {
        this.areas = areas.areas;
        this.filterAreas = this.areas;
      },
      error: (err) => {},
    });
  }

  matches(listData: any, term: string) {}

  filterRecords(): void {
    const filterData = this.areas.filter((data: any) => {
      return this.matches(data, this.filterText);
    });

    this.filterAreas = filterData;
  }
}
