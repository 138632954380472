import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class CreateComponent implements OnInit {
  promotionsForm: any = FormGroup;
  submitted: boolean = false;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  generalMessage: string = '';
  breadcrumbs: any = [];
  status: boolean = false;
  imageURL: string = '';
  flagOffices: boolean = false;
  flagClients: boolean = false;
  offices: any = [];
  clientsFilter: any = [];
  clients: any = [];

  constructor(private api: ApiService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de promociones',
        path: '/promotions/list',
        link: true,
      },
      {
        name: 'Crear promocion',
        link: false,
      },
    ];

    this.validations();
    this.getOffices();
    this.getClients();
  }

  getOffices(): void {
    this.api.get('offices').subscribe({
      next: (offices) => {
        this.offices = offices.offices;
      },
      error: (err) => {},
    });
  }

  getClients() {
    this.api.get('clients').subscribe({
      next: (clients) => {
        this.clients = clients.clients;
        const clientsFormat = this.clients.map((client: any) => {
          return {
            value: client.id,
            label: `${client.first_name} ${client.last_name}`,
          };
        });
        this.clientsFilter = [{ options: clientsFormat }];
      },
      error: (err) => {},
    });
  }

  validations() {
    this.promotionsForm = this.fb.group({
      text: ['', [Validators.required]],
      promotion: ['', [Validators.required]],
      image: ['', [Validators.required]],
      imageData: [''],
      send_options: ['', [Validators.required]],
      clientId: [''],
      client_one: [''],
      offices: [''],
    });
  }

  get f() {
    return this.promotionsForm.controls;
  }

  onFileChange(evt: any) {
    this.status = false;
    const file = evt.target.files[0];
    this.status = evt.target.files.length > 0 ? true : false;

    if (this.status) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.promotionsForm.patchValue({
          image: reader.result,
        });
        this.imageURL = reader.result as string;
      };
    }
  }

  selectOption(evt: any): void {
    const value = Number(evt.target.value);

    if (value === 2) {
      this.flagOffices = true;
      this.flagClients = false;
    } else if (value === 3) {
      this.flagClients = true;
      this.flagOffices = false;
    } else {
      this.flagClients = false;
      this.flagOffices = false;
    }
  }

  updateClient(evt: any) {
    if (evt.value !== undefined) {
      const clientData = this.clients.filter((c: any) => c.id === evt.value);

      this.promotionsForm.patchValue({
        clientId: evt.value,
      });
    }
  }

  createPromotion() {
    this.submitted = true;

    if (this.promotionsForm.invalid) {
      this.submitted = false;
      return;
    }

    const valueData = this.validData(
      Number(this.promotionsForm.value.send_options)
    );
    const obj = {
      text: this.promotionsForm.value.text,
      promotion: this.promotionsForm.value.promotion,
      image: this.promotionsForm.value.image,
      type: this.promotionsForm.value.send_options,
      dynamicData: valueData,
    };

    this.api.create('promotions', obj).subscribe({
      next: (promotion) => {
        this.submitted = false;
        if (promotion.code == 404) {
          this.isDisplayed = true;
          this.generalMessage = promotion.message;
        } else {
          this.isSaved = true;
          this.generalMessage = promotion.message;
          this.resetPromotion();
        }
      },
      error: (err) => {},
    });
  }

  validData(type: number) {
    let returnData;
    if (type === 2) {
      returnData = this.promotionsForm.value.offices;
    } else if (type === 3) {
      returnData = this.promotionsForm.value.clientId;
    } else {
      returnData = '';
    }

    return returnData;
  }

  resetPromotion(): void {
    this.promotionsForm.reset();
    this.imageURL = '';
    this.flagClients = false;
    this.flagOffices = false;

    setTimeout(() => {
      this.submitted = false;
      this.isDisplayed = false;
      this.isSaved = false;
      this.generalMessage = '';
    }, 4000);
  }
}
