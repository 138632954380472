import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { Subject, debounceTime } from 'rxjs';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-create-bonus',
  templateUrl: './create-bonus.component.html',
  styleUrls: ['./create-bonus.component.scss'],
})
export class CreateBonusComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  bonusForm: any = FormGroup;
  submitted: boolean = false;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  generalMessage: string = '';
  breadcrumbs: any = [];
  roles: any = [];
  offices: any = [];
  private _message = new Subject<string>();

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit(): void {
    this._message.subscribe(
      (messageData: string) => (this.generalMessage = messageData)
    );
    this._message.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
        this.isDisplayed = false;
        this.isSaved = false;
        this.generalMessage = '';
        this.submitted = false;
      }
    });

    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de bonos',
        path: '/bonus/list',
        link: true,
      },
      {
        name: 'Crear bono',
        link: false,
      },
    ];

    this.validations();
    this.getRoles();
    this.getOffices();
  }

  getOffices() {
    this.api.get('offices').subscribe({
      next: (offices) => {
        this.offices = offices.offices;
      },
      error: (err) => {},
    });
  }

  getRoles(): void {
    this.api.get('roles').subscribe({
      next: (roles) => {
        this.roles = roles.roles;
      },
      error: (err) => {},
    });
  }

  validations() {
    this.bonusForm = this.fb.group({
      title: ['', [Validators.required, Validators.minLength(4)]],
      amount: ['', [Validators.required]],
      percentage: ['', [Validators.required]],
      roleId: ['', [Validators.required]],
      type: ['', [Validators.required]],
      office: ['', [Validators.required]],
      active: [''],
    });
  }

  get f() {
    return this.bonusForm.controls;
  }

  createBonus() {
    this.submitted = true;

    if (this.bonusForm.invalid) {
      return;
    }

    const obj = {
      title: this.bonusForm.value.title,
      amount: this.bonusForm.value.amount,
      percentage: this.bonusForm.value.percentage,
      roleId: this.bonusForm.value.roleId,
      type: this.bonusForm.value.type,
      office: this.bonusForm.value.office,
      active: this.bonusForm.value.active,
    };

    this.api.create('bonus', obj).subscribe({
      next: (bonus) => {
        this.submitted = false;
        this.isSaved = true;
        this.generalMessage = bonus.message;

        this._message.next(bonus.message);
        this.resetBonusForm();
      },
      error: (err) => {},
    });
  }

  resetBonusForm() {
    this.bonusForm.reset();
  }
}
