import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss'],
})
export class UpdatePasswordComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  breadcrumbs: any = [];
  passwordUpdate: any = FormGroup;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  submitted: boolean = false;
  generalMessage: string = '';
  paramId: string | null = '';
  private _messageUptPwd = new Subject<string>();

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this._messageUptPwd.subscribe(
      (messageData: string) => (this.generalMessage = messageData)
    );
    this._messageUptPwd.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
        this.isDisplayed = false;
        this.isSaved = false;
        this.submitted = false;
        this.generalMessage = '';
      }
    });

    this.paramId = this.route.snapshot.paramMap.get('id');
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de colaboradores',
        path: '/employees/list',
        link: true,
      },
      {
        name: 'Actualizar contraseña',
        link: false,
      },
    ];
    this.validations();
  }

  validations() {
    this.passwordUpdate = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      cpassword: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  get f() {
    return this.passwordUpdate.controls;
  }

  updatePassword() {
    this.submitted = true;

    if (this.passwordUpdate.invalid) {
      return;
    }

    const obj = {
      password: this.passwordUpdate.value.password,
      cpassword: this.passwordUpdate.value.cpassword,
    };

    this.api
      .update('employees/update-password', Number(this.paramId), obj)
      .subscribe({
        next: (pass) => {
          this._messageUptPwd.next(pass.message);
          if (pass.code === 404) {
            this.isDisplayed = true;
          } else {
            this.isSaved = true;
          }
        },
        error: (err) => {
          this.isDisplayed = true;
          this._messageUptPwd.next(err.message);
        },
      });
  }
}
