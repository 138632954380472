import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-update-payment-methods',
  templateUrl: './update-payment-methods.component.html',
  styleUrls: ['./update-payment-methods.component.scss'],
})
export class UpdatePaymentMethodsComponent implements OnInit {
  paymentMethod: any = FormGroup;
  submitted: boolean = false;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  generalMessage: string = '';
  paramId: string | null = '';
  breadcrumbs: any;

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de metodos de pago',
        path: '/payments-methods/list',
        link: true,
      },
      {
        name: 'Editar metodo de pago',
        link: false,
      },
    ];
    this.paramId = this.route.snapshot.paramMap.get('id');

    this.validations();
    this.loadPaymentMethodInformation();
  }

  loadPaymentMethodInformation() {
    this.api.getById('payment_methods', Number(this.paramId)).subscribe({
      next: (paymentMethod) => {
        this.paymentMethod.setValue({
          payment_method: paymentMethod.payment_methods.name,
          is_active: paymentMethod.payment_methods.is_active,
        });
      },
      error: (err) => {},
    });
  }

  validations() {
    this.paymentMethod = this.fb.group({
      payment_method: ['', [Validators.required, Validators.minLength(5)]],
      is_active: [''],
    });
  }

  get f() {
    return this.paymentMethod.controls;
  }

  updatePaymentMethod() {
    this.submitted = true;

    if (this.paymentMethod.invalid) {
      return;
    }

    const obj = {
      payment_method: this.paymentMethod.value.payment_method,
      is_active: this.paymentMethod.value.is_active,
    };

    this.api.update('payment_methods', Number(this.paramId), obj).subscribe({
      next: (payment) => {
        this.submitted = false;
        this.isSaved = true;
        this.generalMessage = payment.message;
      },
      error: (err) => {},
    });

    setTimeout(() => {
      this.submitted = false;
      this.isDisplayed = false;
      this.isSaved = false;
      this.generalMessage = '';
    }, 4000);
  }
}
