import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-list-roles',
  templateUrl: './list-roles.component.html',
  styleUrls: ['./list-roles.component.scss'],
})
export class ListRolesComponent implements OnInit {
  filterText: string = '';
  filterRoles: any = [];
  roles: any = [];
  breadcrumbs: any;
  page: number = 0;

  constructor(private api: ApiService) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de roles',
        link: false,
      },
    ];

    this.loadRoles();
  }

  loadRoles() {
    this.api.get('roles').subscribe({
      next: (roles) => {
        this.roles = roles.roles;
        this.filterRoles = this.roles;
      },
      error: (err) => {},
    });
  }

  clearSearch() {
    this.filterText = '';
    this.loadRoles();
  }

  matches(listData: any, term: string) {
    return (
      listData.role.toLowerCase().includes(term.toLowerCase()) ||
      listData.key.toLowerCase().includes(term.toLowerCase())
    );
  }

  filterRecords() {
    const filterData = this.roles.filter((data: any) => {
      return this.matches(data, this.filterText);
    });

    this.filterRoles = filterData;
  }
}
