<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Reporte de cuentas por cobrar ({{ formatDate }})</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-5">
    <a [routerLink]="['/daily-sells/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de ventas del dia
    </a>
  </div>

  <div
    class="col-md-12 alert alert-danger"
    role="alert"
    *ngIf="nextPays.length == 0"
  >
    No hay cuentas por cobrar
  </div>

  <div class="col-md-12" *ngIf="nextPays.length > 0">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Colaborador</th>
          <th>Numero de sesi&oacute;n</th>
          <th>Fecha de sesi&oacute;n</th>
          <th>Paquete</th>
          <th>Total de sesiones</th>
          <th>Total a pagar</th>
        </tr>
      </thead>
      <tbody *ngFor="let report of nextPays">
        <tr>
          <td>{{ report.user_id }}</td>
          <td>{{ report.number_session }}</td>
          <td>{{ report.date_start }}</td>
          <td>{{ report.package }}</td>
          <td>{{ report.total_sessions }}</td>
          <td>{{ report.payment_session | currency }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
