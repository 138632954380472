import { Component } from '@angular/core';

@Component({
  selector: 'app-show-nextday-payments',
  templateUrl: './show-nextday-payments.component.html',
  styleUrls: ['./show-nextday-payments.component.scss']
})
export class ShowNextdayPaymentsComponent {

}
