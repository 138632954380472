import { Component } from '@angular/core';

@Component({
  selector: 'app-update-areas',
  templateUrl: './update-areas.component.html',
  styleUrls: ['./update-areas.component.scss']
})
export class UpdateAreasComponent {

}
