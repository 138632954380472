import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-list-products',
  templateUrl: './list-products.component.html',
  styleUrls: ['./list-products.component.scss'],
})
export class ListProductsComponent implements OnInit {
  breadcrumbs: any = [];
  filterText: string = '';
  products: any = [];
  filterProducts: any = [];
  page: number = 1;

  constructor(private api: ApiService) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de insumos en ventas',
        link: false,
      },
    ];

    this.loadProducts();
  }

  loadProducts(): void {
    this.api.get('products').subscribe({
      next: (products) => {
        this.products = products.products;
        this.filterProducts = this.products;
      },
      error: (err) => {},
    });
  }

  clearSearch() {
    this.filterText = '';
    this.loadProducts();
  }

  matches(listData: any, term: string) {
    return (
      listData.name.toLowerCase().includes(term.toLowerCase()) ||
      listData.price.includes(term)
    );
  }

  filterRecords() {
    const filterData = this.products.filter((data: any) => {
      return this.matches(data, this.filterText);
    });

    this.filterProducts = filterData;
  }
}
