import { Component } from '@angular/core';

@Component({
  selector: 'app-black-debts',
  templateUrl: './black-debts.component.html',
  styleUrls: ['./black-debts.component.scss']
})
export class BlackDebtsComponent {

}
