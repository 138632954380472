import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Subject, debounceTime } from 'rxjs';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-create-commissions',
  templateUrl: './create-commissions.component.html',
  styleUrls: ['./create-commissions.component.scss'],
})
export class CreateCommissionsComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  comisionForm: any = FormGroup;
  submitted: boolean = false;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  generalMessage: string = '';
  breadcrumbs: any = [];
  roles: any = [];
  offices: any = [];
  private _message = new Subject<string>();

  constructor(private api: ApiService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this._message.subscribe(
      (messageData: string) => (this.generalMessage = messageData)
    );
    this._message.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
        this.isDisplayed = false;
        this.isSaved = false;
        this.generalMessage = '';
        this.submitted = false;
      }
    });

    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de comisiones',
        path: '/commissions/list',
        link: true,
      },
      {
        name: 'Crear comisi&oacute;n',
        link: false,
      },
    ];

    this.validations();
    this.getRoles();
    this.getOffices();
  }

  getRoles(): void {
    this.api.get('roles').subscribe({
      next: (roles) => {
        this.roles = roles.roles;
      },
      error: (err) => {},
    });
  }

  getOffices() {
    this.api.get('offices').subscribe({
      next: (offices) => {
        this.offices = offices.offices;
      },
      error: (err) => {},
    });
  }

  validations() {
    this.comisionForm = this.fb.group({
      title: ['', [Validators.required, Validators.minLength(4)]],
      office: ['', [Validators.required]],
      type: ['', [Validators.required]],
      percentage: ['', [Validators.required]],
      roleId: ['', [Validators.required]],
      active: [''],
    });
  }

  get f() {
    return this.comisionForm.controls;
  }

  createCommission() {
    this.submitted = true;

    if (this.comisionForm.invalid) {
      return;
    }

    const obj = {
      title: this.comisionForm.value.title,
      office: this.comisionForm.value.office,
      type: this.comisionForm.value.type,
      percentage: this.comisionForm.value.percentage,
      roleId: this.comisionForm.value.roleId,
      active: this.comisionForm.value.active,
    };

    this.api.create('commissions', obj).subscribe({
      next: (bonus) => {
        this.submitted = false;
        this.isSaved = true;
        this.generalMessage = bonus.message;

        this._message.next(bonus.message);
        this.resetBonusForm();
      },
      error: (err) => {},
    });
  }

  resetBonusForm() {
    this.comisionForm.reset();
  }
}
