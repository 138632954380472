import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-list-black-debts',
  templateUrl: './list-black-debts.component.html',
  styleUrls: ['./list-black-debts.component.scss'],
})
export class ListBlackDebtsComponent implements OnInit {
  @ViewChild('disabledClient', { static: false })
  disabledClient!: TemplateRef<any>;
  closeModal: any;
  deletedForms: any = FormGroup;
  filterText: any = '';
  blackdebts: any = [];
  filterBlackDebts: any = [];
  office: number = 0;
  offices: any = [];
  rolename: any = '';
  rolekey: any = '';
  breadcrumbs: any = '';
  page: number = 1;
  roleofficeid: number = 0;
  currentOfficeId: number = 0;
  clientId: number = 0;
  clientName: string = '';
  apptId: number = 0;
  message: string = '';
  flagDeleted: boolean = false;

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private modal: NgbModal
  ) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de Negritas',
        link: false,
      },
    ];

    this.loadOffices();
    this.roleInfo();
    this.loadBlacks();
    this.validations();
  }

  validations() {
    this.deletedForms = this.fb.group({
      comments: [''],
      apptId: [''],
    });
  }

  loadOffices() {
    this.api.get('offices').subscribe({
      next: (office) => {
        this.offices = office.offices;
      },
      error: (err) => {},
    });
  }

  roleInfo() {
    this.api.get('employees/roles').subscribe({
      next: (roles) => {
        this.rolename = roles.user.role;
        this.rolekey = roles.user.key.toLowerCase();
        this.roleofficeid = roles.user.office.id;
        this.currentOfficeId = this.roleofficeid;
      },
      error: (err) => {},
    });
  }

  loadBlacks(): void {
    this.api.get('black-debts').subscribe({
      next: (blacks) => {
        this.blackdebts = blacks.blackDebts;
        this.filterBlackDebts = this.blackdebts;
      },
      error: (err) => {},
    });
  }

  setCurrentOffice(evt: any) {
    this.api.get(`black-debts/${evt.target.value}`).subscribe({
      next: (blacks) => {
        this.blackdebts = blacks.blackDebts;
        this.filterBlackDebts = this.blackdebts;
      },
      error: (err) => {},
    });
  }

  clearSearch() {
    this.filterText = '';
    this.currentOfficeId = this.roleofficeid;
    this.loadBlacks();
  }

  matches(listData: any, term: string) {
    return (
      listData.reason.toLowerCase().includes(term.toLowerCase()) ||
      listData.client.toLowerCase().includes(term.toLowerCase())
    );
  }

  filterRecords() {
    let filterData;
    if (this.office == 0) {
      filterData = this.blackdebts.filter((data: any) => {
        return this.matches(data, this.filterText);
      });
    } else {
      filterData = this.blackdebts.filter((data: any) => {
        if (data.office_id == this.office) {
          return this.matches(data, this.filterText);
        }
      });
    }

    this.filterBlackDebts = filterData;
  }

  close(): void {
    this.closeModal.close();
  }

  showTemplateDisabled(id: number, name: string, apptId: number) {
    this.clientId = id;
    this.clientName = name;
    console.log(apptId);
    this.deletedForms.patchValue({
      apptId: apptId,
    });

    this.closeModal = this.modal.open(this.disabledClient, {
      size: 'md',
    });
  }

  deleteClients() {
    const obj = {
      comments: this.deletedForms.value.comments,
      apptId: this.deletedForms.value.apptId,
    };

    console.log(obj);

    this.api.update('black-debts/disabled', this.clientId, obj).subscribe({
      next: (data) => {
        this.message = data.message;
        this.flagDeleted = true;

        this.closeModal.close();

        setTimeout(() => {
          this.message = '';
          this.flagDeleted = false;
          this.deletedForms.patchValue({
            comments: '',
          });
        }, 2500);
      },
      error: (err) => {},
    });
  }
}
