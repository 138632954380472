import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-list-cabins',
  templateUrl: './list-cabins.component.html',
  styleUrls: ['./list-cabins.component.scss'],
})
export class ListCabinsComponent implements OnInit {
  @ViewChild('modalCabin', { static: false }) modalCabin!: TemplateRef<any>;
  modalClose: any;
  breadcrumbs: any = [];
  filterText: string = '';
  cabins: any = [];
  cabinsFilter: any = [];
  offices: any = [];
  labelModal: string = '';
  message: string = '';
  idDisabled: number = 0;
  messageDisEn: string = '';
  nameCabin: string = '';
  disabledFlag: boolean = false;
  page: number = 1;
  rolename: string = '';
  rolekey: string = '';
  roleofficeid: number = 0;
  officeId: number = 0;
  currentOfficeId: number = 0;

  constructor(private api: ApiService, private modal: NgbModal) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de cabinas',
        link: false,
      },
    ];

    this.getCabins();
    this.getRoles();
    this.getOffices();
  }

  getRoles() {
    this.api.get('employees/roles').subscribe({
      next: (roles) => {
        this.rolename = roles.user.role;
        this.rolekey = roles.user.key.toLowerCase();
        this.roleofficeid = roles.user.office.id;
        this.currentOfficeId = this.roleofficeid;
      },
      error: (err) => {},
    });
  }

  getCabins() {
    this.api.get('cabin-all').subscribe({
      next: (cabinData) => {
        this.cabins = cabinData.cabins;
        this.cabinsFilter = this.cabins;
      },
      error: (err) => {},
    });
  }

  getOffices() {
    this.api.get('offices').subscribe({
      next: (offices) => {
        this.offices = offices.offices;
      },
      error: (err) => {},
    });
  }

  setCurrentOffice(evt: any) {
    this.api.get(`cabin-all/${evt.target.value}`).subscribe({
      next: (cabinData) => {
        this.cabins = cabinData.cabins;
        this.cabinsFilter = this.cabins;
      },
      error: (err) => {},
    });
  }

  disabledEnabledRecord(id: any) {
    this.idDisabled = id;
    const cabinSelected = this.cabins.filter((data: any) => data.id === id);
    this.messageDisEn = cabinSelected[0].enabled ? 'Desactivar' : 'Activar';
    this.nameCabin = cabinSelected[0].name;
    this.modalClose = this.modal.open(this.modalCabin, { size: 'md' });
  }

  disabledEnabledFunction(id: any) {
    const cabinSelected = this.cabins.filter((data: any) => data.id === id);
    const numberFlag = cabinSelected[0].enabled ? 0 : 1;
    const cabinOfficeId = cabinSelected[0].office_id;
    const params = `${numberFlag}/${id}`;
    this.api.getById('cabin/status', params).subscribe({
      next: (cabin) => {
        if (cabinOfficeId === this.roleofficeid) {
          if (numberFlag == 1) {
            this.api.setTotalCabins(this.api.getTotalCabinsPerOffice + 1);
          } else {
            this.api.setTotalCabins(this.api.getTotalCabinsPerOffice - 1);
          }
        }

        this.message = cabin.message;
        this.disabledFlag = true;
        this.close();
        this.getCabins();

        setTimeout(() => {
          this.disabledFlag = false;
          this.message = '';
        }, 4000);
      },
      error: (err) => {},
    });
  }

  close() {
    this.modalClose.close();
  }

  matches(listData: any, term: string) {
    return (
      listData.name.toLowerCase().includes(term.toLowerCase()) ||
      listData.office.toLowerCase().includes(term.toLowerCase()) ||
      listData.employee.toLowerCase().includes(term.toLowerCase())
    );
  }

  clearSearch() {
    this.filterText = '';
    this.currentOfficeId = this.roleofficeid;
    this.getCabins();
  }

  filterRecords() {
    const filterData = this.cabins.filter((data: any) => {
      return this.matches(data, this.filterText);
    });

    this.cabinsFilter = filterData;
  }
}
