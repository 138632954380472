import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-update-bonus',
  templateUrl: './update-bonus.component.html',
  styleUrls: ['./update-bonus.component.scss'],
})
export class UpdateBonusComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  bonusForm: any = FormGroup;
  submitted: boolean = false;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  generalMessage: string = '';
  breadcrumbs: any;
  paramId: string | null = '';
  roles: any = [];
  offices: any = [];
  checkedRadio: number = 0;
  private _message = new Subject<string>();

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this._message.subscribe(
      (messageData: string) => (this.generalMessage = messageData)
    );
    this._message.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
        this.submitted = false;
        this.isDisplayed = false;
        this.isSaved = false;
        this.generalMessage = '';
      }
    });

    this.paramId = this.route.snapshot.paramMap.get('id');
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de  bonos',
        path: '/bonus/list',
        link: true,
      },
      {
        name: 'Editar bono',
        link: false,
      },
    ];

    this.validations();
    this.getBonus();
    this.getRoles();
    this.getOffices();
  }

  getOffices(): void {
    this.api.get('offices').subscribe({
      next: (offices) => {
        this.offices = offices.offices;
      },
      error: (err) => {},
    });
  }

  getRoles(): void {
    this.api.get('roles').subscribe({
      next: (roles) => {
        this.roles = roles.roles;
      },
      error: (err) => {},
    });
  }

  getBonus() {
    this.api.getById('bonus', Number(this.paramId)).subscribe({
      next: (bonus) => {
        this.checkedRadio = bonus.bonus.type;
        this.bonusForm.patchValue({
          title: bonus.bonus.title,
          amount: bonus.bonus.amount,
          percentage: bonus.bonus.percentage,
          roleId: bonus.bonus.role,
          type: bonus.bonus.type,
          office: bonus.bonus.office,
          active: bonus.bonus.active_flag,
        });
      },
      error: (err) => {},
    });
  }

  validations() {
    this.bonusForm = this.fb.group({
      title: ['', [Validators.required, Validators.minLength(4)]],
      amount: ['', [Validators.required]],
      percentage: ['', [Validators.required]],
      roleId: ['', [Validators.required]],
      type: ['', [Validators.required]],
      office: ['', [Validators.required]],
      active: [''],
    });
  }

  get f() {
    return this.bonusForm.controls;
  }

  updateBonus() {
    this.submitted = true;

    if (this.bonusForm.invalid) {
      return;
    }

    const obj = {
      title: this.bonusForm.value.title,
      amount: this.bonusForm.value.amount,
      percentage: this.bonusForm.value.percentage,
      roleId: this.bonusForm.value.roleId,
      type: this.bonusForm.value.type,
      office: this.bonusForm.value.office,
      active: this.bonusForm.value.active,
    };

    this.api.update('bonus', Number(this.paramId), obj).subscribe({
      next: (bonus) => {
        this.submitted = false;
        this.isSaved = true;
        this.isDisplayed = false;
        this._message.next(bonus.message);
      },
      error: (err) => {},
    });
  }
}
