import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-list-cities',
  templateUrl: './list-cities.component.html',
  styleUrls: ['./list-cities.component.scss'],
})
export class ListCitiesComponent implements OnInit {
  filterText: any = '';
  cities: any = [];
  filterCities: any = [];
  breadcrumbs: any;
  page: number = 1;

  constructor(private api: ApiService) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de ciudades',
        link: false,
      },
    ];

    this.getCities();
  }

  getCities() {
    this.api.get('cities').subscribe({
      next: (cities) => {
        this.cities = cities.cities;
        this.filterCities = this.cities;
      },
      error: (err) => {},
    });
  }

  clearSearch() {
    this.filterText = '';
    this.getCities();
  }

  matches(listData: any, term: string) {
    return (
      listData.city_name.toLowerCase().includes(term.toLowerCase()) ||
      listData.country_code.toLowerCase().includes(term.toLowerCase()) ||
      listData.state_name.toLowerCase().includes(term.toLowerCase())
    );
  }

  filterRecords() {
    const filterData = this.cities.filter((data: any) => {
      return this.matches(data, this.filterText);
    });

    this.filterCities = filterData;
  }
}
