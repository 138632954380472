import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-create-areas',
  templateUrl: './create-areas.component.html',
  styleUrls: ['./create-areas.component.scss'],
})
export class CreateAreasComponent implements OnInit {
  generalMessage: string = '';
  submitted: boolean = false;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  areasForm: any = FormGroup;
  breadcrumbs: any = [];
  offices: any = [];
  cities: any = [];

  constructor(private api: ApiService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de Areas',
        path: '/areas/list',
        link: true,
      },
      {
        name: 'Crear area',
        link: false,
      },
    ];

    this.validations();
    //this.getOffices();
    //this.getCities();
  }

  validations(): void {
    this.areasForm = this.fb.group({
      area: ['', [Validators.required]],
      duration: ['', [Validators.required]],
      duration_sin: ['', [Validators.required]],
      duration_tj: ['', [Validators.required]],
      type: ['', [Validators.required]],
      price: ['', [Validators.required]],
      price_sin: ['', [Validators.required]],
      price_tj: ['', [Validators.required]],
      fullBody: [''],
      //city: ['', [Validators.required]],
    });
  }

  getOffices() {
    this.api.get('offices').subscribe({
      next: (offices) => {
        this.offices = offices.offices;
      },
      error: (err) => {},
    });
  }

  getCities() {
    this.api.get('cities').subscribe({
      next: (cities) => {
        this.cities = cities.cities;
      },
      error: (err) => {},
    });
  }

  get f() {
    return this.areasForm.controls;
  }

  createArea() {
    this.submitted = true;
    this.generalMessage = '';

    if (this.areasForm.invalid) {
      return;
    }

    const obj = {
      area: this.areasForm.value.area,
      duration: this.areasForm.value.duration,
      duration_sin: this.areasForm.value.duration_sin,
      duration_tj: this.areasForm.value.duration_tj,
      type: this.areasForm.value.type,
      price: this.areasForm.value.price,
      price_sin: this.areasForm.value.price_sin,
      price_tj: this.areasForm.value.price_tj,
      fullBody: this.areasForm.value.fullBody,
    };

    this.api.create('areas/catalog/web', obj).subscribe({
      next: (areas) => {
        this.generalMessage = areas.message;
        this.isSaved = true;
        this.resetAreaForm();
      },
      error: (err) => {
        this.generalMessage = err.message;
        this.submitted = false;
        this.isDisplayed = true;
      },
    });
  }

  resetAreaForm(): void {
    this.areasForm.reset();
    this.areasForm.get('type').setValue('');

    setTimeout(() => {
      this.generalMessage = '';
      this.submitted = false;
      this.isDisplayed = false;
      this.isSaved = false;
    }, 4000);
  }
}
