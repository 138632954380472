<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Crear ciudades</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-5">
    <a [routerLink]="['/cities/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de ciudades
    </a>
  </div>

  <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
    <div *ngIf="isDisplayed" class="alert alert-danger" role="alert">
      {{ generalMessage }}
    </div>
    <div *ngIf="isSaved" class="alert alert-success" role="alert">
      {{ generalMessage }}
    </div>

    <form [formGroup]="citiesForm" (ngSubmit)="createCity()" novalidate>
      <div class="accordion" id="cities_accordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="cities-information">
            <button
              class="accordion-button color-header-collapse"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              disabled
            >
              <span class="color-text"> Creaci&oacute;n de Ciudades </span>
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-6 mb-3 form-floating">
                  <input
                    type="text"
                    name="city"
                    id="city"
                    formControlName="city"
                    class="form-control"
                    placeholder="Nombre de la Ciudad"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.city.errors,
                      'is-valid': submitted && !f.city.errors
                    }"
                  />
                  <label class="ms-2" for="city">Nombre de la ciudad</label>
                </div>
                <div class="col-md-6 mb-3 form-floating">
                  <select
                    class="form-select"
                    aria-label="Floating label select"
                    id="state_id"
                    name="state_id"
                    formControlName="state_id"
                  >
                    <option selected value="" class="">
                      Selecciona un estado
                    </option>
                    <option *ngFor="let state of states" value="{{ state.id }}">
                      {{ state.state }}
                    </option>
                  </select>
                  <label class="ms-2" for="select_state">
                    Selecciona un estado
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 mt-2 d-grip gap-2 d-md-flex justify-content-md-end">
        <button type="button" class="btn btn-danger" (click)="resetCityForm()">
          <i class="bi bi-eraser"></i>
          Limpiar Informaci&oacute;n
        </button>
        <button type="submit" class="btn btn-success">
          <i class="bi bi-plus-circle"></i>
          Crear
        </button>
      </div>
    </form>
  </div>
</div>
