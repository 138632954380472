<div class="ms-1 mb-4">
  <nav aria-label="breadcrumbs">
    <ol class="breadcrumb">
      <li *ngFor="let element of breadcrumbs" class="breadcrumb-item">
        <a href="{{ element.path }}" class="text-color-decoration">
          {{ element.name }}
        </a>
      </li>
      <li
        class="breadcrumb-item active text-color-single-text"
        aria-current="page"
      >
        {{ currentPage.name }}
      </li>
    </ol>
  </nav>
</div>
