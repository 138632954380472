import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-create-roles',
  templateUrl: './create-roles.component.html',
  styleUrls: ['./create-roles.component.scss'],
})
export class CreateRolesComponent implements OnInit {
  rolesForm: any = FormGroup;
  submitted: boolean = false;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  generalMessage: string = '';
  breadcrumbs: any;

  constructor(private api: ApiService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de roles',
        path: '/roles/list',
        link: true,
      },
      {
        name: 'Crear rol',
        link: false,
      },
    ];

    this.validators();
  }

  validators() {
    this.rolesForm = this.fb.group({
      role: ['', [Validators.required, Validators.minLength(2)]],
      role_key: ['', [Validators.required, Validators.minLength(2)]],
    });
  }

  get f() {
    return this.rolesForm.controls;
  }

  createRoles() {
    this.submitted = true;

    if (this.rolesForm.invalid) {
      return;
    }

    const obj = {
      role: this.rolesForm.value.role,
      role_key: this.rolesForm.value.role_key,
    };

    this.api.create('roles', obj).subscribe({
      next: (roles) => {
        this.submitted = false;
        this.isSaved = true;
        this.generalMessage = roles.message;

        this.resetRolesForm();
      },
      error: (err) => {},
    });
  }

  resetRolesForm() {
    this.rolesForm.reset();

    setTimeout(() => {
      this.submitted = false;
      this.isDisplayed = false;
      this.isSaved = false;
      this.generalMessage = '';
    }, 4000);
  }
}
