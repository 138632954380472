import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject, debounceTime } from 'rxjs';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-show-black-debts',
  templateUrl: './show-black-debts.component.html',
  styleUrls: ['./show-black-debts.component.scss'],
})
export class ShowBlackDebtsComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  breadcrumbs: any = [];
  paramId: string | null = '';
  paramApptId: string | null = '';
  blackData: any = [];
  blackHistorical: any = [];
  blackCommentsData: any = [];
  blackComments: any = FormGroup;
  submitted: boolean = false;
  generalMessage: string = '';
  isSaved: boolean = false;
  private _alert = new Subject<string>();

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this._alert.subscribe((message: string) => (this.generalMessage = message));
    this._alert.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
        this.submitted = false;
        this.isSaved = false;
        this.generalMessage = '';
      }
    });

    this.paramId = this.route.snapshot.paramMap.get('id');
    this.paramApptId = this.route.snapshot.paramMap.get('apptId');
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de negritas',
        path: '/black-debts/list',
        link: true,
      },
      {
        name: 'Informacion',
        link: false,
      },
    ];

    this.validations();
    this.getInformation();
    this.getBlacksComments();
  }

  validations() {
    this.blackComments = this.fb.group({
      client_id: ['', [Validators.required]],
      appt_id: ['', [Validators.required]],
      comment: ['', [Validators.required, Validators.minLength(5)]],
      blackDebtsId: ['', [Validators.required]],
    });
  }

  getInformation() {
    const params = `${Number(this.paramId)}/${Number(this.paramApptId)}`;
    this.api.getById('black-debts', params).subscribe({
      next: (blacks) => {
        this.blackData = blacks.black;
        this.blackData.map(
          (data: any) => (this.blackHistorical = [...data.historical])
        );

        this.blackComments.patchValue({
          client_id: blacks.black[0].client.id,
          appt_id: blacks.black[0].appoinment.id,
          blackDebtsId: blacks.black[0].id,
        });
      },
      error: (err) => {},
    });
  }

  getBlacksComments() {
    const params = `${Number(this.paramId)}/${Number(this.paramApptId)}`;
    this.api.getById('black-debts-comments', params).subscribe({
      next: (comments) => {
        this.blackCommentsData = comments.comments;
      },
      error: (err) => {},
    });
  }

  get f() {
    return this.blackComments.controls;
  }

  createComment() {
    this.submitted = true;

    if (this.blackComments.invalid) {
      console.log(this.blackComments);
      return;
    }

    const obj = {
      clientId: this.blackComments.value.client_id,
      apptId: this.blackComments.value.appt_id,
      comments: this.blackComments.value.comment,
      blackDebtsId: this.blackComments.value.blackDebtsId,
    };

    this.api.create('black-debts-comments', obj).subscribe({
      next: (comments) => {
        this.submitted = false;
        this.isSaved = true;
        this._alert.next(comments.message);
        this.getBlacksComments();
      },
      error: (err) => {},
    });
  }
}
