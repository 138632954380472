import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-advance-payments',
  templateUrl: './advance-payments.component.html',
  styleUrls: ['./advance-payments.component.scss'],
})
export class AdvancePaymentsComponent implements OnInit {
  breadcrumbs: any;
  packageId: string | null = '';
  advanceForm: any = FormGroup;
  submitted: boolean = false;
  paymentsFilter: any = [];
  payments: any;
  payment_methods: any;
  generalMessage: string = '';

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.packageId = this.route.snapshot.paramMap.get('id');
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de paquetes',
        path: '/packages/list',
        link: true,
      },
      {
        name: 'Registrar pago adelantado',
        link: false,
      },
    ];

    this.getInfo();
    this.validations();
    this.getPaymentMethods();
    this.getPayments();
  }

  getInfo() {
    this.api.getById('packages/single', Number(this.packageId)).subscribe({
      next: (infoPackage) => {
        this.advanceForm.patchValue({
          name: infoPackage.package.client_name,
          areas: infoPackage.package.areas,
          subtotal: infoPackage.package.subtotal,
          discount: infoPackage.package.percentage,
          sessionId: infoPackage.package.currentNumberSession,
          apptId: infoPackage.package.apptId,
        });
      },
      error: (err) => {},
    });
  }

  getPayments() {
    this.api.get('payments').subscribe({
      next: (payments) => {
        this.payments = payments.payments;
      },
      error: (err) => {},
    });
  }

  getPaymentMethods() {
    this.api.get('payment_methods').subscribe({
      next: (paymentMethods) => {
        this.payment_methods = paymentMethods.payment_methods;
      },
      error: (err) => {},
    });
  }

  validations() {
    this.advanceForm = this.fb.group({
      name: [''],
      areas: [''],
      subtotal: [''],
      discount: [''],
      amount: ['', [Validators.required]],
      paymentMethodId: ['', [Validators.required]],
      terminal: [''],
      paymentId: ['0', [Validators.required]],
      sessionId: ['', [Validators.required]],
      apptId: ['', [Validators.required]],
    });
  }

  checkPaymentMethods(evt: any): void {
    this.paymentsFilter = this.payments.filter(
      (data: any) => data.payment_method_id === Number(evt.target.value)
    );
  }

  get f() {
    return this.advanceForm.controls;
  }

  addAdvancePayments() {
    this.submitted = true;

    if (this.advanceForm.invalid) {
      console.log('holas:', this.advanceForm);
      this.submitted = false;
      return;
    }

    const obj = {
      amount: this.advanceForm.value.amount,
      paymentMethodId: this.advanceForm.value.paymentMethodId,
      terminal: this.advanceForm.value.terminal,
      paymentId: this.advanceForm.value.paymentId,
      sessionId: this.advanceForm.value.sessionId,
      apptId: this.advanceForm.value.apptId,
    };

    this.api.create('packages/advance', obj).subscribe({
      next: (advance) => {
        this.generalMessage = advance.message;

        setTimeout(() => {
          this.generalMessage = '';
          this.submitted = false;
          this.resetAdvancePayments();
        }, 3000);
      },
      error: (err) => {},
    });
  }

  resetAdvancePayments() {
    this.advanceForm.get('amount').setValue('');
    this.advanceForm.get('paymentMethodId').setValue('');
    this.advanceForm.get('paymentId').setValue(0);
    this.advanceForm.get('terminal').setValue('');
    this.paymentsFilter = [];
  }
}
