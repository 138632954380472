<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Editar periodo de pago</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-5">
    <a [routerLink]="['/cycles/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de periodos de pagos
    </a>
  </div>

  <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
    <ngb-alert
      #selfClosingAlert
      type="danger"
      *ngIf="generalMessage != '' && isDisplayed"
    >
      {{ generalMessage }}
    </ngb-alert>
    <ngb-alert
      #selfClosingAlert
      type="success"
      *ngIf="generalMessage != '' && isSaved"
    >
      {{ generalMessage }}
    </ngb-alert>

    <form [formGroup]="cyclesForm" (ngSubmit)="updateCycle()" novalidate>
      <div class="accordion" id="cities_accordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="cities-information">
            <button
              class="accordion-button color-header-collapse"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              disabled
            >
              <span class="color-text">
                Creaci&oacute;n de Peridos de Pagos
              </span>
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div class="row">
                <div class="mb-3 form-floating col-md-6">
                  <input
                    type="text"
                    name="cycle"
                    id="cycle"
                    formControlName="cycle"
                    class="form-control"
                    placeholder="Nombre del ciclo"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.cycle.errors,
                      'is-valid': submitted && !f.cycle.errors
                    }"
                  />
                  <label for="cycle" class="ms-2">
                    Nombre del periodo de pago
                  </label>
                </div>
                <div class="mb-3 form-floating col-md-6">
                  <input
                    type="text"
                    name="year"
                    id="year"
                    formControlName="year"
                    class="form-control"
                    placeholder="A&ntilde;o del ciclo"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.year.errors,
                      'is-valid': submitted && !f.year.errors
                    }"
                  />
                  <label for="cycle" class="ms-2">
                    A&ntilde;o del periodo de pago
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="input-group">
                    <input
                      type="text"
                      name="dateSession"
                      class="form-control"
                      id="date_start"
                      formControlName="date_start"
                      placeholder="Fecha de inicio"
                      ngbDatepicker
                      #ds="ngbDatepicker"
                      required
                      readonly
                      style="height: 3.5rem"
                      [ngClass]="{
                        'is-valid': submitted && !f.date_start.errors,
                        'is-invalid': submitted && f.date_start.errors
                      }"
                    />
                    <button
                      class="btn btn-outline-secondary bi bi-calendar3"
                      (click)="ds.toggle()"
                      type="button"
                    ></button>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="input-group">
                    <input
                      type="text"
                      name="date_end"
                      class="form-control"
                      id="date_end"
                      formControlName="date_end"
                      placeholder="Fecha fin"
                      ngbDatepicker
                      #de="ngbDatepicker"
                      required
                      readonly
                      style="height: 3.5rem"
                      [ngClass]="{
                        'is-valid': submitted && !f.date_end.errors,
                        'is-invalid': submitted && f.date_end.errors
                      }"
                    />
                    <button
                      class="btn btn-outline-secondary bi bi-calendar3"
                      (click)="de.toggle()"
                      type="button"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 d-grip gap-2 d-md-flex justify-content-md-end">
        <button type="submit" class="btn btn-success">
          <i class="bi bi-plus-circle"></i>
          Actualizar
        </button>
      </div>
    </form>
  </div>
</div>
