import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  breadcrumbs: any = [];
  information: any = [];
  page: number = 1;

  constructor(private api: ApiService) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Caja chica',
        link: false,
      },
    ];

    this.getInformation();
  }

  getInformation(): void {
    this.api.get('small_box_payments').subscribe({
      next: (data) => {
        this.information = data.information;
      },
      error: (err) => {},
    });
  }
}
