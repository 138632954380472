<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Caja chica</h3>
  </div>
  <hr />

  <table class="table table-striped">
    <thead>
      <tr>
        <td>ID</td>
        <td>Fecha</td>
        <td>Acciones</td>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="
          let info of information
            | paginate : { itemsPerPage: 15, currentPage: page }
        "
      >
        <td>{{ info.id }}</td>
        <td>{{ info.filter }}</td>
        <td>
          <div class="me-2">
            <a
              [routerLink]="[
                '/small-box/create',
                info.office_id,
                info.filterDate
              ]"
              placement="top"
              ngbTooltip="Mostrar Información"
              role="button"
              class="btn btn-primary"
            >
              <i class="bi bi-eye-fill"></i>
            </a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="col-md-12 d-flex align-items-center justify-content-center">
    <pagination-controls
      (pageChange)="page = $event"
      nextLabel="Siguiente"
      previousLabel="Anterior"
      class="mt-3"
    ></pagination-controls>
  </div>
</div>
