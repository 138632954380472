import { Component } from '@angular/core';

@Component({
  selector: 'app-daily-payments',
  templateUrl: './daily-payments.component.html',
  styleUrls: ['./daily-payments.component.scss']
})
export class DailyPaymentsComponent {

}
