<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Editar Colaborador</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-5">
    <a [routerLink]="['/employees/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de colaboradores
    </a>
  </div>

  <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
    <div *ngIf="isDisplayed" class="alert alert-danger" role="alert">
      {{ generalMessage }}
    </div>
    <div *ngIf="isSaved" class="alert alert-success" role="alert">
      {{ generalMessage }}
    </div>

    <form [formGroup]="employeeForm" (ngSubmit)="updateEmployee()" novalidate>
      <div class="accordion" id="cities_accordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="cities-information">
            <button
              class="accordion-button color-header-collapse"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              disabled
            >
              <span class="color-text">
                Actualizaci&oacute;n de Colaboradores
              </span>
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div class="row">
                <div class="mb-3 form-floating col-md-6">
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    formControlName="firstName"
                    class="form-control"
                    placeholder="Nombre"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.firstName.errors,
                      'is-valid': submitted && !f.firstName.errors
                    }"
                  />
                  <label for="firstName" class="ms-2">Nombre</label>
                </div>
                <div class="mb-3 form-floating col-md-6">
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    formControlName="lastName"
                    class="form-control"
                    placeholder="Apellidos"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.lastName.errors,
                      'is-valid': submitted && !f.lastName.errors
                    }"
                  />
                  <label class="ms-2" for="lastName">Apellidos</label>
                </div>
              </div>

              <div class="row">
                <div class="mb-3 form-floating col-md-6">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    formControlName="email"
                    class="form-control"
                    placeholder="Correo electr&oacute;nico"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.email.errors,
                      'is-valid': submitted && !f.email.errors
                    }"
                  />
                  <label for="last_name" class="ms-2"
                    >Correo electr&oacute;nico</label
                  >
                </div>
                <div class="mb-3 form-floating col-md-6">
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    formControlName="phone"
                    class="form-control"
                    placeholder="Tel&eacute;fono"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.phone.errors,
                      'is-valid': submitted && !f.phone.errors
                    }"
                  />
                  <label for="phone" class="ms-2">Tel&eacute;fono</label>
                </div>
              </div>

              <div class="row">
                <div class="mb-3 form-floating col-md-6">
                  <input
                    type="text"
                    name="address"
                    id="address"
                    formControlName="address"
                    class="form-control"
                    placeholder="Direcci&oacute;n"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.address.errors,
                      'is-valid': submitted && !f.address.errors
                    }"
                  />
                  <label for="address" class="ms-2">Direccio&oacute;n</label>
                </div>
                <div class="mb-3 form-floating col-md-6">
                  <input
                    type="text"
                    name="salaryBase"
                    id="salaryBase"
                    formControlName="salaryBase"
                    class="form-control"
                    placeholder="Salario Base"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.salaryBase.errors,
                      'is-valid': submitted && !f.salaryBase.errors
                    }"
                  />
                  <label for="salaryBase" class="ms-2">Salario Base</label>
                </div>
              </div>

              <div class="row">
                <div class="mb-3 form-floating col-md-4">
                  <select
                    name="restDay"
                    class="form-select"
                    id="restDay"
                    aria-label="Floating label select"
                    formControlName="restDay"
                  >
                    <option value="" selected>
                      Seleccione el dia de descanso
                    </option>
                    <option value="-1">No Definido</option>
                    <option value="1">Lunes</option>
                    <option value="2">Martes</option>
                    <option value="3">Miercoles</option>
                    <option value="4">Jueves</option>
                    <option value="5">Viernes</option>
                    <option value="6">Sabado</option>
                    <option value="7">Domingo</option>
                  </select>
                  <label for="restDay" class="ms-2">
                    Seleccione el dia de descanso
                  </label>
                </div>
                <div class="mb-3 form-floating col-md-2">
                  <label for=""> Hora de unicio de comida: </label>
                </div>
                <div class="col-md-2 mt-2">
                  <ngb-timepicker
                    [(ngModel)]="timeStart"
                    [ngModelOptions]="{ standalone: true }"
                  ></ngb-timepicker>
                </div>

                <div class="mb-3 form-floating col-md-2">
                  <label for=""> Hora de fin de comida: </label>
                </div>
                <div class="col-md-2 mt-2">
                  <ngb-timepicker
                    [(ngModel)]="timeEnd"
                    [ngModelOptions]="{ standalone: true }"
                  ></ngb-timepicker>
                </div>
              </div>

              <div class="row">
                <div class="mb-3 form-floating col-md-4">
                  <select
                    class="form-select"
                    aria-label="Floating label select"
                    id="roleId"
                    name="roleId"
                    formControlName="roleId"
                  >
                    <option selected value="">Selecciona un rol</option>
                    <option *ngFor="let role of roles" value="{{ role.id }}">
                      {{ role.role }}
                    </option>
                  </select>
                  <label for="select_state" class="ms-2">
                    Selecciona un rol
                  </label>
                </div>
                <div class="mb-3 form-floating col-md-4">
                  <select
                    class="form-select"
                    aria-label="Floating label select"
                    id="cityId"
                    name="cityId"
                    formControlName="cityId"
                  >
                    <option selected value="">Selecciona una ciudad</option>
                    <option *ngFor="let city of cities" value="{{ city.id }}">
                      {{ city.city_name }}
                    </option>
                  </select>
                  <label for="select_state" class="ms-2">
                    Selecciona una ciudad
                  </label>
                </div>
                <div class="mb-3 form-floating col-md-4">
                  <select
                    class="form-select"
                    aria-label="Floating label select"
                    id="officeId"
                    name="officeId"
                    formControlName="officeId"
                  >
                    <option selected value="">Selecciona una sucursal</option>
                    <option
                      *ngFor="let office of offices"
                      value="{{ office.id }}"
                    >
                      {{ office.name }}
                    </option>
                  </select>
                  <label for="select_state" class="ms-2">
                    Selecciona una sucursal
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mb-3 mt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="has_secondary"
                    id="has_secondary"
                    (change)="activateSecondary($event)"
                  />
                  <label class="form-check-label ms-2" for="has_secondary">
                    Tiene sucursales secundarias
                  </label>
                </div>
              </div>

              <div class="row" *ngIf="hasSecondary">
                <input
                  type="hidden"
                  name="secondary_offices"
                  formControlName="secondary_offices"
                  id="secondary_offices"
                />
                <select2
                  [data]="officesData"
                  style="width: 100%"
                  displaySearchStatus="always"
                  placeholder="Sucursal secundaria"
                  formControlName="temp_offices"
                  multiple
                  listPosition="below"
                  styleMode="material"
                  noResultMessage="Sucursal no encontrada"
                  hideSelectedItems
                  (update)="updateOffices($event)"
                ></select2>
              </div>

              <div class="row">
                <div class="col-md-3 offset-md-2">
                  <label for="startSession">Hora de entrada</label>
                  <ngb-timepicker
                    [(ngModel)]="startEmployee"
                    [ngModelOptions]="{ standalone: true }"
                    [spinners]="true"
                  ></ngb-timepicker>
                </div>
                <div class="col-md-3 offset-md-2">
                  <label for="endSession">Hora de salida</label>
                  <ngb-timepicker
                    [(ngModel)]="endEmployee"
                    [ngModelOptions]="{ standalone: true }"
                    [spinners]="true"
                  ></ngb-timepicker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3 mt-2 d-grip gap-2 d-md-flex justify-content-md-end">
        <button type="submit" class="btn btn-success">
          <i class="bi bi-plus-circle"></i>
          Actualizar
        </button>
      </div>
    </form>
  </div>
</div>
