import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-update-commissions',
  templateUrl: './update-commissions.component.html',
  styleUrls: ['./update-commissions.component.scss'],
})
export class UpdateCommissionsComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  commissionForm: any = FormGroup;
  submitted: boolean = false;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  generalMessage: string = '';
  breadcrumbs: any;
  paramId: string | null = '';
  roles: any = [];
  offices: any = [];
  private _message = new Subject<string>();

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this._message.subscribe(
      (messageData: string) => (this.generalMessage = messageData)
    );
    this._message.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
        this.submitted = false;
        this.isDisplayed = false;
        this.isSaved = false;
        this.generalMessage = '';
      }
    });

    this.paramId = this.route.snapshot.paramMap.get('id');
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de comisiones',
        path: '/commissions/list',
        link: true,
      },
      {
        name: 'Editar comisi&oacute;n',
        link: false,
      },
    ];

    this.validations();
    this.getCommissions();
    this.getRoles();
    this.getOffices();
  }

  getRoles(): void {
    this.api.get('roles').subscribe({
      next: (roles) => {
        this.roles = roles.roles;
      },
      error: (err) => {},
    });
  }

  getOffices() {
    this.api.get('offices').subscribe({
      next: (offices) => {
        this.offices = offices.offices;
      },
      error: (err) => {},
    });
  }

  getCommissions() {
    this.api.getById('commissions', Number(this.paramId)).subscribe({
      next: (commission) => {
        this.commissionForm.patchValue({
          title: commission.commission.title,
          office: commission.commission.office.id,
          percentage: commission.commission.percentage,
          roleId: commission.commission.role.id,
          active: commission.commission.isEnabled,
          typeCom: commission.commission.type,
        });
      },
      error: (err) => {},
    });
  }

  validations() {
    this.commissionForm = this.fb.group({
      title: ['', [Validators.required, Validators.minLength(4)]],
      office: ['', [Validators.required]],
      percentage: ['', [Validators.required]],
      roleId: ['', [Validators.required]],
      active: [''],
      typeCom: ['', [Validators.required]],
    });
  }

  get f() {
    return this.commissionForm.controls;
  }

  updateCommission() {
    this.submitted = true;

    if (this.commissionForm.invalid) {
      return;
    }

    const obj = {
      title: this.commissionForm.value.title,
      office: this.commissionForm.value.office,
      percentage: this.commissionForm.value.percentage,
      roleId: this.commissionForm.value.roleId,
      active: this.commissionForm.value.active,
      type: this.commissionForm.value.typeCom,
    };

    this.api.update('commissions', Number(this.paramId), obj).subscribe({
      next: (bonus) => {
        this.submitted = false;
        this.isSaved = true;
        this.isDisplayed = false;
        this._message.next(bonus.message);
      },
      error: (err) => {},
    });
  }
}
