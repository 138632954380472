import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-update-client',
  templateUrl: './update-client.component.html',
  styleUrls: ['./update-client.component.scss'],
})
export class UpdateClientComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  clients: any = FormGroup;
  submitted: boolean = false;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  generalMessage: string = '';
  responseMessage: string = '';
  paramId: string | null = '';
  offices: any = [];
  breadcrumbs: any;
  officeId: number = 0;
  private _alert = new Subject<string>();

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this._alert.subscribe(
      (messageData: string) => (this.generalMessage = messageData)
    );
    this._alert.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
        this.isSaved = false;
        this.isDisplayed = false;
        this.submitted = false;
        this.generalMessage = '';
        this.responseMessage = '';
      }
    });

    this.paramId = this.route.snapshot.paramMap.get('id');
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/calendar/dashboard',
        link: true,
      },
      {
        name: 'Lista de clientes',
        path: '/clients/list',
        link: true,
      },
      {
        name: 'Editar cliente',
        link: false,
      },
    ];

    this.validations();
    this.getOffices();
    this.getClient();
  }

  getClient(): void {
    this.api.getById('clients/single', Number(this.paramId)).subscribe({
      next: (client) => {
        this.officeId = client.client.offices.id;
        const dateBirth = client.client.date_birthday.date
          .split(' ')[0]
          .split('-');

        this.clients.setValue({
          first_name: client.client.first_name,
          last_name: client.client.last_name,
          email: client.client.email,
          phone: client.client.phone,
          date_of_birthday: {
            year: parseInt(dateBirth[0]),
            month: parseInt(dateBirth[1]),
            day: parseInt(dateBirth[2]),
          },
          cp: client.client.cp,
          office_id: client.client.offices.id,
          reference: client.client.reference,
        });
      },
      error: (err) => {},
    });
  }

  getOffices(): void {
    this.api.get('offices').subscribe({
      next: (offices) => {
        this.offices = offices.offices;
      },
      error: (err) => {},
    });
  }

  validations(): void {
    this.clients = this.fb.group({
      first_name: ['', [Validators.required, Validators.minLength(3)]],
      last_name: ['', [Validators.required, Validators.minLength(3)]],
      email: [''],
      phone: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(15),
        ],
      ],
      date_of_birthday: ['', [Validators.required]],
      cp: [''],
      office_id: ['', [Validators.required]],
      reference: ['', [Validators.required]],
    });
    //  [Validators.required, Validators.minLength(5), Validators.maxLength(7)],
    // [Validators.required, Validators.minLength(5), Validators.email],
  }

  get f() {
    return this.clients.controls;
  }

  updateClient(): void {
    this.submitted = true;

    if (this.clients.invalid) {
      return;
    }

    const dob = `${this.clients.value.date_of_birthday.year}-${this.clients.value.date_of_birthday.month}-${this.clients.value.date_of_birthday.day}`;
    const obj = {
      first_name: this.clients.value.first_name,
      last_name: this.clients.value.last_name,
      email: this.clients.value.email,
      phone: this.clients.value.phone,
      dob: dob,
      cp: this.clients.value.cp,
      office_id: this.clients.value.office_id,
      numExp: this.clients.value.reference,
    };

    this.api.update('clients', Number(this.paramId), obj).subscribe({
      next: (client) => {
        this.submitted = false;
        this.isSaved = true;
        this.responseMessage = client.message;
      },
      error: (err) => {},
    });

    this._alert.next(this.responseMessage);
  }
}
