import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbTimepickerConfig, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { trackByHourSegment } from 'angular-calendar/modules/common/util';

@Component({
  selector: 'app-update-employees',
  templateUrl: './update-employees.component.html',
  styleUrls: ['./update-employees.component.scss'],
  providers: [NgbTimepickerConfig],
})
export class UpdateEmployeesComponent implements OnInit {
  employeeForm: any = FormGroup;
  submitted: boolean = false;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  generalMessage: string = '';
  breadcrumbs: any;
  paramId: string | null = '';
  cities: any = [];
  roles: any = [];
  offices: any = [];
  timeStart: NgbTimeStruct = { hour: 13, minute: 0, second: 0 };
  timeEnd: NgbTimeStruct = { hour: 14, minute: 0, second: 0 };
  startEmployee: { hour: any; minute: any; second: any } = {
    hour: '',
    minute: '',
    second: '',
  };
  endEmployee: { hour: any; minute: any; second: any } = {
    hour: '',
    minute: '',
    second: '',
  };
  hasSecondary: boolean = true;
  officesData: any = [];

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public config: NgbTimepickerConfig
  ) {
    config.seconds = false;
    config.spinners = false;
  }

  ngOnInit(): void {
    this.paramId = this.route.snapshot.paramMap.get('id');
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de colaboradores',
        path: '/employees/list',
        link: true,
      },
      {
        name: 'Editar empleado',
        link: false,
      },
    ];

    this.validations();
    this.getEmployee();
    this.getRoles();
    this.getCities();
    this.getOffices();
  }

  getRoles(): void {
    this.api.get('roles').subscribe({
      next: (roles) => {
        this.roles = roles.roles;
      },
      error: (err) => {},
    });
  }

  getCities(): void {
    this.api.get('cities').subscribe({
      next: (cities) => {
        this.cities = cities.cities;
      },
      error: (err) => {},
    });
  }

  getOffices(): void {
    this.api.get('offices').subscribe({
      next: (offices) => {
        this.offices = offices.offices;
        const offices_data = this.offices.map((data: any) => {
          return {
            value: data.id,
            label: data.name,
          };
        });

        this.officesData = [{ options: offices_data }];
      },
      error: (err) => {},
    });
  }

  validations(): void {
    this.employeeForm = this.fb.group({
      cityId: ['', [Validators.required]],
      officeId: ['', [Validators.required]],
      roleId: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', [Validators.required, Validators.minLength(3)]],
      lastName: ['', [Validators.required, Validators.minLength(3)]],
      phone: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(15),
        ],
      ],
      address: ['', [Validators.required, Validators.minLength(5)]],
      salaryBase: ['', [Validators.required]],
      restDay: ['', [Validators.required]],
      has_secondary: [''],
      secondary_offices: [''],
      temp_offices: [''],
    });
  }

  get f() {
    return this.employeeForm.controls;
  }

  getEmployee(): void {
    console.log('holas');
    this.api.getById('employee/single', Number(this.paramId)).subscribe({
      next: (employee) => {
        let rest_Day = '';
        if (employee.employee.schedule.length != 0) {
          rest_Day = employee.employee.schedule.rest_day;
          const splitStart = employee.employee.schedule.start_food.split(':');
          const splitEnd = employee.employee.schedule.end_food.split(':');
          this.timeStart = {
            hour: Number(splitStart[0]),
            minute: Number(splitStart[1]),
            second: 0,
          };
          this.timeEnd = {
            hour: Number(splitEnd[0]),
            minute: Number(splitEnd[1]),
            second: 0,
          };
        }

        console.log(employee.employee.startHour.length);
        if (
          employee.employee.startHour.length !== 0 &&
          employee.employee.endHour.length !== 0
        ) {
          const start = employee.employee.startHour.split(' ')[0].split(':');
          const end = employee.employee.endHour.split(' ')[0].split(':');

          this.startEmployee = {
            hour: Number(start[0]),
            minute: Number(start[1]),
            second: 0,
          };
          this.endEmployee = {
            hour: Number(end[0]),
            minute: Number(end[1]),
            second: 0,
          };
        } else {
          this.startEmployee = { hour: 10, minute: 0, second: 0 };
          this.endEmployee = { hour: 20, minute: 0, second: 0 };
        }

        this.hasSecondary = employee.employee.has_secondary;
        const d = employee.employee.secondary.split(',').map(Number);

        this.employeeForm.patchValue({
          firstName: employee.employee.first_name,
          lastName: employee.employee.last_name,
          email: employee.employee.email,
          phone: employee.employee.phone,
          address: employee.employee.address,
          salaryBase: employee.employee.salary_base,
          cityId: employee.employee.city.id,
          officeId: employee.employee.office.id,
          roleId: employee.employee.role.id,
          restDay: rest_Day,
          secondary_offices: employee.employee.secondary,
          has_secondary: employee.employee.has_secondary,
        });

        this.formData(d);
      },
      error: (err) => {},
    });
  }

  formData(d: any) {
    this.employeeForm.patchValue({
      temp_offices: d,
    });
  }

  updateEmployee(): void {
    this.submitted = true;

    if (this.employeeForm.invalid) {
      return;
    }

    const timeHour1 =
      String(this.timeStart.hour).length == 1
        ? '0' + this.timeStart.hour
        : this.timeStart.hour;
    const timeMinute1 =
      String(this.timeStart.minute).length == 1
        ? '0' + this.timeStart.minute
        : this.timeStart.minute;
    const timeHour2 =
      String(this.timeEnd.hour).length == 1
        ? '0' + this.timeEnd.hour
        : this.timeEnd.hour;
    const timeMinute2 =
      String(this.timeEnd.minute).length == 1
        ? '0' + this.timeEnd.minute
        : this.timeEnd.minute;
    const timeStartData = `${timeHour1}:${timeMinute1}:00`;
    const timeEndData = `${timeHour2}:${timeMinute2}:00`;

    const obj = {
      firstName: this.employeeForm.value.firstName,
      lastName: this.employeeForm.value.lastName,
      email: this.employeeForm.value.email,
      phone: this.employeeForm.value.phone,
      address: this.employeeForm.value.address,
      salaryBase: this.employeeForm.value.salaryBase,
      cityId: Number(this.employeeForm.value.cityId),
      officeId: Number(this.employeeForm.value.officeId),
      roleId: Number(this.employeeForm.value.roleId),
      foodStart: timeStartData,
      foodEnd: timeEndData,
      freeDay: this.employeeForm.value.restDay,
      startHour: `${this.startEmployee.hour}:${this.startEmployee.minute}:${this.startEmployee.second}`,
      endHour: `${this.endEmployee.hour}:${this.endEmployee.minute}:${this.endEmployee.second}`,
      has_secondary: this.employeeForm.value.has_secondary,
      secOffices: this.employeeForm.value.secondary_offices,
    };

    this.api.update('employees', Number(this.paramId), obj).subscribe({
      next: (employee) => {
        this.submitted = false;
        this.isSaved = true;
        this.generalMessage = employee.message;
      },
      error: (err) => {},
    });

    setTimeout(() => {
      this.submitted = false;
      this.isDisplayed = false;
      this.isSaved = false;
      this.generalMessage = '';
    }, 4000);
  }

  updateOffices(evt: any) {
    this.employeeForm.patchValue({
      secondary_offices: evt.value,
    });
  }

  activateSecondary(evt: any) {
    if (evt.target.checked) {
      this.hasSecondary = true;
    } else {
      this.hasSecondary = false;
    }
  }
}
