import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-display-sells',
  templateUrl: './display-sells.component.html',
  styleUrls: ['./display-sells.component.scss'],
})
export class DisplaySellsComponent implements OnInit {
  optionsAdvace: any = FormGroup;
  breadcrumbs: any = [];
  paymentsData: any = [];
  filterPaymentsData: any = [];
  paramId: any = '';
  paramOfficeId: any = '';
  flagAdvancedOptions: boolean = false;

  constructor(
    private api: ApiService,
    private router: ActivatedRoute,
    private currency: CurrencyPipe,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.paramId = this.router.snapshot.paramMap.get('date');
    this.paramOfficeId = this.router.snapshot.paramMap.get('office_id');
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de ventas por dia',
        path: '/daily-sells/list',
        link: true,
      },
      {
        name: 'Reporte de ventas',
        link: false,
      },
    ];

    this.loadDataReport();
    this.validations();
  }

  validations() {
    this.optionsAdvace = this.fb.group({
      oldies: [],
      sessions: [],
      packages: [],
      prizes: [],
    });
  }

  loadDataReport(): void {
    const params = this.paramId + '/' + this.paramOfficeId;
    this.api.getById('displayPaymentsReport', params).subscribe({
      next: (dataReport) => {
        this.paymentsData = dataReport.dailyPayments;
        this.filterPaymentsData = this.paymentsData;
      },
      error: (err) => {},
    });
  }

  showOptionsAdvanced() {
    this.flagAdvancedOptions = !this.flagAdvancedOptions;
  }

  filterData() {
    let filtros;
    let sum = 0;
    this.filterPaymentsData = this.paymentsData;
    console.log(this.filterPaymentsData);

    if (this.optionsAdvace.value.oldies) {
      sum++;
      filtros = this.filterPaymentsData.filter(
        (data: any) => data.old_package == true
      );

      this.filterPaymentsData = filtros;
    }

    if (this.optionsAdvace.value.packages) {
      sum++;
      filtros = this.filterPaymentsData.filter(
        (data: any) => data.is_package == 1
      );

      this.filterPaymentsData = filtros;
    }

    if (this.optionsAdvace.value.prizes) {
      sum++;
      filtros = this.filterPaymentsData.filter(
        (data: any) => data.has_gift_area > 0
      );

      this.filterPaymentsData = filtros;
    }

    if (this.optionsAdvace.value.sessions) {
      sum++;
      filtros = this.filterPaymentsData.filter(
        (data: any) => data.is_package == 0
      );

      this.filterPaymentsData = filtros;
    }

    if (sum === 0) {
      this.filterPaymentsData = this.paymentsData;
    }

    console.log(this.filterPaymentsData);
  }

  clearFilters() {
    this.flagAdvancedOptions = false;

    this.optionsAdvace.controls['oldies'].setValue(false);
    this.optionsAdvace.controls['sessions'].setValue(false);
    this.optionsAdvace.controls['packages'].setValue(false);
    this.optionsAdvace.controls['prizes'].setValue(false);

    this.filterPaymentsData = this.paymentsData;
  }
}
