import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-cities',
  templateUrl: './create-cities.component.html',
  styleUrls: ['./create-cities.component.scss'],
})
export class CreateCitiesComponent implements OnInit {
  citiesForm: any = FormGroup;
  submitted: boolean = false;
  isSaved: boolean = false;
  isDisplayed: boolean = false;
  generalMessage: string = '';
  states: any = [];
  breadcrumbs: any = [];

  constructor(private api: ApiService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de ciudades',
        path: '/cities/list',
        link: true,
      },
      {
        name: 'Crear ciudad',
        link: false,
      },
    ];

    this.validations();
    this.getStates();
  }

  validations() {
    this.citiesForm = this.fb.group({
      state_id: ['', [Validators.required]],
      city: ['', [Validators.required]],
    });
  }

  getStates() {
    this.api.get('states').subscribe({
      next: (states) => {
        this.states = states.estados;
      },
      error: (err) => {},
    });
  }

  get f() {
    return this.citiesForm.controls;
  }

  createCity() {
    this.submitted = true;

    if (this.citiesForm.invalid) {
      return;
    }

    const obj = {
      stateId: this.citiesForm.value.state_id,
      city: this.citiesForm.value.city,
    };

    this.api.create('cities', obj).subscribe({
      next: (city) => {
        this.submitted = false;
        this.isSaved = true;
        this.generalMessage = city.message;

        this.resetCityForm();
      },
      error: (err) => {},
    });
  }

  resetCityForm() {
    this.citiesForm.reset();
    this.citiesForm.controls.state_id.setValue('');

    setTimeout(() => {
      this.isSaved = false;
      this.isDisplayed = false;
      this.submitted = false;
      this.generalMessage = '';
    }, 4000);
  }
}
