import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgbModal, NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-list-bonus',
  templateUrl: './list-bonus.component.html',
  styleUrls: ['./list-bonus.component.scss'],
})
export class ListBonusComponent implements OnInit {
  @ViewChild('modalBonus', { static: false }) modalBonus!: TemplateRef<any>;
  @ViewChild('activeBonus', { static: false }) activeBonus!: TemplateRef<any>;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  modalBonusClose: any;
  filterText: string = '';
  filterBonus: any = [];
  bonus: any = [];
  breadcrumbs: any;
  idDeleted: number = 0;
  idActive: number = 0;
  statusActive: boolean = false;
  bonusNameDeleted: string = '';
  generalMessage: string = '';
  flagDeleted: boolean = false;
  flagActive: boolean = false;
  typeAlert: string = '';
  page: number = 1;
  private _message = new Subject<string>();
  offices: any = [];
  rolename: string = '';
  rolekey: string = '';
  roleofficeid: number = 0;
  currentOfficeId: number = 0;

  constructor(private api: ApiService, private modal: NgbModal) {}

  ngOnInit(): void {
    this._message.subscribe(
      (messageData: string) => (this.generalMessage = messageData)
    );
    this._message.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.flagDeleted = false;
        this.generalMessage = '';
        this.bonusNameDeleted = '';
        this.idDeleted = 0;
      }
    });

    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de bonos',
        link: false,
      },
    ];

    this.getRoles();
    this.getBonus();
    this.getOffices();
  }

  getRoles() {
    this.api.get('employees/roles').subscribe({
      next: (roles) => {
        this.rolename = roles.user.role;
        this.rolekey = roles.user.key.toLowerCase();
        this.roleofficeid = roles.user.office.id;
        this.currentOfficeId = this.roleofficeid;
      },
      error: (err) => {},
    });
  }

  getBonus() {
    this.api.get('bonus').subscribe({
      next: (bonus) => {
        this.bonus = bonus.bonus;
        this.filterBonus = this.bonus;
      },
      error: (err) => {},
    });
  }

  getOffices() {
    this.api.get('offices').subscribe({
      next: (offices) => {
        this.offices = offices.offices;
      },
      error: (err) => {},
    });
  }

  setCurrentOffice(evt: any) {
    this.api.get(`bonus/${evt.target.value}`).subscribe({
      next: (bonus) => {
        this.bonus = bonus.bonus;
        this.filterBonus = this.bonus;
      },
      error: (err) => {},
    });
  }

  openDeleteModal(id: number, bonusName: string): void {
    this.idDeleted = id;
    this.bonusNameDeleted = bonusName;

    this.modalBonusClose = this.modal.open(this.modalBonus, { size: 'md' });
  }

  openActiveInactiveModal(id: number, status: boolean): void {
    this.idActive = id;
    this.statusActive = status;

    this.modalBonusClose = this.modal.open(this.activeBonus, { size: 'md' });
  }

  deleteBonus(id: number): void {
    this.api.delete('bonus', Number(id)).subscribe({
      next: (bonus) => {
        this.flagDeleted = true;
        this.typeAlert = 'success';
        this._message.next(bonus.message);
        this.modalBonusClose.close();
        this.getBonus();
      },
      error: (err) => {},
    });
  }

  activeInactiveBonus(id: number, status: boolean): void {
    this.api.update('bonus/status', id, { active: !status }).subscribe({
      next: (data) => {
        this.flagActive = true;
        this.typeAlert = 'success';
        this._message.next(data.message);
        this.modalBonusClose.close();
        this.getBonus();
      },
      error: (err) => {},
    });
  }

  close(): void {
    this.modalBonusClose.close();
  }

  clearSearch() {
    this.filterText = '';
    this.currentOfficeId = this.roleofficeid;
    this.getBonus();
  }

  matches(listData: any, term: string) {
    return (
      listData.title.toLowerCase().includes(term.toLowerCase()) ||
      listData.amount.includes(term) ||
      listData.role.name.toLowerCase().includes(term.toLowerCase()) ||
      listData.office.name.toLowerCase().includes(term.toLowerCase())
    );
  }

  filterRecords() {
    const filterData = this.bonus.filter((data: any) => {
      return this.matches(data, this.filterText);
    });

    this.filterBonus = filterData;
  }
}
