import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-show-promotion',
  templateUrl: './show-promotion.component.html',
  styleUrls: ['./show-promotion.component.scss'],
})
export class ShowPromotionComponent implements OnInit {
  breadcrumbs: any = [];
  paramId: string | null = '';
  promotions: any = [];

  constructor(private api: ApiService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.paramId = this.route.snapshot.paramMap.get('id');
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista  de promociones',
        path: '/promotions/list',
        link: true,
      },
      {
        name: 'Informacion de la promocion',
        link: false,
      },
    ];
    this.getPromotions();
  }

  getPromotions() {
    this.api.getById('promotions', Number(this.paramId)).subscribe({
      next: (promotion) => {
        this.promotions = promotion.promotions;
      },
      error: (err) => {},
    });
  }
}
