<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Lista de paquetes</h3>
  </div>
  <hr />
  <div class="row align-items-center mb-3">
    <div class="col-md-6">
      <div class="input-group">
        <input
          type="text"
          id="table-complete-search"
          name="searchText"
          class="form-control"
          placeholder="Buscar paquete"
          [(ngModel)]="filterText"
          (keyup)="filterRecords()"
        />

        <select
          class="form-select ms-1"
          name="currentOfficeId"
          [(ngModel)]="currentOfficeId"
          id="currentOfficeId"
          (change)="setCurrentOffice($event)"
          *ngIf="rolekey == 'admin'"
        >
          <option value="0" selected="selected">Selecciona sucursal</option>
          <option
            *ngFor="let office of offices"
            value="{{ office.id }}"
            selected="{ office.id == roleofficeid ? 'selected' : '' }"
          >
            {{ office.name }}
          </option>
        </select>

        <button
          class="btn btn-outline-danger ms-1"
          type="button"
          (click)="clearSearch()"
        >
          Limpiar busqueda
        </button>
      </div>
    </div>
    <div class="col-md-6">
      <div class="d-grip gap-1 d-md-flex justify-content-md-end">
        <a [routerLink]="['/packages/create']" class="btn btn-outline-success">
          <i class="bi bi-plus-circle"></i>
          Crear paquete
        </a>
      </div>
    </div>
  </div>
  <hr />

  <div class="alert alert-success" role="alert" *ngIf="flagDeleted">
    {{ message }}
  </div>

  <form>
    <table class="table table-striped">
      <thead>
        <tr>
          <th class="col">ID</th>
          <th class="col">Nombre del cliente</th>
          <th class="col">Tipo</th>
          <th class="col">Es compartido?</th>
          <th class="col">Precio</th>
          <th class="col">Paquete liquidado?</th>
          <th class="col">Duraci&oacute;n</th>
          <th class="col">Total de sesi&oacute;nes</th>
          <th class="col">Porcentaje</th>
          <th class="col">Sucursal</th>
          <th class="col">Fecha de compra</th>
          <th class="col">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let package of filterPackages
              | paginate : { itemsPerPage: 15, currentPage: page }
          "
        >
          <td scope="row">
            {{ package.id }}
          </td>
          <td>
            {{ package.client.name }}
            <i
              class="bi bi-question-circle"
              placement="top"
              ngbTooltip="{{ package.packageName }}"
            ></i>
          </td>
          <td>
            {{ package.type }}
          </td>
          <td class="text-center">
            {{ package.shared }}
          </td>
          <td>
            {{ package.subtotal | currency }}
          </td>
          <td class="text-center">
            {{ package.isOldText }}
          </td>
          <td>
            {{ package.duration }}
          </td>
          <td class="text-center">
            {{ package.sessionsNumber }}
          </td>
          <td>{{ package.percentage }} %</td>
          <td>{{ package.client.office }}</td>
          <td>{{ package.date_purchased }}</td>
          <td>
            <div class="d-flex d-flex-row">
              <div>
                <a
                  disabled
                  [routerLink]="['/packages/edit', package.id]"
                  placement="top"
                  ngbTooltip="Editar"
                  role="button"
                  [ngClass]="{ 'link-disabled': package.finished }"
                  class="btn btn-primary"
                >
                  <i class="bi bi-pencil-square"></i>
                </a>
              </div>
              <div class="ms-2">
                <a
                  [routerLink]="['/packages/information', package.id]"
                  placement="top"
                  ngbTooltip="Informacion"
                  role="button"
                  class="btn btn-primary"
                >
                  <i class="bi bi-person-bounding-box"></i>
                </a>
              </div>
              <div class="ms-2">
                <a
                  *ngIf="rolekey == 'admin'"
                  (click)="openDeleteMessage(package.id, package.client.name)"
                  placement="top"
                  ngbTooltip="Eliminar"
                  role="button"
                  [ngClass]="{ 'link-disabled': package.finished }"
                  class="btn btn-primary"
                >
                  <i class="bi bi-trash3-fill"></i>
                </a>
              </div>
              <div class="ms-2">
                <a
                  [routerLink]="['/packages/advance-payments', package.id]"
                  placement="top"
                  ngbTooltip="Pago Adelantado"
                  role="button"
                  class="btn btn-primary"
                >
                  <i class="bi bi-coin"></i>
                </a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="col-md-12 d-flex align-items-center justify-content-center">
      <pagination-controls
        (pageChange)="page = $event"
        nextLabel="Siguiente"
        previousLabel="Anterior"
        class="mt-3"
      ></pagination-controls>
    </div>
  </form>

  <ng-template #modalPackage let-close="close">
    <div class="modal-header">
      <h4 class="modal-title">Eliminar paquete</h4>
      <button class="close" type="button" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        Deseas eliminar el paquete de <strong>{{ packageNameDeleted }}</strong>
      </p>
      <div class="row">
        <div class="d-grip gap-2 d-md-flex justify-content-md-end">
          <button
            type="button"
            (click)="deletePackages(idDeleted)"
            class="btn btn-outline-success"
          >
            Confirmar
          </button>
          <button
            class="btn btn-outline-danger"
            type="button"
            (click)="close()"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</div>
