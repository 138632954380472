<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Crear Areas</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-5">
    <a [routerLink]="['/areas/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de areas
    </a>
  </div>

  <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
    <div *ngIf="isDisplayed" class="alert alert-danger" role="alert">
      {{ generalMessage }}
    </div>
    <div *ngIf="isSaved" class="alert alert-success" role="alert">
      {{ generalMessage }}
    </div>

    <form [formGroup]="areasForm" (ngSubmit)="createArea()" novalidate>
      <div class="accordion" id="cities_accordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="cities-information">
            <button
              class="accordion-button color-header-collapse"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              disabled
            >
              <span class="color-text"> Creaci&oacute;n de Sucursales </span>
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div class="row">
                <div class="mb-3 form-floating col-md-6">
                  <input
                    type="text"
                    name="area"
                    id="area"
                    formControlName="area"
                    class="form-control"
                    placeholder="Area"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.area.errors,
                      'is-valid': submitted && !f.area.errors
                    }"
                  />
                  <label for="area" class="ms-2">Area</label>
                </div>
                <div class="mb-3 form-floating col-md-6">
                  <select
                    class="form-select"
                    aria-label="floating label select"
                    id="type"
                    name="type"
                    formControlName="type"
                  >
                    <option selected value="">
                      Selecciona un tama&ntilde;o
                    </option>
                    <option value="3">Chica</option>
                    <option value="2">Mediana</option>
                    <option value="1">Grande</option>
                  </select>
                  <label for="type" class="ms-2">
                    Selecciona un tama&ntilde;o
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 form-floating col-md-4">
                  <input
                    type="text"
                    name="price"
                    id="price"
                    formControlName="price"
                    class="form-control"
                    placeholder="Precio Guadalajara"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.price.errors,
                      'is-valid': submitted && !f.price.errors
                    }"
                  />
                  <label for="price" class="ms-2"> Precio Guadalajara </label>
                </div>
                <div class="mb-3 form-floating col-md-4">
                  <input
                    type="text"
                    name="price_sin"
                    id="price_sin"
                    formControlName="price_sin"
                    class="form-control"
                    placeholder="Precio Mochis"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.price_sin.errors,
                      'is-valid': submitted && !f.price_sin.errors
                    }"
                  />
                  <label for="price_sin" class="ms-2"> Precio Mochis </label>
                </div>
                <div class="mb-3 form-floating col-md-4">
                  <input
                    type="text"
                    name="price_tj"
                    id="price_tj"
                    formControlName="price_tj"
                    class="form-control"
                    placeholder="Precio Peninsula"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.price_tj.errors,
                      'is-valid': submitted && !f.price_tj.errors
                    }"
                  />
                  <label for="price_tj" class="ms-2"> Precio Peninsula </label>
                </div>
              </div>

              <div class="row">
                <div class="mb-3 form-floating col-md-4">
                  <input
                    type="text"
                    name="duration"
                    id="duration"
                    formControlName="duration"
                    class="form-control"
                    placeholder="Duraci&oacute;n (ejemplo: 00:10 para 10 minutos)"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.duration.errors,
                      'is-valid': submitted && !f.duration.errors
                    }"
                  />
                  <label for="duration" class="ms-2">
                    Duraci&oacute;n (ejemplo: 00:10 para 10 minutos)
                  </label>
                </div>
                <div class="mb-3 form-floating col-md-4">
                  <input
                    type="text"
                    name="duration_sin"
                    id="duration_sin"
                    formControlName="duration_sin"
                    class="form-control"
                    placeholder="Duraci&oacute;n Mochis (ejemplo: 00:10 para 10 minutos)"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.duration_sin.errors,
                      'is-valid': submitted && !f.duration_sin.errors
                    }"
                  />
                  <label for="duration_sin" class="ms-2">
                    Duraci&oacute;n Mochis (ejemplo: 00:10 para 10 minutos)
                  </label>
                </div>
                <div class="mb-3 form-floating col-md-4">
                  <input
                    type="text"
                    name="duration_tj"
                    id="duration_tj"
                    formControlName="duration_tj"
                    class="form-control"
                    placeholder="Duraci&oacute;n Peninsula (ejemplo: 00:10 para 10 minutos)"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.duration_tj.errors,
                      'is-valid': submitted && !f.duration_tj.errors
                    }"
                  />
                  <label for="duration_tj" class="ms-2">
                    Duraci&oacute;n Peninsula (ejemplo: 00:10 para 10 minutos)
                  </label>
                </div>
              </div>
              <div class="row">
                <!--div class="mb-3 form-floating col-md-6">
                  <select
                    class="form-select"
                    aria-label="floating label select"
                    id="city"
                    name="city"
                    formControlName="city"
                  >
                    <option selected value="">Selecciona una ciudad</option>
                    <option *ngFor="let city of cities" value="{{ city.id }}">
                      {{ city.city_name }}
                    </option>
                  </select>
                  <label for="city" class="">Selecciona una ciudad</label>
                </div-->
                <div class="col-md-6 mb-3 mt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="fullBody"
                    id="fullBody"
                  />
                  <label class="form-check-label ms-2" for="fullBody">
                    Es full body el area?
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3 mt-2 d-grip gap-2 d-md-flex justify-content-md-end">
        <button type="button" class="btn btn-danger" (click)="resetAreaForm()">
          <i class="bi bi-eraser"></i>
          Limpiar Informaci&oacute;n
        </button>
        <button type="submit" class="btn btn-success">
          <i class="bi bi-plus-circle"></i>
          Crear
        </button>
      </div>
    </form>
  </div>
</div>
