<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Reporte de ventas del dia</h3>
  </div>
  <hr />
  <div class="row align-items-center mb-3">
    <div class="col-md-6">
      <button
        class="btn btn-outline-success ms-1"
        type="button"
        (click)="showOptionsAdvanced()"
      >
        Filtros
      </button>
      <button
        type="button"
        (click)="clearFilters()"
        class="btn btn-outline-danger ms-2"
      >
        <i class="bi bi-trash3-fill"></i>
        Limpiar filtros
      </button>
    </div>
    <div class="col-md-6">
      <div class="d-grip gap-1 d-md-flex justify-content-md-end">
        <a [routerLink]="['/daily-sells/list']" class="btn btn-outline-success">
          <i class="bi bi-arrow-left-circle"></i>
          Regresar a lista de ventas del dia
        </a>
      </div>
    </div>
  </div>
  <hr />
  <div *ngIf="flagAdvancedOptions">
    <form [formGroup]="optionsAdvace" novalidate>
      <div class="row">
        <h4>Filtros Avanzados</h4>
      </div>
      <div class="row">
        <div class="col-md-3">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="oldies"
            formControlName="oldies"
          />
          <label class="form-check-label ms-2" for="oldies">
            Paquetes antiguos
          </label>
        </div>
        <div class="col-md-3">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="packages"
            formControlName="packages"
          />
          <label class="form-check-label ms-2" for="packages">
            Pago de paquetes
          </label>
        </div>
        <div class="col-md-3">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="sessions"
            formControlName="sessions"
          />
          <label class="form-check-label ms-2" for="sessions">
            Pagos en sesi&oacute;n
          </label>
        </div>
        <div class="col-md-3">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="prizes"
            formControlName="prizes"
          />
          <label class="form-check-label ms-2" for="prizes">
            Paquetes con areas de regalo
          </label>
        </div>
      </div>
      <div class="mb-3 mt-3 d-grid gap-2 d-md-flex justify-content-md-end">
        <button type="button" (click)="clearFilters()" class="btn btn-danger">
          <i class="bi bi-trash3-fill"></i>
          Limpiar filtros
        </button>
        <button type="button" (click)="filterData()" class="btn btn-success">
          <i class="bi bi-funnel"></i>
          Filtrar
        </button>
      </div>
    </form>
    <hr />
  </div>

  <div class="col-md-12">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th class="col">Nombre del colaborador</th>
          <th class="col">Cliente</th>
          <th class="col">Cantidad</th>
          <th class="col">Fecha</th>
          <th class="col">Tipo</th>
          <th class="col">MSI</th>
          <th class="col">Terminal</th>
        </tr>
      </thead>
      <tbody *ngFor="let report of filterPaymentsData">
        <tr>
          <td>{{ report.nombre }}</td>
          <td>{{ report.cliente }}</td>
          <td>{{ report.cantidad | currency }}</td>
          <td>{{ report.fecha }}</td>
          <td>{{ report.tipo != "" ? report.tipo : "N/A" }}</td>
          <td>{{ report.msi != "" ? report.msi : "N/A" }}</td>
          <td>{{ report.terminal != "" ? report.terminal : "N/A" }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
