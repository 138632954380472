export const colors: any = ['#ad2121', '#1e90ff'];

export const I18N_VALUES = {
  es: {
    weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
    months: [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic',
    ],
    weekLabel: 'sem',
  },
};

export const SELLS_FOR: string[] = [
  'Reventa o Ingreso',
  'Redes Sociales',
  'Referido',
];

export const SOCIAL_NETWORK: string[] = ['Facebook', 'Instagram'];
