<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Crear sucursal</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-5">
    <a [routerLink]="['/offices/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de sucursales
    </a>
  </div>

  <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
    <div *ngIf="isDisplayed" class="alert alert-danger" role="alert">
      {{ generalMessage }}
    </div>
    <div *ngIf="isSaved" class="alert alert-success" role="alert">
      {{ generalMessage }}
    </div>

    <form [formGroup]="officesForm" (ngSubmit)="createOffice()" novalidate>
      <div class="accordion" id="cities_accordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="cities-information">
            <button
              class="accordion-button color-header-collapse"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              disabled
            >
              <span class="color-text"> Creaci&oacute;n de Sucursales </span>
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div class="row">
                <div class="mb-3 form-floating col-md-6">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    formControlName="name"
                    class="form-control"
                    placeholder="Nombre de la sucursal"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.name.errors,
                      'is-valid': submitted && !f.name.errors
                    }"
                  />
                  <label for="name" class="ms-2">Nombre de la sucursal</label>
                </div>
                <div class="mb-3 form-floating col-md-6">
                  <input
                    type="text"
                    name="address"
                    id="address"
                    formControlName="address"
                    class="form-control"
                    placeholder="Direcci&oacute;n de la sucursal"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.address.errors,
                      'is-valid': submitted && !f.address.errors
                    }"
                  />
                  <label for="address" class="ms-2"
                    >Direcci&oacute;n de la sucursal</label
                  >
                </div>
              </div>

              <div class="mb-3 form-floating">
                <select
                  class="form-select"
                  aria-label="floating label select"
                  id="city_id"
                  name="city_id"
                  formControlName="city_id"
                >
                  <option selected value="">Selecciona una ciudad</option>
                  <option *ngFor="let city of cities" value="{{ city.id }}">
                    {{ city.city_name }}
                  </option>
                </select>
                <label for="select_state" class="">Selecciona una ciudad</label>
              </div>

              <div class="row">
                <div class="col-md-3 offset-md-2">
                  <label for="startSession">Hora de apertura</label>
                  <ngb-timepicker
                    [(ngModel)]="startOffice"
                    [ngModelOptions]="{ standalone: true }"
                    [spinners]="true"
                  ></ngb-timepicker>
                </div>
                <div class="col-md-3 offset-md-2">
                  <label for="endSession">Hora de cierre</label>
                  <ngb-timepicker
                    [(ngModel)]="endOffice"
                    [ngModelOptions]="{ standalone: true }"
                    [spinners]="true"
                  ></ngb-timepicker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 mt-2 d-grip gap-2 d-md-flex justify-content-md-end">
        <button
          type="button"
          class="btn btn-danger"
          (click)="resetOfficeForm()"
        >
          <i class="bi bi-eraser"></i>
          Limpiar Informaci&oacute;n
        </button>
        <button type="submit" class="btn btn-success">
          <i class="bi bi-plus-circle"></i>
          Crear
        </button>
      </div>
    </form>
  </div>
</div>
