import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CalendarView } from 'angular-calendar';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'mwl-utils-calendar-header',
  styleUrls: ['../../app.component.scss'],
  template: `
    <div class="row text-center">
      <div class="col-md-4">
        <div class="btn-group">
          <div
            class="btn btn-info"
            mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="viewDateChange.next(viewDate)"
          >
            Anterior
          </div>
          <div
            class="btn btn-outline-secondary"
            mwlCalendarToday
            [(viewDate)]="viewDate"
            (viewDateChange)="viewDateChange.next(viewDate)"
          >
            Hoy
          </div>
          <div
            class="btn btn-info"
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="viewDateChange.next(viewDate)"
          >
            Siguiente
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <h3>{{ viewDate | calendarDate : view + 'ViewTitle' : locale }}</h3>
      </div>
      <div class="col-md-4">
        <div class="btn-group">
          <div
            class="btn btn-info"
            (click)="viewChange.emit(CalendarView.Month)"
            [class.active]="view === CalendarView.Month"
          >
            Mes
          </div>
          <div
            class="btn btn-info"
            (click)="viewChange.emit(CalendarView.Week)"
            [class.active]="view === CalendarView.Week"
          >
            Semana
          </div>
          <div
            class="btn btn-info"
            (click)="viewChange.emit(CalendarView.Day)"
            [class.active]="view === CalendarView.Day"
          >
            D&iacute;a
          </div>
        </div>
      </div>
    </div>
    <br />
  `,
})
export class CalendarHeaderComponent {
  @Input() view!: CalendarView;

  @Input() viewDate!: Date;

  @Input() locale: string = 'es';

  @Output() viewChange = new EventEmitter<CalendarView>();

  @Output() viewDateChange = new EventEmitter<Date>();

  CalendarView = CalendarView;

  constructor(private sharedService: SharedService) {
    this.sharedService.invokeEvent.subscribe((value) => {
      if (value.value == 'calendarPerClientDate') {
        this.nextday(value.date);
      }
    });
  }

  nextday(datePassed: string) {
    let date = new Date(datePassed.replace(/-/g, '/'));
    this.viewDate = new Date(date.toString());
    this.viewDateChange.next(this.viewDate);
  }
}
