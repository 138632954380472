import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  @ViewChild('modalPromotion', { static: false })
  modalPromotion!: TemplateRef<any>;
  modalPromotionClose: any;
  filterText: string = '';
  promotions: any = [];
  filterPromotions: any = [];
  breadcrumbs: any = [];
  idDeleted: number = 0;
  promotionDeleted: string = '';
  message: string = '';
  flagDeleted: boolean = false;
  page: number = 1;

  constructor(private api: ApiService, private modal: NgbModal) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de promociones',
        link: false,
      },
    ];

    this.getPromotions();
  }

  getPromotions(): void {
    this.api.get('promotions').subscribe({
      next: (promotions) => {
        this.promotions = promotions.promotions;
        this.filterPromotions = promotions.promotions;
      },
      error: (err) => {},
    });
  }

  clearSearch() {
    this.filterText = '';
    this.getPromotions();
  }

  matches(listData: any, term: string) {
    return (
      listData.text.toLowerCase().includes(term.toLowerCase()) ||
      listData.name.toLowerCase().includes(term.toLowerCase())
    );
  }

  filterRecords(): void {
    const filterData = this.promotions.filter((data: any) => {
      return this.matches(data, this.filterText);
    });

    this.filterPromotions = filterData;
  }

  openDeleteMessage(id: number, promotionName: string): void {
    this.idDeleted = id;
    this.promotionDeleted = promotionName;

    this.modalPromotionClose = this.modal.open(this.modalPromotion, {
      size: 'md',
    });
  }

  deletePromotion(id: number): void {
    this.api.delete('promotions', Number(id)).subscribe({
      next: (promotion) => {
        this.message = promotion.message;
        this.flagDeleted = true;
        const id = promotion.id;

        this.filterPromotions = this.filterPromotions.filter(
          (dataPromotion: any) => dataPromotion.id !== id
        );
        this.modalPromotionClose.close();
      },
      error: (err) => {},
    });

    setTimeout(() => {
      this.flagDeleted = false;
      this.message = '';
    }, 4000);
  }
}
