<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Crear roles</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-5">
    <a [routerLink]="['/roles/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de roles
    </a>
  </div>

  <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
    <div *ngIf="isDisplayed" class="alert alert-danger" role="alert">
      {{ generalMessage }}
    </div>
    <div *ngIf="isSaved" class="alert alert-success" role="alert">
      {{ generalMessage }}
    </div>
    <form [formGroup]="rolesForm" (ngSubmit)="createRoles()" novalidate>
      <div class="accordion" id="cities_accordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="cities-information">
            <button
              class="accordion-button color-header-collapse"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              disabled
            >
              <span class="color-text"> Creaci&oacute;n de Roles </span>
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div class="row">
                <div class="mb-3 form-floating col-md-6">
                  <input
                    type="text"
                    formControlName="role"
                    name="role"
                    id="role"
                    class="form-control"
                    placeholder="Nombre del rol"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.role.errors,
                      'is-valid': submitted && !f.role.errors
                    }"
                  />
                  <label for="role" class="ms-2">Nombre del rol</label>
                </div>

                <div class="mb-3 form-floating col-md-6">
                  <input
                    type="text"
                    name="role_key"
                    id="role_key"
                    formControlName="role_key"
                    class="form-control"
                    placeholder="Abreviacion del rol"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.role_key.errors,
                      'is-valid': submitted && !f.role_key.errors
                    }"
                  />
                  <label for="role_key" class="ms-2"
                    >Abreviaci&oacute;n del rol</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3 mt-2 d-grid gap-2 d-md-flex justify-content-md-end">
        <button type="button" class="btn btn-danger" (click)="resetRolesForm()">
          <i class="bi bi-eraser"></i>
          Limpiar Informaci&oacute;n
        </button>
        <button type="submit" class="btn btn-success">
          <i class="bi bi-plus-circle"></i>
          Crear
        </button>
      </div>
    </form>
  </div>
</div>
